import React from 'react';
import ReactDOM from 'react-dom';
import Root from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
// import registerServiceWorker from './registerServiceWorker';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {Provider} from "react-redux";
import {persistStore} from 'redux-persist';
import {getProfileUser, logout, setCurrentUser, setToken} from "./state/auth/actions";
import configStore from "./store";

const store = configStore();
const moment = require('moment-timezone');
moment().tz("UTC").format();

if (localStorage.getItem('access_token')) {
  setToken(localStorage.getItem('access_token'));
  const currentTime = Date.now();
  if (parseInt(localStorage.getItem('access_token_expiresIn')) < currentTime) {
    //Logout user
    store.dispatch(logout());
    //Redirect to login
    window.location.href = "/";
  } else {
    let userId = localStorage.getItem('userId')
    getProfileUser(userId).then(res => {
      let user = res.data.getProfile.data;
      store.dispatch(setCurrentUser(user));
    }).catch(err => {
      //Logout user
      store.dispatch(logout());
      //Redirect to login
      window.location.href = "/login";
    })
  }
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <Root/>
    </PersistGate>
  </Provider>
  , document.getElementById('root'),
  // registerServiceWorker()
)
