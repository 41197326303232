import React, {useEffect, useState} from 'react';
import './index.scss'
import {Avatar, Button, Pagination, Popconfirm, Tabs} from 'antd';
import {EnvironmentFilled, FileTextOutlined} from "@ant-design/icons/lib/icons";
import ScheduleDetail from "./../components/ScheduleDetail";
import {actionAppointment, listAppointmentPending, listAppointmentSchedule} from "../../../state/appointment/action";
import moment from 'moment'
import ModalShowProfile from "../../../components/Profile";
import ModalCancelAppointment from "../components/CancelAppointment";
import {useSelector} from "react-redux";
import Loading from "../../../components/Loading";
import EmptyData from "../../../components/Empty";
import {
    ACCEPT_APPOINTMENT_FOR_CREW,
    CANCEL_APPOINTMENT_FOR_CLIENT,
    DECLINE_APPOINTMENT_FOR_CREW,
    NO_IMAGE,
    USER_TYPE_CREW
} from "../../../constants";
import ModalResultSuccess from "../../../components/Result";
import ModalCompleteAppointment from "../components/Complete";
import AlertError from "../../../components/Alert/error";

const  Appointment = (props) => {
    const [detail, setDetail] = useState(false)
    const [spinState, setSpinState] = useState(true)
    const [tabActive, setTabActive] = useState('pending')
    const [appointmentPendingData, setAppointmentPendingData] = useState([])
    const [appointmentScheduleData, setAppointmentScheduleData] = useState([])
    const [appointmentSelected , setAppointmentSelected] = useState(null)
    const [visibleModalShowProfile, setVisibleModalShowProfile] = useState(false)
    const [useSelected, setUserSelected] = useState(null)
    const [isOpenModalCancel, setOpenModalCancel] = useState(false)
    const [typeCancel, setTypeCancel] = useState(null)
    const {user} = useSelector(state => state.auth)
    const [pageSchedule, setPageSchedule] = useState(1);
    const [pagePending, setPagePending] = useState(1);
    const [totalPageSchedule, setTotalPageSchedule] = useState(1)
    const [totalPagePending, setTotalPagePending] = useState(1)
    const perPage = 10;
    const [isOpenModalResult, setOpenModalResult] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [clicked, setClickedButton] = useState(null);
    const [isOpenComplete, setOpenComplete] = useState(false)
    const [emailClient, setEmailClient] = useState(null)

    useEffect(() => {
        let notificationData = JSON.parse(localStorage.getItem('notificationData'))
        if(notificationData){
            setAppointmentSelected(notificationData.appointmentId)
            setDetail(notificationData.seeDetail)
        }
        localStorage.removeItem('notificationData')
    }, [])

    useEffect(() => {
        const initDataListAppointment = async () => {
            try {
                if(tabActive === 'pending'){
                    setSpinState(true)
                    let page = pagePending - 1
                    let result = await listAppointmentPending(page)
                    setAppointmentPendingData(result.data.pendingAppointment.data)
                    if(result.data.pendingAppointment.rowcount){
                        setTotalPagePending(result.data.pendingAppointment.rowcount)
                    }
                    setSpinState(false)
                }
                if(tabActive === 'scheduled'){
                    setSpinState(true)
                    let page = pageSchedule - 1
                    let result = await listAppointmentSchedule(page)
                    setAppointmentScheduleData(result.data.scheduledAppointment.data)
                    if(result.data.scheduledAppointment.rowcount){
                        setTotalPageSchedule(result.data.scheduledAppointment.rowcount)
                    }
                    setSpinState(false)
                }
            } catch (e) {
                console.log(e)
            }
        }
        initDataListAppointment()
    }, [tabActive, pagePending, pageSchedule])

     const onChangeTab = (key) => {
        setTabActive(key)
    }

    const renderListAppointmentClientPending = () => {
        return appointmentPendingData.map(item =>
            <div className="item" key={item.appointmentId}>
                <div className="info-crew">
                    <div className="info-top">
                        <div className="thumb">
                            <Avatar onClick={() => selectedUser(item.CrewsId)} size={80} src={item.CrewProfilePic ? item.CrewProfilePic : NO_IMAGE} />
                        </div>
                        <div className="info">
                            <p className="name" onClick={() => selectedUser(item.CrewsId)}>{item.CrewsName} <span>${item.Price}</span></p>
                            <span className="appointment-name">{item.appointmentName}</span>
                            <p className='address'><EnvironmentFilled /> {item.Location}</p>
                            <p className='appointment-id'><FileTextOutlined />Appointment ID: #{item.appointmentId}</p>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <p>{item.appointmentDetail ? item.appointmentDetail : 'No info'}</p>
                    </div>
                </div>
                <div className="action">
                    <p className='time'>{moment(item.Date).format('MM-DD-YYYY')} - {moment(item.Date, 'HH:mm').format('hh:mm A')}</p>
                    <div className='duration'>
                        <p>Duration:</p>
                        <p>{item.Hours ? `${parseFloat(item.Hours).toFixed(1)} hour(s)` : `${parseFloat(item.Days).toFixed(1)} day(s)`}</p>
                    </div>
                    <Button className='detail' onClick={() => {onSeeDetail(item.appointmentId)}}>Detail</Button>
                    <Button className='cancel' onClick={() => {showModalCancel(item.appointmentId, CANCEL_APPOINTMENT_FOR_CLIENT)}}>Cancel</Button>
                </div>
            </div>
        )
    }

    const renderListAppointmentClientScheduled = () => {
        return appointmentScheduleData.map(item =>
            <div className="item" key={item.appointmentId}>
                <div className="info-crew">
                    <div className="info-top">
                        <div className="thumb">
                            <Avatar onClick={() => selectedUser(item.CrewsId)} size={80} src={item.CrewProfilePic ? item.CrewProfilePic : NO_IMAGE} />
                        </div>
                        <div className="info">
                            <p className="name" onClick={() => selectedUser(item.CrewsId)}>{item.CrewsName} <span>${item.Price}</span></p>
                            <span className="appointment-name">{item.appointmentName}</span>
                            <p className='address'><EnvironmentFilled /> {item.Location}</p>
                            <p className='appointment-id'><FileTextOutlined />Appointment ID: #{item.appointmentId}</p>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <p>{item.appointmentDetail ? item.appointmentDetail : 'No info'}</p>
                    </div>
                </div>
                <div className="action">
                    <p className='time'>{moment(item.Date).format('MM-DD-YYYY')} - {moment(item.Date, 'HH:mm').format('hh:mm A')}</p>
                    <div className='duration'>
                        <p>Duration:</p>
                        <p>{item.Hours ? `${parseFloat(item.Hours).toFixed(1)} hour(s)` : `${parseFloat(item.Days).toFixed(1)} day(s)`}</p>
                    </div>
                    <Button className='detail' onClick={() => {onSeeDetail(item.appointmentId)}}>Detail</Button>
                    <Button className='cancel' onClick={() => {showModalCancel(item.appointmentId, CANCEL_APPOINTMENT_FOR_CLIENT)}}>Cancel</Button>
                </div>
            </div>
        )
    }

    const renderListAppointmentCrewPending = () => {
        return appointmentPendingData.map(item =>
            <div className="item" key={item.appointmentId}>
                <div className="info-client">
                    <div className="info-top">
                        <div className="thumb">
                            <Avatar onClick={() => selectedUser(item.ClientID)} size={80} src={item.ClientProfilePic ? item.ClientProfilePic : NO_IMAGE} />
                        </div>
                        <div className="info">

                            <p className="name" onClick={() => selectedUser(item.CrewsId)}>{item.ClientName} <span>${item.Price}</span></p>
                            <span className="appointment-name">{item.appointmentName}</span>
                            <p className='address'><EnvironmentFilled /> {item.Location}</p>
                            <p className='appointment-id'><FileTextOutlined />Appointment ID: #{item.appointmentId}</p>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <p>{item.appointmentDetail ? item.appointmentDetail : 'No info'}</p>
                    </div>
                </div>
                <div className="action">
                    <p className='time'>{moment(item.Date).format('MM-DD-YYYY')} - {moment(item.Date, 'HH:mm').format('hh:mm A')}</p>
                    <div className='duration'>
                        <p>Duration:</p>
                        <p>{item.Hours ? `${parseFloat(item.Hours).toFixed(1)} hour(s)` : `${parseFloat(item.Days).toFixed(1)} day(s)`}</p>
                    </div>
                    <Button className='detail' onClick={() => {onSeeDetail(item.appointmentId)}}>Detail</Button>
                    <Popconfirm
                        title="Are you sure to accept this appointment?"
                        onConfirm={() => acceptedAppointment(item.appointmentId, ACCEPT_APPOINTMENT_FOR_CREW)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button loading={clicked === item.appointmentId && loading ? loading : false} disabled={!!(clicked && clicked !== item.appointmentId)} className='accept' >Accepted</Button>
                    </Popconfirm>
                    <Button className='cancel' onClick={() => {showModalCancel(item.appointmentId, DECLINE_APPOINTMENT_FOR_CREW)}} disabled={clicked}>Decline</Button>
                </div>
            </div>
        )
    }

    const renderListAppointmentCrewScheduled = () => {
        return appointmentScheduleData.map(item =>
            <div className="item" key={item.appointmentId}>
                <div className="info-client">
                    <div className="info-top">
                        <div className="thumb">
                            <Avatar onClick={() => selectedUser(item.ClientID)} size={80} src={item.ClientProfilePic ? item.ClientProfilePic : NO_IMAGE} />
                        </div>
                        <div className="info">
                            <p className="name" onClick={() => selectedUser(item.ClientID)}>{item.ClientName} <span>${item.Price}</span></p>
                            <span className="appointment-name">{item.appointmentName}</span>
                            <p className='address'><EnvironmentFilled /> {item.Location}</p>
                            <p className='appointment-id'><FileTextOutlined />Appointment ID: #{item.appointmentId}</p>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <p>{item.appointmentDetail ? item.appointmentDetail : 'No info'}</p>
                    </div>
                </div>
                <div className="action">
                    <p className='time'>{moment(item.Date).format('MM-DD-YYYY')} - {moment(item.Date, 'HH:mm').format('hh:mm A')}</p>
                    <div className='duration'>
                        <p>Duration:</p>
                        <p>{item.Hours ? `${parseFloat(item.Hours).toFixed(1)} hour(s)` : `${parseFloat(item.Days).toFixed(1)} day(s)`}</p>
                    </div>
                    <Button className='detail' onClick={() => {onSeeDetail(item.appointmentId)}}>Detail</Button>
                </div>
            </div>
        )
    }

    const acceptedAppointment =  async (appointmentId, type) => {
        setClickedButton(appointmentId)
        setLoading(true)
        const result = await actionAppointment(type, appointmentId, user.UserId)
        if(result.data.AppointmentAction.status){
            setMessageSuccess(result.data.AppointmentAction.Message)
            setOpenModalResult(true)
            setLoading(false)
            setClickedButton(null)
            let newListAppointmentPending = appointmentPendingData
            newListAppointmentPending = newListAppointmentPending.filter(item => item.appointmentId !== appointmentId)
            setAppointmentPendingData(newListAppointmentPending)
            setDetail(false)
        } else{
            setLoading(false)
            setClickedButton(null)
            return AlertError(result.data.AppointmentAction.Message)
        }
    }

    const onSeeDetail = (id) => {
        setAppointmentSelected(id)
        setDetail(true)
    }
    const closeDetail = () => {
        setDetail(false)
        setAppointmentSelected(null)
    }

    const closeModal = (id) => {
        // console.log(id)
        setVisibleModalShowProfile(false)
        setUserSelected(null)
        setOpenModalCancel(false)
        setAppointmentSelected(null)
        if(typeof id === 'number'){
            if(tabActive === 'pending'){
                let newDataAppointmentPending = appointmentPendingData.filter(item => item.appointmentId !== id)
                setAppointmentPendingData(newDataAppointmentPending)
            }
            if(tabActive === 'scheduled'){
                let newDataAppointmentSchedule = appointmentScheduleData.filter(item => item.appointmentId !== id)
                setAppointmentScheduleData(newDataAppointmentSchedule)
            }
            setDetail(false)
        }
        setTypeCancel(null)
        setOpenModalResult(false)
        setMessageSuccess(null)
        setOpenComplete(false)
    }

    const selectedUser = (userId) => {
        setVisibleModalShowProfile(true)
        setUserSelected(userId)
    }

    const showModalCancel = (appointmentId, type) => {
        setAppointmentSelected(appointmentId)
        setTypeCancel(type)
        setOpenModalCancel(true)
    }

    const onAcceptedAppointmentInDetail = async (id, type) => {
        await acceptedAppointment(id, type)
    }

    const onDeclineAppointmentInDetail = (id, type) => {
        setAppointmentSelected(id)
        setTypeCancel(type)
        setOpenModalCancel(true)
    }

    const onCompleteAppointment = (id, emailClient) => {
        setAppointmentSelected(id)
        setEmailClient(emailClient)
        setOpenComplete(true)
    }

    return (
        <div className='appointment-page page-children'>
            {!detail ?
                <div className='appointment'>
                    <Tabs defaultActiveKey={tabActive} onChange={onChangeTab}>
                        <Tabs.TabPane tab={`Pending`} key="pending">
                            {!spinState ?
                                appointmentPendingData.length > 0 ?
                                    <React.Fragment>
                                        {user.UserType === USER_TYPE_CREW ? renderListAppointmentCrewPending() : renderListAppointmentClientPending()}
                                        <div className="wp-pagination">
                                            <Pagination className='pagination-custom' pageSize={perPage} current={pagePending} total={totalPagePending} onChange={(page, pageSize) => setPagePending(page)} />
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className='widget-empty-data'>
                                        <EmptyData/>
                                    </div>
                                :
                                <Loading/>
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Scheduled" key="scheduled">
                            {!spinState ?
                                appointmentScheduleData.length > 0 ?
                                    <React.Fragment>
                                        {user.UserType === USER_TYPE_CREW ? renderListAppointmentCrewScheduled() : renderListAppointmentClientScheduled()}
                                        <div className="wp-pagination">
                                            <Pagination className='pagination-custom' pageSize={perPage} current={pageSchedule} total={totalPageSchedule} onChange={(page, pageSize) => setPageSchedule(page)} />
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className='widget-empty-data'>
                                        <EmptyData/>
                                    </div>
                                :
                                <Loading/>
                            }
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            :
                <ScheduleDetail appointmentSelected={appointmentSelected} closeDetail={closeDetail} onAcceptedAppointment={onAcceptedAppointmentInDetail} onDeclineAppointment={onDeclineAppointmentInDetail} onCompleteAppointment={onCompleteAppointment} onCancelAppointment={showModalCancel} loading={loading} />
            }

            {visibleModalShowProfile && useSelected && <ModalShowProfile idUser={useSelected} visible={visibleModalShowProfile}  closeModal={closeModal} />}
            {isOpenModalCancel && appointmentSelected && <ModalCancelAppointment appointmentId={appointmentSelected} typeCancel={typeCancel} visible={isOpenModalCancel}  closeModal={closeModal} userId={user.UserId} />}
            {isOpenModalResult && <ModalResultSuccess isSuccess={isOpenModalResult} messageSuccess={messageSuccess} closeModalSuccess={closeModal} />}
            {isOpenComplete && appointmentSelected && <ModalCompleteAppointment visible={isOpenComplete} appointmentId={appointmentSelected} emailClient={emailClient} authId={user.UserId} closeModal={closeModal} />}
        </div>
    );
}

export default Appointment;