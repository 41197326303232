import {useState} from 'react';
import {login, loginSocialFacebook, loginSocialGoogle, setToken} from '../actions';
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import AlertError from "../../../components/Alert/error";
import AlertSuccess from "../../../components/Alert/success";
import queryString from "query-string";
import {isEmpty} from "lodash";

const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {search} = useLocation();
  const values = queryString.parse(search);
  const goToPage = () => {
    if (!isEmpty(values)) {
      if (values.type === "raise") {
        history.push({pathname: '/search-project'})
      }
      if (values.type === "crew") {
        history.push({pathname: '/booking', search})
      }
    } else {
      history.push('/home');
    }

  }
  const formData = values => {
    setIsLoading(true);
    if (!values.username) {
      AlertError('Please input your username or email!')
      return setIsLoading(false);
    }
    if (!values.password) {
      AlertError('Please input your password!!')
      return setIsLoading(false);
    }
    if (values) {
      let dataToSubmit = {
        username: values.username,
        password: values.password,
        token: values.token ? values.token : ''
      };
      dispatch(login(dataToSubmit)).then(res => {
        if (res.payload.checkLogin.status) {
          const userData = res.payload.checkLogin.data;
          dispatch(setToken(userData.accessToken, userData.UserId));
          AlertSuccess(res.payload.checkLogin.Message)
          goToPage();
        } else {
          AlertError(res.payload.checkLogin.Message)
        }
        setIsLoading(false);
      })
    }
  };
  const loginFacebook = (values) => {
    setIsLoading(true);
    if (values) {
      dispatch(loginSocialFacebook(values)).then(res => {
        if (res.payload.socialLogin.status) {
          dispatch(setToken(res.payload.socialLogin.data.accessToken));
          goToPage();
          AlertSuccess('User logged in successfully!')
        } else {
          AlertError(res.payload.socialLogin.Message)
        }
        setIsLoading(false);
      })
    }
  };
  const loginGoogle = values => {
    if (values) {
      dispatch(loginSocialGoogle(values)).then(res => {
        if (res.payload.socialLogin.status) {
          dispatch(setToken(res.payload.socialLogin.data.accessToken));
          goToPage();
          AlertSuccess('User logged in successfully!')
        } else {
          AlertError(res.payload.socialLogin.Message)
        }
      })
    }
  };
  return [loginGoogle, loginFacebook, formData, isLoading];
};

export default useLogin;
