import React, {useEffect, useState} from 'react';
import './index.scss'
import {Button, Col, notification, Popconfirm, Row, Tabs} from 'antd';
import {PlusSquareOutlined, DeleteFilled, CreditCardFilled} from "@ant-design/icons/lib/icons";
import {useDispatch, useSelector} from "react-redux";
import {deleteCard, getListCard, getListPaymentHistory} from "../../state/payment/actions";
import StripeForm from "../../components/StripeForm";
import Loading from "../../components/Loading";
import EmptyData from "../../components/Empty";

const  Payment = (props) => {
      const {cards, histories} = useSelector(state => state.payments)
      const dispatch = useDispatch()
      const [isOpen, setOpen] = useState(false)
      const [tabActive, setTabActive] = useState('card')
      const [spinState, setSpinState] = useState(true)

      useEffect(() => {
            const initDataPayment = async () => {
                  try {
                        if(tabActive === 'card'){
                              setSpinState(true)
                              await dispatch(getListCard())
                              setSpinState(false)
                        }
                        if(tabActive === 'history'){
                              setSpinState(true)
                              await dispatch(getListPaymentHistory())
                              setSpinState(false)
                        }
                  } catch (e) {
                        console.log(e)
                        setSpinState(false)
                  }
            }
            initDataPayment()
      }, [tabActive, dispatch])

      const onChangeTab = (key) => {
            setTabActive(key)
      }

      const renderListCards = () => {
            return cards.map(item =>
                <Col key={item.id} className="item" xs={{ span: 24}} sm={{ span: 12}} md={{ span: 12}}>
                      <div className="wp-card">
                            <div className="content">
                                  <div className="thumb">
                                        <CreditCardFilled />
                                  </div>
                                  <div className="detail">
                                        <p className="brand">{item.brand}</p>
                                        <p className="number">**** **** **** {item.last4}</p>
                                        <p className="date">**/****</p>
                                  </div>
                            </div>
                            <div className="action">
                                  <Popconfirm
                                      title="Are you sure to delete this card?"
                                      onConfirm={() => deleteCardClient(item.id)}
                                      okText="Yes"
                                      cancelText="No"
                                  >
                                        <Button><DeleteFilled /></Button>
                                  </Popconfirm>
                            </div>
                      </div>
                </Col>
            )
      }

      const renderListHistory = () => {
            return  histories.map(item =>
                <div key={item.appointmentId} className='item'>
                      <div className="detail">
                            <div className='row-item'>
                                  <div className="title">
                                        <p>Service</p>
                                  </div>
                                  <div className="value">
                                        <p>{item.serviceName}</p>
                                  </div>
                            </div>
                            <div className='row-item'>
                                  <div className="title">
                                        <p>Crew</p>
                                  </div>
                                  <div className="value" >
                                        <p className='name-crew'>{item.CrewsName}</p>
                                  </div>
                            </div>
                            <div className='row-item'>
                                  <div className="title">
                                        <p>Time</p>
                                  </div>
                                  <div className="value">
                                        <p>{item.PaymentDate} {item.PaymentTime}</p>
                                  </div>
                            </div>
                      </div>
                      <div className="price-payment">
                            <p>${item.Price}</p>
                      </div>
                </div>
            )
      }

      const deleteCardClient = async (id) => {
            const result = await dispatch(deleteCard(id))
            if(result.payload.data.deleteCard.status){
                  notification.success({
                        message: result.payload.data.deleteCard.Message,
                        className: 'custom-notify-message-success'
                  });
            } else{
                  notification.warning({
                        message: result.payload.data.deleteCard.Message,
                        className: 'custom-notify-message-success'
                  });
            }
      }

      const closeModalCreateCard = () => {
            setOpen(false)
      }
      return (
          <div className='card-history-payment-page page-children'>
                <div className='payment'>
                      <Tabs defaultActiveKey={tabActive} onChange={onChangeTab}>
                            <Tabs.TabPane tab="Cards" key='card'>
                                  {!spinState ?
                                      <div className="list-card">
                                            <div className="head-list">
                                                  <p className="title">List Card</p>
                                                  <Button onClick={() => setOpen(true)}><PlusSquareOutlined /></Button>
                                            </div>
                                            {cards.length > 0 ?
                                                <Row className='body-list' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                                      {renderListCards()}
                                                </Row>
                                                :
                                                <EmptyData/>
                                            }

                                      </div>
                                  :
                                      <Loading/>
                                  }
                                  {isOpen && <StripeForm visible={isOpen} closeModalCreateCard={closeModalCreateCard} />}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="History" key='history'>
                                  {!spinState ?
                                          <div className="list-card">
                                            <div className="head-list">
                                                  <p className="title">History Payment</p>
                                            </div>
                                            {histories.length > 0 ?
                                                <div className='body-list-history' >
                                                      {renderListHistory()}
                                                </div>
                                                :
                                                <EmptyData/>
                                            }
                                      </div>
                                      :
                                      <Loading/>
                                  }
                            </Tabs.TabPane>
                      </Tabs>
                </div>
          </div>
      );
}

export default Payment;