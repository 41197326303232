import React, {useCallback, useEffect, useState} from 'react';
import ModalCheckService from "./components/ModalCheckService";
import {useDispatch, useSelector} from "react-redux";
import {USER_TYPE_CREW} from '../../constants';
import {getListServiceOfUser} from "../../state/service/actions";
import {getProfileUser, setCurrentUser} from "../../state/auth/actions";
import {Redirect} from "react-router-dom";
import VerificationEmail from "../login/components/VerifyEmail";

const Home = () => {
  const [visible, setVisible] = useState(false)
  const {user} = useSelector(state => state.auth)
  const {services} = useSelector(state => state.services)
  const [isOpen, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const [verifyEmail, setVerifyEmail] = useState(true)
  const accessToken = localStorage.getItem('access_token')

  useEffect(() => {
    const updateListServicesOfUserToState = async () => {
      await dispatch(getListServiceOfUser())
    }
    updateListServicesOfUserToState()
  }, [dispatch])

  useEffect(() => {
    const updateProfileUser = async () => {
      const result = await getProfileUser(user.UserId)
      dispatch(setCurrentUser(result.data.getProfile.data))
    }
    updateProfileUser()
  }, [dispatch, user.UserId])

  useEffect(() => {
    if (user.isVerified === 1) {
      setVerifyEmail(false)
    }
  }, [user])

  const closeVerify = () => {
    let userData = user
    userData.isVerified = 1
    dispatch(setCurrentUser(userData))
    setLoading(true)
    setVerifyEmail(false)
    checkEmptyService()
  }

  const checkEmptyService = useCallback(() => {
    if (user.UserType === USER_TYPE_CREW && user.isVerified !== 0 && services.length === 0) {
      setVisible(true)
      setOpen(true)
    } else {
      setOpen(false)
      setLoading(false)
    }
  }, [services.length, user.UserType, user.isVerified])

  useEffect(() => {
    checkEmptyService()
  }, [user, services, checkEmptyService])

  return (
    <div className=" page-children">
      {isOpen && <ModalCheckService visible={visible}/>}
      {verifyEmail &&
        <VerificationEmail email={user.Email} accessToken={accessToken} closeVerify={closeVerify}/>
      }
      {!loading && !verifyEmail && <Redirect to="/appointment"/>}
    </div>

  );
};

export default Home;
