import React, {useEffect, useState} from 'react';
import './index.scss';
import {Button, Avatar, Popconfirm, notification, Empty} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {deleteService, setServiceEdit} from "../../state/service/actions";
import {PlusSquareOutlined, FormOutlined , DeleteOutlined} from "@ant-design/icons/lib/icons";
import ModalSaveService from "./components/Form";
import {getListServices} from "../../actions/commonActions";
import Loading from "../../components/Loading";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Service = () => {
    const dispatch = useDispatch()
    const {services} = useSelector(state => state.services);
    const [visible, setVisible] = useState(false)
    const [listServicesConfig , setListServicesConfig] = useState([])
    const [listEquipment , setListEquipment] = useState([])

    useEffect(() => {
        const initDataService = async () => {
            let results = await getListServices()
            results = results.getConfig.data;
            setListServicesConfig(results.Services)
            setListEquipment(results.Equipent)
        }
        initDataService()
    }, [])


    const renderListServices = () => {
        return services.map((d, index) =>
            <div className="item-service" key={index} >
                <div className="content-service">
                    <div className="content">
                        <div className="thumb">
                            <Avatar size={48} src={d.service.image} />
                        </div>
                        <div className="detail">
                            <p className="title">{d.service.serviceName}
                                {/*<span className="price">${d.service.servicePrice}</span>*/}
                            </p>
                            <p className="equipment">{d.EquipmentName ? d.EquipmentName : ''}</p>
                            <div className="info-price">
                                <div className="item-info">
                                    <div className="title"><p>Minimum offer price/hour</p></div>
                                    <div className="value"><p>${parseFloat(d.serviceMinPrice).toFixed(2)}</p></div>
                                </div>
                                <div className="item-info">
                                    <div className="title"><p>Maximum offer price/hour</p></div>
                                    <div className="value"><p>${parseFloat(d.serviceMaxPrice).toFixed(2)}</p></div>
                                </div>
                                <div className="item-info">
                                    <div className="title"><p>Minimum offer price/day</p></div>
                                    <div className="value"><p>${parseFloat(d.dayMinPrice).toFixed(2)}</p></div>
                                </div>
                                <div className="item-info">
                                    <div className="title"><p>Maximum offer price/day</p></div>
                                    <div className="value"><p>${parseFloat(d.dayMaxPrice).toFixed(2)}</p></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="action">
                        <Button className='edit' onClick={() => handleShowModalEditService(d)}><FormOutlined /></Button>
                        <Popconfirm
                            placement="top"
                            title='Are you sure to delete this service?'
                            onConfirm={() => onDeleteService(d.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button className='delete'><DeleteOutlined /></Button>
                        </Popconfirm>
                    </div>
                </div>
            </div>
        );
    }

    const onDeleteService = async (id) => {
        const result = await dispatch(deleteService(id))
        if(result.payload.data.status){
            notification.success({
                message: result.payload.data.Message,
                className: 'custom-notify-message-success'
            });
        }else{
            notification.warning({
                message: result.payload.data.Message,
                className: 'custom-notify-message-success'
            });
        }
    }
    const closeModalSaveService = (isShow) => {
        setVisible(isShow)
    }
    const handleShowModalEditService = (item) => {
        dispatch(setServiceEdit(item))
        setVisible(true)
    }

    return (
        <div className="setting-service-wrapper service-list-page page-children">
            <div className="list-services">
                <div className="d-flex justify-content-between align-items-center header-widget">
                    <h3>Services</h3>
                    {listServicesConfig.length > 0 &&
                        <Button  onClick={() => setVisible(true)}><PlusSquareOutlined /></Button>
                    }

                </div>
                {listServicesConfig.length > 0 ?
                      <div className="services">
                          {services.length > 0 ?
                                renderListServices()
                                :
                                <div className='empty-data'>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                </div>
                          }
                      </div>
                : <Spin className='loading-services' indicator={antIcon} />}
            </div>
            {visible && <ModalSaveService visible={visible} closeModalSaveService={closeModalSaveService} listServicesConfig={listServicesConfig} listEquipment={listEquipment} />}
        </div>
    );
};

export default Service;
