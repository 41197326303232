import {notification} from "antd";

const AlertSuccess = (message) => {
      return (
          notification.success({
                message: message,
                className: 'custom-notify-message-success',
                duration : 6
          })
      )
}
export default AlertSuccess