/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {Button, Form, Input} from 'antd';
import {Link} from "react-router-dom";
import {LockFilled, UserOutlined} from '@ant-design/icons';

const LoginForm = props => (

    <Form
        name="normal_login"
        className="form-input"
        initialValues={{remember: true}}
        {...props}
    >
        <Form.Item
            className="w-100"
            name="username"
            // rules={[{required: true, message: 'Please input your username or email!'}]}
        >
            <Input placeholder="Email" className="w-100" prefix={<UserOutlined className="site-form-item-icon"/>}/>
        </Form.Item>
        <Form.Item
            name="password"
            className="w-100"
            // rules={[{required: true, message: 'Please input your password!'}]}
        >
            <Input.Password
                placeholder="Password"
                iconRender={visible => (visible ? 'Hide' : 'Show')}
                prefix={<LockFilled className="site-form-item-icon"/>}
            />
        </Form.Item>
        <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
            <Button loading={props.isLoading} htmlType="submit" className="w-100" id="btn-login">
                Sign in
            </Button>
            <br/>
            <Link className="login-form-forgot" to="/forgot-password">
                Forgot password ?
            </Link>
        </Form.Item>
    </Form>
);

export default LoginForm;
