
import produce from "immer";
import {ADD_SERVICE, DELETE_SERVICE, EDIT_SERVICE, GET_LIST_SERVICE, SET_SERVICE_EDIT} from "./actions";

let initialState = {
    services: [],
    serviceEdit : null,
};

const services =  (state = initialState, action = {}) => {
    return produce(state, draft => {
        switch (action.type) {
            case GET_LIST_SERVICE:
                draft.services = action.payload.data.data;
                break;
            case ADD_SERVICE:
                return {
                    ...state,
                    services: [action.payload.data.data].concat(state.services)
                }
            case SET_SERVICE_EDIT : {
                return {
                    ...state,
                    serviceEdit: action.payload.service
                }
            }
            case EDIT_SERVICE:
                let listServices = [...state.services]
                let id = action.payload.data.data.id;
                let foundIndex = listServices.findIndex(x => x.id === id);
                listServices[foundIndex] = action.payload.data.data
                return {
                    ...state,
                    services: listServices
                }
            case DELETE_SERVICE:
                return {
                    ...state,
                    services : state.services.filter(item => item.id !== action.payload.data.data.id)
                }

            default:
                return state;
        }
    })
}

export default services

