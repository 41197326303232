import React, {useEffect, useState} from 'react';
import './index.scss'
import {Rate, Tabs, Avatar, Pagination, Button} from 'antd';
import {EnvironmentFilled, SolutionOutlined} from "@ant-design/icons/lib/icons";
import {listHistoryAppointmentCancelAPI, listHistoryAppointmentCompleteAPI} from "../../state/appointment/action";
import moment from 'moment'
import ModalShowProfile from "../../components/Profile";
import Loading from "../../components/Loading";
import EmptyData from "../../components/Empty";
import {NO_IMAGE, PAYMENT_PAID, PAYMENT_UNPAID, USER_TYPE_CREW} from "../../constants";
import ScheduleDetail from "../Appointment/components/ScheduleDetail";
import ModalFinalPayAppointment from "./components/Pay";
import {useSelector} from "react-redux";

const History = (props) => {
    const [tab, setTab] = useState('complete')
    const [historyCompleteData, setHistoryCompleteData] = useState([])
    const [historyCancelData, setHistoryCancelData] = useState([])
    const [visibleModalShowProfile, setVisibleModalShowProfile] = useState(false)
    const [idUser , setIdUser] = useState(null)
    const [spinState, setSpinState] = useState(true)
    const [pageCompleted, setPageCompleted] = useState(1);
    const [pageCancel, setPageCancel] = useState(1);
    const [totalPageCompleted, setTotalPageCompleted] = useState(1)
    const [totalPageCancel, setTotalPageCancel] = useState(1)
    const perPage = 10;
    const [isOpenDetail, setOpenDetail] = useState(false)
    const [appointmentSelected , setAppointmentSelected] = useState(null)
    const [isOpenFinalPay, setOpenFinalPay] = useState(false)
    const {user} = useSelector(state => state.auth)

    const onChangeTab = (key) => {
        setSpinState(true)
        setTab(key)
    }

    useEffect(() => {
        let notificationData = JSON.parse(localStorage.getItem('notificationData'))
        if(notificationData){
            setAppointmentSelected(notificationData.appointmentId)
            setOpenDetail(notificationData.seeDetail)
        }
        localStorage.removeItem('notificationData')
    }, [])

    useEffect(() => {
        const initDataHistory = async () => {
            if(tab === 'complete'){
                setSpinState(true)
                let page = pageCompleted - 1
                const result = await listHistoryAppointmentCompleteAPI(page)
                setHistoryCompleteData(result.data.appointmentHistoryCompleted.data)
                if(result.data.appointmentHistoryCompleted.rowcount){
                    setTotalPageCompleted(result.data.appointmentHistoryCompleted.rowcount)
                }
                setSpinState(false)
            } else{
                setSpinState(true)
                let page = pageCancel - 1
                const result = await listHistoryAppointmentCancelAPI(page)
                setHistoryCancelData(result.data.appointmentHistoryCancel.data)
                if(result.data.appointmentHistoryCancel.rowcount){
                    setTotalPageCancel(result.data.appointmentHistoryCancel.rowcount)
                }
                setSpinState(false)
            }
        }
        initDataHistory()
    }, [tab, pageCancel, pageCompleted])

    const closeModal = (appointmentId , isPay) => {
        if(typeof appointmentId === 'number' && isPay === PAYMENT_PAID){
            let newListHistoryComplete = historyCompleteData
            let foundIndex = newListHistoryComplete.findIndex(x => x.appointmentId === appointmentId);
            newListHistoryComplete[foundIndex].paymentStatus = PAYMENT_PAID
            setHistoryCompleteData(newListHistoryComplete)
        }
        setVisibleModalShowProfile(false)
        setIdUser(null)
        setAppointmentSelected(null)
        setOpenFinalPay(false)
    }
    const selectedUser = (userId) => {
        setVisibleModalShowProfile(true)
        setIdUser(userId)
    }

    const onSeeDetail = (item) => {
        setAppointmentSelected(item)
        setOpenDetail(true)
    }

    const closeDetail = (appointmentId, isPay) => {
        if(tab === 'complete'){
            let newListHistoryComplete = historyCompleteData
            let foundIndex = newListHistoryComplete.findIndex(x => x.appointmentId === appointmentId);
            newListHistoryComplete[foundIndex].paymentStatus = isPay
            setHistoryCompleteData(newListHistoryComplete)
        }
        setOpenDetail(false)
    }

    const onShowFinalPayment = (item) => {
        setAppointmentSelected(item)
        setOpenFinalPay(true)
    }

    const renderListHistoryClientComplete = () => {
        return historyCompleteData.map(item =>
            <div key={item.appointmentId} className="item">
                <div className="info-crew">
                    <div className="info-top">
                        <div className="info">
                            <p className="name">{item.appointmentName}</p>
                        </div>
                        <div className="amount">
                            <p>${item.Price}</p>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <div className="time-address">
                            <p><SolutionOutlined />{moment(item.CompletedAt).format('LLL')}</p>
                            <p><EnvironmentFilled />{item.Location}</p>
                        </div>
                        <div className='info'>
                            <div>
                                <div className="title"><p>Crew</p></div>
                                <div className="detail">
                                    <Avatar onClick={() => selectedUser(item.CrewsId)} size={32} src={item.CrewProfilePic ? item.CrewProfilePic : NO_IMAGE} />
                                    <span onClick={() => selectedUser(item.CrewsId)} className='name'>{item.CrewsName}</span>
                                </div>
                            </div>
                            <div>
                                <div className="title"><p>{null}</p></div>
                                <div className="detail">
                                    <Rate disabled allowHalf  defaultValue={item.ratings} />
                                </div>
                            </div>
                            <div>
                                <div className="title"><p>Feedback</p></div>
                                <div className="detail">
                                    <span className='feedback'>{item.appointmentDetail ? item.appointmentDetail : 'No notes'}</span>
                                </div>
                            </div>
                            <div className='check-pay-appointment'>
                                {item.paymentStatus === PAYMENT_UNPAID && user && user.UserType !== USER_TYPE_CREW &&
                                    <p onClick={() => onShowFinalPayment(item)}>Unpaid. Click to pay</p>
                                }
                                {item.paymentStatus === PAYMENT_UNPAID && user && user.UserType === USER_TYPE_CREW &&
                                <p>Unpaid</p>
                                }
                            </div>
                            <div className='btn-detail'>
                                <Button onClick={() => {onSeeDetail(item)}}>Detail</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderListHistoryClientCancel = () => {
        return historyCancelData.map(item =>
            <div key={item.appointmentId} className="item">
                <div className="info-crew">
                    <div className="info-top">
                        <div className="info">
                            <p className="name">{item.appointmentName}</p>
                        </div>
                        <div className="amount">
                            <p>${item.Price}</p>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <div className="time-address">
                            <p><SolutionOutlined />{moment(item.CompletedAt).format('LLL')}</p>
                            <p><EnvironmentFilled />{item.Location}</p>
                        </div>
                        <div className='info'>
                            <div>
                                <div className="title"><p>Crew</p></div>
                                <div className="detail">
                                    <Avatar onClick={() => selectedUser(item.CrewsId)} size={32} src={item.CrewProfilePic ? item.CrewProfilePic : NO_IMAGE} />
                                    <span onClick={() => selectedUser(item.CrewsId)} className='name'>{item.CrewsName}</span>
                                </div>
                            </div>
                            <div>
                                <div className="title"><p>{null}</p></div>
                                <div className="detail">
                                    <Rate disabled allowHalf  defaultValue={item.ratings} />
                                </div>
                            </div>
                            <div>
                                <div className="title"><p>Reason</p></div>
                                <div className="detail">
                                    <span className='feedback'>{item.Reason ? item.Reason : 'No reason'}</span>
                                </div>
                            </div>
                            <div className='btn-detail'>
                                <Button onClick={() => {onSeeDetail(item)}}>Detail</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderListHistoryCrewComplete = () => {
        return historyCompleteData.map(item =>
            <div key={item.appointmentId} className="item">
                <div className="info-crew">
                    <div className="info-top">
                        <div className="info">
                            <p className="name">{item.appointmentName}</p>
                        </div>
                        <div className="amount">
                            <p>${item.Price}</p>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <div className="time-address">
                            <p><SolutionOutlined />{moment(item.CompletedAt).format('LLL')}</p>
                            <p><EnvironmentFilled />{item.Location}</p>
                        </div>
                        <div className='info'>
                            <div>
                                <div className="title"><p>Client</p></div>
                                <div className="detail">
                                    <Avatar onClick={() => selectedUser(item.ClientID)} size={32} src={item.ClientProfilePic ? item.ClientProfilePic : NO_IMAGE} />
                                    <span onClick={() => selectedUser(item.ClientID)} className='name'>{item.ClientName}</span>
                                </div>
                            </div>
                            <div>
                                <div className="title"><p>{null}</p></div>
                                <div className="detail">
                                    <Rate disabled allowHalf  defaultValue={item.ratings} />
                                </div>
                            </div>
                            <div>
                                <div className="title"><p>Feedback</p></div>
                                <div className="detail">
                                    <span className='feedback'>{item.appointmentDetail ? item.appointmentDetail : 'No notes'}</span>
                                </div>
                            </div>
                            <div className='check-pay-appointment'>
                                {item.paymentStatus === PAYMENT_UNPAID && user && user.UserType !== USER_TYPE_CREW &&
                                <p onClick={() => onShowFinalPayment(item)}>Unpaid. Click to pay</p>
                                }
                                {item.paymentStatus === PAYMENT_UNPAID && user && user.UserType === USER_TYPE_CREW &&
                                <p>Unpaid</p>
                                }
                            </div>
                            <div className='btn-detail'>
                                <Button onClick={() => {onSeeDetail(item)}}>Detail</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderListHistoryCrewCancel = () => {
        return historyCancelData.map(item =>
            <div key={item.appointmentId} className="item">
                <div className="info-crew">
                    <div className="info-top">
                        <div className="info">
                            <p className="name">{item.appointmentName}</p>
                        </div>
                        <div className="amount">
                            <p>${item.Price}</p>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <div className="time-address">
                            <p><SolutionOutlined />{moment(item.CompletedAt).format('LLL')}</p>
                            <p><EnvironmentFilled />{item.Location}</p>
                        </div>
                        <div className='info'>
                            <div>
                                <div className="title"><p>Client</p></div>
                                <div className="detail">
                                    <Avatar onClick={() => selectedUser(item.ClientID)} size={32} src={item.ClientProfilePic ? item.ClientProfilePic : NO_IMAGE} />
                                    <span onClick={() => selectedUser(item.ClientID)} className='name'>{item.ClientName}</span>
                                </div>
                            </div>
                            <div>
                                <div className="title"><p>{null}</p></div>
                                <div className="detail">
                                    <Rate disabled allowHalf  defaultValue={item.ratings} />
                                </div>
                            </div>
                            <div>
                                <div className="title"><p>Reason</p></div>
                                <div className="detail">
                                    <span className='feedback'>{item.Reason ? item.Reason : 'No reason'}</span>
                                </div>
                            </div>
                            <div className='btn-detail'>
                                <Button onClick={() => {onSeeDetail(item)}}>Detail</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='appointment-crews-page page-children'>
            {!isOpenDetail ?
                <div className='appointment'>
                    <Tabs defaultActiveKey={tab} onChange={onChangeTab}>
                        <Tabs.TabPane tab="Completed" key="complete">
                            {!spinState ?
                                historyCompleteData.length > 0 ?
                                    <div className='body-list-history' >
                                        {user.UserType === USER_TYPE_CREW ? renderListHistoryCrewComplete() : renderListHistoryClientComplete()}
                                        <div className="wp-pagination">
                                            <Pagination className='pagination-custom' pageSize={perPage} current={pageCompleted} total={totalPageCompleted} onChange={(page, pageSize) => setPageCompleted(page)} />
                                        </div>
                                    </div>
                                    :
                                    <div className='widget-empty-data'>
                                        <EmptyData />
                                    </div>

                                :   <Loading/>
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Cancelled" key="cancel">
                            {!spinState ?
                                historyCancelData.length > 0 ?
                                    <div className='body-list-history'>
                                        {user.UserType === USER_TYPE_CREW ? renderListHistoryCrewCancel() : renderListHistoryClientCancel()}
                                        <div className="wp-pagination">
                                            <Pagination className='pagination-custom' pageSize={perPage} current={pageCancel} total={totalPageCancel} onChange={(page, pageSize) => setPageCancel(page)} />
                                        </div>
                                    </div>
                                    :
                                    <div className='widget-empty-data'>
                                        <EmptyData/>
                                    </div>
                                :
                                <Loading/>
                            }
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            :
                <ScheduleDetail appointmentSelected={appointmentSelected.appointmentId} closeDetail={closeDetail} />
            }
            {visibleModalShowProfile && idUser && <ModalShowProfile idUser={idUser} visible={visibleModalShowProfile}  closeModal={closeModal} />}
            {isOpenFinalPay && appointmentSelected && <ModalFinalPayAppointment  visible={isOpenFinalPay} appointmentId={appointmentSelected.appointmentId} appointmentPrice={appointmentSelected.Price} crewName={appointmentSelected.CrewsName} crewProfileImage={appointmentSelected.CrewProfilePic} maxTip={appointmentSelected.MaxTip}  closeModal={closeModal} />}
        </div>
    );
}

export default History;