import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, Row, Select, TimePicker, Upload} from 'antd';
import "./style.scss"
import PlacesAutocomplete, {geocodeByAddress, getLatLng, geocodeByPlaceId} from "react-places-autocomplete";
import {CameraOutlined} from "@ant-design/icons/lib/icons";
import moment from 'moment'
import {getConfigService} from "../../state/auth/actions";
import {createNewProject} from "../../state/project/actions";
import AlertSuccess from "../../components/Alert/success";

const CreateProject = () => {
  const [form] = Form.useForm()
  const [services, setServices] = useState([])
  const [image, setImage] = useState([])
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState('')
  const [loadSubmit, setLoadSubmit] = useState(false)
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
    city: null,
    state: null
  })

  useEffect(() => {
    const listServicesConfig = async () => {
      try {
        let result = await getConfigService()
        setServices(result.data.getConfig.data.Services)
      } catch (e) {
        console.log(e);
      }
    }
    listServicesConfig()
  }, [])

  const renderListServices = () => {
    return services.map(item =>
      <Select.Option key={item.serviceId} value={item.serviceId}>{item.serviceName}</Select.Option>
    )
  }

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const dummyRequest = ({file, onSuccess}) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const onChangeImage = ({fileList: newFileList}) => {
    setImage(newFileList)
  }

  const renderFunc = ({getInputProps, getSuggestionItemProps, suggestions}) => {
    return (
      <div className="autocomplete-root">
        <Input  {...getInputProps({placeholder: 'Enter location'})} />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions.map(suggestion => {
            const style = {
              backgroundColor: suggestion.active ? '#92278f' : '#fff',
              padding: '10px',
              color: suggestion.active ? '#fff' : '#000',
              cursor: 'pointer'
            }
            return (
              <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, {style})}>
                <span>{suggestion.description}</span>
              </div>
            );
          })}
        </div>
      </div>
    )
  };

  const handleSelectAddress = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(results[0].place_id)
    let city = null
    let state = null

    if (place.address_components) {
      let cityData = place.address_components.filter(item => item.types.join().includes('locality'))
      if (cityData.length > 0) {
        city = cityData[0].long_name
      }
      let stateData = place.address_components.filter(item => item.types.join().includes('administrative_area_level_1'))
      if (stateData.length > 0) {
        state = stateData[0].short_name
      }
    }
    setCoordinates({
      lat: latLng.lat,
      lng: latLng.lng,
      city,
      state
    })
    setLocation(results[0].formatted_address);
  };

  const onCreateProject = async (values) => {
    setLoadSubmit(true)
    let formData = new FormData()
    let imageUpload = image.length > 0 ? image[0].originFileObj : null
    let dataSubmit = {
      serviceId: values.service,
      Company: values.company,
      City: coordinates.city,
      State: coordinates.state,
      Date: values.date ? moment(values.date).format('YYYY-MM-DD') : null,
      IsAllDay: 1,
      // StartTime: values.time ? moment(values.time).format('HH:mm:ss'): null,
      Image: imageUpload,
      accessToken: localStorage.getItem('access_token')
    }
    Object.keys(dataSubmit).forEach(item => formData.append(item, dataSubmit[item]))

    const response = await createNewProject(formData)
    AlertSuccess(response.Message)
    form.resetFields()
    setLocation('')
    setCoordinates({
      lat: 0,
      lng: 0,
      city: null,
      state: null
    })
    setImage([])
    setLoadSubmit(false)
  };

  return (
    <div className="create-project-page">
      <div className="wp-inner">
        <div className="wp-form">
          <h3 className="title-form">Create Project</h3>
          <Form
            form={form}
            name="create-project-hooks-form"
            initialValues={{remember: true}}
            onFinish={onCreateProject}
          >

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="service"
                >
                  <Select placeholder="Select a service">
                    {renderListServices()}
                  </Select>
                  {/*<Input placeholder="Enter Position"/>*/}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                >
                  <PlacesAutocomplete value={location}
                                      onChange={value => {
                                        console.log('value', value);
                                        setLocation(value)
                                      }}
                                      searchOptions={{
                                        types: ["geocode", "establishment"],
                                        componentRestrictions: {country: ['us']},

                                      }}
                                      onSelect={handleSelectAddress}
                  >
                    {renderFunc}
                  </PlacesAutocomplete>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="date"
            >
              <DatePicker popupClassName='dropdown-time-project' showNow={false} onChange={onChange}/>
            </Form.Item>
            <Form.Item
              name="company"
            >
              <Input placeholder="Enter company"/>
            </Form.Item>


            {/*<Row gutter={[16, 16]}>*/}
            {/*      <Col span={12} >*/}
            {/*            <Form.Item*/}
            {/*                  name="time"*/}
            {/*            >*/}
            {/*                  <TimePicker showNow={false} format='HH:mm' onChange={onChange} popupClassName='dropdown-time-project' defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />*/}
            {/*            </Form.Item>*/}
            {/*      </Col>*/}
            {/*      <Col span={12} >*/}
            {/*            <Form.Item*/}
            {/*                  name="date"*/}
            {/*            >*/}
            {/*                  <DatePicker popupClassName='dropdown-time-project' showNow={false} onChange={onChange} />*/}
            {/*            </Form.Item>*/}
            {/*      </Col>*/}
            {/*</Row>*/}

            <Form.Item
              name="image"
              className='image-upload'
            >
              <Upload
                listType="picture-card"
                fileList={image}
                customRequest={dummyRequest}
                onChange={onChangeImage}
                showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                accept='.jpg, .jpeg, .png'
              >
                {image.length < 1 && <CameraOutlined/>}
              </Upload>
            </Form.Item>

            <div className="btn-form">
              <Button loading={loadSubmit} htmlType='submit'>Create</Button>
            </div>

          </Form>
        </div>
      </div>

    </div>
  );
};

export default CreateProject;
