import React, {useEffect, useState} from 'react';
import {Button, Modal, Form, Input, Select} from "antd";
import './index.scss'
import {DollarOutlined} from "@ant-design/icons/lib/icons";
import {getListServices} from "../../../../actions/commonActions";
import {addService} from "../../../../state/service/actions";
import {useDispatch} from "react-redux";
import {useHistory } from "react-router-dom";
import AlertSuccess from "../../../../components/Alert/success";
import AlertError from "../../../../components/Alert/error";
import {MAX_PRICE_SERVICE} from "../../../../constants";

const ModalSaveService = ({visible, closeModalAddService}) => {
    const [listServices , setListServices] = useState([])
    const [listEquipment , setListEquipment] = useState([])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory();
    const accessToken = localStorage.getItem('access_token');
    const [form] = Form.useForm();

    useEffect(() => {
        initDataService()
    }, [])

    const initDataService = async () => {
        let services = await getListServices()
        setListServices(services.getConfig.data.Services)
        setListEquipment(services.getConfig.data.Equipent)
    }
    const renderOptionsService = () => {
        return listServices.map((d) => <Select.Option key={d.serviceId} value={d.serviceId}>{d.serviceName}</Select.Option>);
    }
    const renderOptionsEquipment = () => {
        return listEquipment.map((d) => <Select.Option key={d.equipmentID} value={d.equipmentID}>{d.equipmentName}</Select.Option>);
    }
    const onChangeService = (value) => {
        let service = listServices.filter(item => item.serviceId === value)
        let minPrice = service[0].servicePrice ? parseInt(service[0].servicePrice) : ''
        let maxPrice = service[0].Level2_Price ? parseInt(service[0].Level2_Price) : ''
        form.setFieldsValue({
            serviceMinPrice: minPrice,
            serviceMaxPrice: minPrice,
            dayMinPrice: maxPrice,
            dayMaxPrice: maxPrice,
        });
    }
    const onSaveService = async (values) => {
        if(!values.serviceId){
            return AlertError('Please input your service!')
        }
        // if(!values.equipmentID){
        //     return AlertError('Please input your equipment!')
        // }
        if(!values.serviceMinPrice){
            return AlertError('Please input minimum offer price per hour!')
        }
        if(parseFloat(values.serviceMinPrice) > MAX_PRICE_SERVICE){
            return AlertError(`The minimum offer price per hour does not exceed $${MAX_PRICE_SERVICE}!`)
        }
        if(!values.serviceMaxPrice){
            return AlertError('Please input maximum offer price per hour!')
        }
        if(parseFloat(values.serviceMaxPrice) > MAX_PRICE_SERVICE){
            return AlertError(`The maximum offer price per hour does not exceed $${MAX_PRICE_SERVICE}!`)
        }
        if(!values.dayMinPrice){
            return AlertError('Please input minimum offer price per day!')
        }
        if(parseFloat(values.dayMinPrice) > MAX_PRICE_SERVICE){
            return AlertError(`The minimum offer price per day does not exceed $${MAX_PRICE_SERVICE}!`)
        }
        if(!values.dayMaxPrice){
            return AlertError('Please input maximum offer price per day!')
        }
        if(parseFloat(values.dayMaxPrice) > MAX_PRICE_SERVICE){
            return AlertError(`The maximum offer price per day does not exceed $${MAX_PRICE_SERVICE}!`)
        }
        if(parseFloat(values.serviceMinPrice) > parseFloat(values.serviceMaxPrice)){
            return AlertError('The maximum offer per hour must be greater than the minimum offer!')
        }
        if(parseFloat(values.dayMinPrice) > parseFloat(values.dayMaxPrice)){
            return AlertError('The maximum offer per day must be greater than the minimum offer!')
        }
        setLoading(true);
        let formData = new FormData();
        let equipmentName = ''
        if(values.equipmentID){
            let equipment = listEquipment.filter(item => item.equipmentID === values.equipmentID)
            equipmentName = equipment[0].equipmentName
        }
        Object.keys(values).forEach(item => formData.append(item, values[item]))
        formData.append('EquipmentName', equipmentName)
        formData.append('accessToken', accessToken)
        const result = await dispatch(addService(formData));
        if(result.payload.data.status === true){
            AlertSuccess(result.payload.data.Message)
            setLoading(false);
            history.push('/service')
        } else{
            AlertError(result.payload.data.Message)
            setLoading(false);
        }
    }

    return (
        <div>
            <Modal
                footer={null}
                visible={visible}
                maskClosable={false}
                closable={false}
                centered
                className='modal-add-service'
                width={700}
            >
                <div>
                    <div className="header-modal">
                        <Button onClick={() => closeModalAddService(false)}>Close</Button>
                    </div>
                    <div className="body-modal">
                        <Form
                            name="form-save-service"
                            layout="vertical"
                            form={form}
                            initialValues={{
                                'serviceMinPrice' : '150',
                                'serviceMaxPrice' : '150',
                                'dayMinPrice' : '150',
                                'dayMaxPrice' : '150',
                            }}
                            onFinish={onSaveService}
                        >
                            <Form.Item name="serviceId"
                                       label="Service"
                                       // rules={[{ required: true , message: 'Please input your service!' }]}
                            >
                                <Select
                                    className='select-service-of-crew'
                                    placeholder="Select a service"
                                    onChange={onChangeService}
                                >
                                    {renderOptionsService()}
                                </Select>

                            </Form.Item>
                            <Form.Item name="equipmentID"
                                       label="Equipment"
                                       // rules={[{ required: true , message: 'Please input your Equipment!' }]}
                            >
                                <Select
                                    className='select-service-of-crew'
                                    placeholder="Select a equipment"
                                >
                                    {renderOptionsEquipment()}
                                </Select>

                            </Form.Item>
                            <Form.Item
                                name="serviceMinPrice"
                                label="Minimum offer price ($ / Per hour)"
                                // rules={[{ required: true, message: 'Please input minimum offer price per hour!' }]}
                            >
                                <Input placeholder='Minimum offer price ($ / Per hour)' prefix={<DollarOutlined/>}/>
                            </Form.Item>
                            <Form.Item
                                name="serviceMaxPrice"
                                label="Maximum offer price ($ / Per hour)"
                                // rules={[{ required: true, message: 'Please input maximum offer price per hour!' }]}
                            >
                                <Input placeholder='Maximum offer price ($ / Per hour)' prefix={<DollarOutlined/>}/>
                            </Form.Item>
                            <Form.Item
                                name="dayMinPrice"
                                label="Minimum offer price ($ / Per day)"
                                // rules={[{ required: true, message: 'Please input minimum offer price per day!' }]}
                            >
                                <Input placeholder='Minimum offer price ($ / Per day)' prefix={<DollarOutlined/>}/>
                            </Form.Item>
                            <Form.Item
                                name="dayMaxPrice"
                                label="Maximum offer price ($ / Per day)"
                                // rules={[{ required: true, message: 'Please input maximum offer price per day!' }]}
                            >
                                <Input placeholder='Maximum offer price ($ / Per day)' prefix={<DollarOutlined/>}/>
                            </Form.Item>
                            <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
                                <Button loading={loading} htmlType="submit" className="w-100" id="btn-save-service">
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ModalSaveService;