import React, {useEffect, useMemo, useState} from 'react';
import './index.scss'
import useLogin from '../../state/auth/hooks/useLogin';
import FormLogin from './containers/form';
import {Button, Input, Form} from "antd";
import {MailOutlined} from "@ant-design/icons";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import GoogleLogin from 'react-google-login';
import AlertError from "../../components/Alert/error";
import {validateEmail} from "../../utils/help";
import {messageFirebase} from "../../init-fcm";
import {NavLink} from "react-router-dom";

const Login = ({history}) => {
  const [loginGoogle, loginFacebook, setLogin, isLoading] = useLogin();
  const [token, setToken] = useState('')

  // getToken & push tokenID to server
  useEffect(() => {
    if(!messageFirebase) return
    messageFirebase.requestPermission()
        .then(() => {
            return messageFirebase.getToken()
        })
        .then(token => {
            // console.log('token notification', token)
            setToken(token)
        })
  }, [])

  const responseFacebook = (response) => {
    if (response.id) {
      // response.token = token
      loginFacebook(response);
    }
  }
  const responseGoogle = async (response) => {
    if (response.profileObj) {
      // response.profileObj.token = token
      loginGoogle(response.profileObj);
    }
  }

  const setDataLogin = (values) => {
    values.token = token
    setLogin(values)
  }

  const signInSocial = useMemo(() => {
    return <div className="mb-3">
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        // redirectUri={process.env.REACT_APP_FACEBOOK_URL_CALLBACK}
        disableMobileRedirect={true}
        render={renderProps => (
          <img onClick={renderProps.onClick} width="40" style={{marginRight: 10}} className="mr-4 cursor-pointer" src='/images/fb.png' alt=""/>
        )}
      />
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Login"
        className="cursor-pointer"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <img onClick={renderProps.onClick} width="40" className="cursor-pointer" src='/images/gg.png' alt=""/>
        )}
      />
    </div>
  }, [responseFacebook, responseGoogle]);

  const onSignup = (values) => {
    if (!values.email) {
      return AlertError('Please input your email!')
    }
    if (!validateEmail(values.email)) {
      return AlertError('Email invalid!')
    }
    history.push({pathname: '/signup', state: {email: values.email}});
  }
  return (
    <div className="login-wrapper">
      <div className="login-logo cursor-pointer">
        <div className="thumb">
          <img onClick={() => history.push('/home')} src="/images/logo.png" alt=""/>
        </div>
        <div>
          <nav className="Nav">
            <NavLink exact to="/" activeClassName="selected">Home</NavLink>
            <NavLink to="/about-us" activeClassName="selected">About Us</NavLink>
            <NavLink to="/search-crew" activeClassName="selected">Search Crew</NavLink>
            <NavLink to="/search-project" activeClassName="selected">Search Project</NavLink>
            <NavLink className='link-download-app' to="/app" activeClassName="selected">Download app</NavLink>
          </nav>
        </div>
      </div>
      <div className="login-form">
        <div className="left">
          <p>Sign in to</p>
          <h3>CREWSNOW</h3>
          {signInSocial}
          <h6 className="mb-3">or use your email account</h6>
          <FormLogin isLoading={isLoading} onFinish={(values) => setDataLogin((values))}/>
        </div>
        <div className="right">
          <p className="mb-3">New here ?</p>
          <h4 className="mb-5">Create an account</h4>
          {signInSocial}
          <h6 className="mb-4">or sign up by email</h6>
          <Form
            onFinish={onSignup}
            name="normal_signup"
            initialValues={{remember: true}}
          >
            <Form.Item
              className="w-100"
              name="email"
              // rules={[{required: true, message: 'Please input your email!'}, {type: "email", message: "Email invalid"}]}
            >
              <Input placeholder="Enter your email" className="w-100" prefix={<MailOutlined className="site-form-item-icon"/>}/>
            </Form.Item>
            <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
              <Button htmlType="submit" className="w-100" id="btn-signup">
                Sign up
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
