import axiosInstance from "../../axiosInstance";

export const bookAFullCrewsAPI = async (data) => {
      return await axiosInstance.post('/BookAFullCrews', data)
}

export const booking = async (data) => {
      return await axiosInstance.post('/BookAppointment', data)
}

