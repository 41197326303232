import React from 'react';
import {Empty} from "antd";
import './index.scss'

const EmptyData = (props) => {
      return (
          <div className='empty-data'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
      );
}

export default EmptyData;