import firebase from "firebase/app";
import "firebase/messaging";
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
      apiKey: "AIzaSyCMiXuuUxSyqDRUEnoLact37OH9qIuKgQc",
      authDomain: "crews-now.firebaseapp.com",
      databaseURL: "https://crews-now.firebaseio.com",
      projectId: "crews-now",
      storageBucket: "crews-now.appspot.com",
      messagingSenderId: "369413184968",
      appId: "1:369413184968:web:b225c07fa9541162b734fd"
};

firebase.initializeApp(firebaseConfig)
let messaging = null;
if (firebase.messaging.isSupported()){
      messaging = firebase.messaging();
}
export const messageFirebase = messaging

const auth = firebase.auth()
const db = firebase.database()
export {db, auth}

export default firebase