import React, {useEffect, useState} from 'react';
import {Avatar, Button, Input, Modal, Spin} from "antd";
import './index.scss'
import {SearchOutlined, EnvironmentFilled, ProfileOutlined} from "@ant-design/icons/lib/icons";
import {connectionList, sendRequestConnection} from "../../../../state/connection/action";
import {NO_IMAGE, STATUS_INVITATION_CONNECTION} from "../../../../constants";
import Loading from "../../../../components/Loading";
import ModalShowProfile from "../../../../components/Profile";
import EmptyData from "../../../../components/Empty";
import AlertSuccess from "../../../../components/Alert/success";
import AlertError from "../../../../components/Alert/error";
import {useDispatch} from "react-redux";

const ModalShowListConnections = ({visible, authId, closeModal, invitesData}) => {
  const [initListConnection, setInitListConnection] = useState([])
  const [spinState, setSpinState] = useState(true)
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [page, setPage] = useState(0);
  const perPage = 20;
  const [visibleModalShowProfile, setVisibleModalShowProfile] = useState(false)
  const [idUser, setIdUser] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [clicked, setClickedButton] = useState(null);
  const dispatch = useDispatch()
  const [totalRows, setTotalRows] = useState(0)

  useEffect(() => {
    const loadData = async () => {
      try {
        if (initListConnection.length === 0) {
          setSpinState(true)
        }
        setLoading(true)
        let result = await connectionList(authId, searchTerm, page, perPage)
        if (result.data.searchConnection.rowcount) {
          setTotalRows(result.data.searchConnection.rowcount - invitesData.length)
        }
        result = result.data.searchConnection.data;
        let listUserInvited = invitesData.map(item => item.UserId)
        let listData = result.filter(item => !listUserInvited.includes(item.UserId))
        setInitListConnection([...initListConnection, ...listData])

        setSpinState(false)
        setLoading(false)
      } catch (e) {
        console.log(e)
      } finally {
        setSpinState(false);
        setLoading(false)
      }
    };
    loadData();
  }, [page]);

  useEffect(() => {
    if (spinState === false) {
      setSpinState(true)
    }
    const searchData = async () => {
      let result = await connectionList(authId, searchTerm)
      result = result.data.searchConnection.data;
      let listUserInvited = invitesData.map(item => item.UserId)
      let listData = result.filter(item => !listUserInvited.includes(item.UserId))
      setInitListConnection(listData)
      setSpinState(false)
    }
    const delayDebounceFn = setTimeout(() => {
      searchData()
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const onFollow = async (connectionData) => {
    setClickedButton(connectionData.UserId)
    setLoadingSubmit(true)
    const result = await dispatch(sendRequestConnection(authId, connectionData.UserId, STATUS_INVITATION_CONNECTION, connectionData))
    if (result.payload.result.sendRequest.status) {
      setLoadingSubmit(false)
      setClickedButton(null)
      let newListConnectionData = initListConnection
      newListConnectionData = newListConnectionData.filter(item => item.UserId !== connectionData.UserId)
      setInitListConnection(newListConnectionData)
      AlertSuccess(result.payload.result.sendRequest.Message)
    } else {
      setLoadingSubmit(false)
      setClickedButton(null)
      AlertError(result.payload.result.sendRequest.Message)
    }
  }

  const renderListConnections = () => {
    return initListConnection.map(item =>
      <div className="item" key={item.UserId}>
        <div className="left">
          <div className="thumb">
            <Avatar size={24} onClick={() => selectedUser(item.UserId)} src={item.ProfilePic ? item.ProfilePic : NO_IMAGE}/>
          </div>
          <div className="name">
            <p onClick={() => selectedUser(item.UserId)}>{item.ConnectionName}</p>
            {item.serviceName &&
              <p className='address'><ProfileOutlined/>{item.serviceName}</p>
            }
            {item.Address &&
              <p className='address'><EnvironmentFilled/>{item.Address}</p>
            }
          </div>
        </div>
        <div className="right">
          <Button loading={clicked === item.UserId && loadingSubmit ? loadingSubmit : false} disabled={!!(clicked && clicked !== item.UserId)} onClick={() => onFollow(item)}>Invite</Button>
        </div>
      </div>
    )
  }

  const closeModalShowProfile = () => {
    setVisibleModalShowProfile(false)
    setIdUser(null)
  }
  const selectedUser = (userId) => {
    setVisibleModalShowProfile(true)
    setIdUser(userId)
  }

  return (
    <div>
      <Modal
        footer={null}
        visible={visible}
        maskClosable={false}
        closable={false}
        centered
        className='modal-show-list-connections'
        width={700}
      >
        <div className="head-modal">
          <h5 className="title-modal">List Connections</h5>
          <Button onClick={closeModal}>Close</Button>
        </div>
        <div className="body-modal">
          <div className="form-search">
            <Input placeholder="Search ..." className='w-100' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
            <SearchOutlined className='icon-search'/>
          </div>
          <div className="content">
            {!spinState ?
              initListConnection.length > 0 ?
                <div className="list">
                  {renderListConnections()}

                  <div className='load-more-data'>
                    {loading ?
                      <Spin className='spin-load-more-data'/>
                      :
                      initListConnection.length < totalRows &&
                      <Button className="btn-load-more" onClick={() => setPage(page + 1)}>Load more</Button>
                    }

                  </div>
                </div>
                :
                <div className='widget-empty-data'>
                  <EmptyData/>
                </div>
              :
              <Loading/>
            }

          </div>
        </div>
      </Modal>
      {visibleModalShowProfile && idUser && <ModalShowProfile idUser={idUser} visible={visibleModalShowProfile} closeModal={closeModalShowProfile}/>}
    </div>
  );
}

export default ModalShowListConnections;
