import React, {useEffect, useMemo, useState} from 'react';
import {CheckSquareFilled, CloseOutlined, EnvironmentFilled, FieldTimeOutlined, LoadingOutlined, PictureOutlined} from "@ant-design/icons/lib/icons";
import {Avatar, Button, Col, Empty, Form, Pagination, Row, Spin} from "antd";
import axiosInstance from "../../axiosInstance";
import AlertError from "../../components/Alert/error";

function MyProjectPage() {
  const accessToken = localStorage.getItem('access_token');
  const [project, setProject] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
  });
  const getListProject = (page) => {
    axiosInstance.post('/userProject', {
      Page: page || pagination.pageNumber,
      PerPage: pagination.pageSize,
      accessToken: accessToken || ""
    }).then(res => {
      if (res.data.data) {
        setProject(res.data.data || []);
        setPagination({...pagination, totalCount: res.data.total})
      }
      setIsLoading(false)
    }).catch(err => {
      console.log(err);
      AlertError(err.response?.message || "Server error. Please try again!")
      setIsLoading(false)
    })
  }
  useEffect(() => {
    setIsLoading(true);
    getListProject();
  }, [])

  async function handlePageChange(newPage) {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    getListProject(newPage);
  }

  const renderListProject = useMemo(() => {
    if (isLoading) {
      return <div className="show-loading">
        <Spin indicator={<LoadingOutlined style={{fontSize: 24, color: "#feb034"}} spin/>}/>
      </div>
    }
    if (project.length) {
      return <Row className='list' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>{project.map(item =>
        <Col key={item.ProjectId} className="wp-info-crew" xs={{span: 24}} sm={{span: 24}} md={{span: 12}}>
          <div className="info-crew">
            <div className="info">
              <div className="thumb">
                <Avatar size={48}
                        src={item.Image || `https://orbis-alliance.com/wp-content/themes/consultix/images/no-image-found-360x260.png`}/>
              </div>
              <div className="detail">
                <div className="name-rate">
                  <p className="name">{item.Company}</p>
                </div>
                <p className="location"><FieldTimeOutlined/>&nbsp;{item.Date}</p>
                <p className="location"><EnvironmentFilled/>&nbsp;{item.State} {item.City}</p>
                <p className="location"><PictureOutlined/>&nbsp;{item.serviceName}</p>
                <p className="location">{item.Status ? <CheckSquareFilled/> : <CloseOutlined/>}&nbsp;{item.Status ? `OPEN` : `CLOSED`}</p>
              </div>
            </div>
          </div>
        </Col>
      )}
      </Row>
    }
    return <div className='show-loading empty-data'>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    </div>
  }, [project, isLoading]);
  return (
    <div className='project-page'>
      <div className="wp-inner">
        <div className="widget">
          <div className="widget-body">
            <ul className="list-project">
              <Form.Item name='crewId'>
                {renderListProject}
              </Form.Item>
              <div className="mt-2 flex justify-content-end">
                {pagination.totalCount ? <Pagination pagination={pagination} onPageChange={handlePageChange}/> : null}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProjectPage;
