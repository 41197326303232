import React, {useState} from 'react';
import {Button, Form, Input, Modal, Radio, Space, Upload} from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';
import './index.scss'
import AlertError from "../../../../components/Alert/error";
import {actionAppointment, uploadAttachmentAppointmentComplete} from "../../../../state/appointment/action";
import {
      ACTION_COMPLETE_APPOINTMENT,
      ATTACHMENT_TYPE_SEND_MAIL,
      ATTACHMENT_TYPE_UPLOAD_FILE,
      ATTACHMENT_TYPE_USB
} from "../../../../constants";
import ModalResultSuccess from "../../../../components/Result";

const ModalCompleteAppointment = ({visible, appointmentId, emailClient , authId, closeModal}) =>  {
      const [loading, setLoading] = useState(false)
      const [type, setType] = useState(1)
      const [listAttachment, setListAttachment] = useState([])
      const accessToken = localStorage.getItem('access_token')
      const [isOpenModalResult, setOpenModalResult] = useState(false)
      const [messageSuccess, setMessageSuccess] = useState(null)

      const onChangeType = (e) => {
            setType(e.target.value)
      }

      const onCompleteAppointment = async (values) => {
            setLoading(true)
            if(type === ATTACHMENT_TYPE_USB){
                  const response = await actionAppointment(ACTION_COMPLETE_APPOINTMENT, appointmentId, authId)
                  if(response.data.AppointmentAction.status){
                        setMessageSuccess(response.data.AppointmentAction.Message)
                        setOpenModalResult(true)
                        setLoading(false)
                  } else{
                        setLoading(false)
                        return AlertError(response.data.AppointmentAction.Message)
                  }
            } else{
                  if(type === ATTACHMENT_TYPE_SEND_MAIL && !values.email){
                        return AlertError('Please enter email receiver attachment!')
                  }
                  if(type === ATTACHMENT_TYPE_UPLOAD_FILE && listAttachment.length === 0 && !values.links){
                        return AlertError('Please add file attachment or enter links!')
                  }
                  let data = {
                        accessToken : accessToken,
                        appointmentId : appointmentId,
                        AttachmentType : type,
                  }
                  let formData = new FormData();
                  Object.keys(data).forEach(item => formData.append(item, data[item]))
                  if(type === ATTACHMENT_TYPE_SEND_MAIL){
                        formData.append('Email', values.email)
                  }
                  if(type === ATTACHMENT_TYPE_UPLOAD_FILE){
                        formData.append('Links', values.links)
                        formData.append('AttachmentDoc', listAttachment[0])
                  }
                  const result = await uploadAttachmentAppointmentComplete(formData)
                  if(result.uploadAttachment.status){
                        const resultComplete =  await actionAppointment(ACTION_COMPLETE_APPOINTMENT, appointmentId, authId)
                        if(resultComplete.data.AppointmentAction.status){
                              setMessageSuccess(resultComplete.data.AppointmentAction.Message)
                              setOpenModalResult(true)
                              setLoading(false)
                        } else{
                              setLoading(false)
                              return AlertError(resultComplete.data.AppointmentAction.Message)
                        }
                  } else{
                        setLoading(false)
                        return AlertError(result.uploadAttachment.Message)
                  }
            }
      }

      const closeModalResult = () => {
            closeModal(appointmentId)
      }

      const normFile = (e) => {
            let files = e.fileList.map(item => item.originFileObj)
            setListAttachment(files)

            if (Array.isArray(e)) {
                  return e;
            }
            return e && e.fileList;
      };

      return (
          <React.Fragment>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-complete-appointment'
                    width={600}
                >
                      <div className="head-modal">
                            <h5>Attachment</h5>
                            <Button onClick={closeModal}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <Form
                                name="form-complete-appointment"
                                initialValues={{
                                      'type' : type,
                                      'email' : emailClient
                                }}
                                onFinish={onCompleteAppointment}
                            >
                                  <Form.Item
                                      name="type"
                                  >
                                        <Radio.Group onChange={onChangeType} className='type-complete-attachment'>
                                              <Space direction="vertical">
                                                    <Radio value={1}>Give USB hard drive</Radio>
                                                    <Radio value={2}>
                                                          <Form.Item
                                                              label="Email to"
                                                              name="email"
                                                              className='email-client'
                                                          >
                                                                <Input />
                                                          </Form.Item>
                                                    </Radio>
                                                    <Radio value={3}>
                                                          Add attachment
                                                          <div className={`wp-attachment ${type !== 3 && 'd-none'}`}>
                                                                <div className="upload">
                                                                      <span className='title'>Add files</span>
                                                                      <Form.Item
                                                                          name="file"
                                                                          valuePropName="fileList"
                                                                          getValueFromEvent={normFile}
                                                                          className='upload-file-attachment'
                                                                      >
                                                                            <Upload name="logo"
                                                                                    accept=".pdf"
                                                                                    maxCount={1}
                                                                                    action={process.env.REACT_APP_URL}
                                                                                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                                                    listType="picture"
                                                                            >
                                                                                  <Button className='btn-upload-file btn-upload-attachment'
                                                                                          icon={<CloudUploadOutlined/>}>Upload</Button>
                                                                            </Upload>
                                                                      </Form.Item>
                                                                </div>
                                                                <div>
                                                                      <Form.Item
                                                                          label="Add url"
                                                                          name="links"
                                                                      >
                                                                            <Input />
                                                                      </Form.Item>
                                                                </div>
                                                          </div>
                                                    </Radio>
                                              </Space>
                                        </Radio.Group>
                                  </Form.Item>
                                  <Form.Item >
                                        <div className='btn-action-appointment'>
                                              {/*<Button className='btn-cancel'>Cancel</Button>*/}
                                              <Button className='btn-complete' htmlType='submit' loading={loading}>Complete</Button>
                                        </div>
                                  </Form.Item>
                            </Form>
                      </div>
                </Modal>
                {isOpenModalResult && <ModalResultSuccess isSuccess={isOpenModalResult} messageSuccess={messageSuccess} closeModalSuccess={closeModalResult} />}
          </React.Fragment>


      );
}

export default ModalCompleteAppointment;