import React, {useMemo, useState} from 'react';
import {Button, Checkbox, Form, Input} from "antd";
import {MailOutlined, PhoneOutlined, UserOutlined, LockFilled} from "@ant-design/icons";
import './index.scss'
import {userSignup} from "../../state/auth/actions";
import useLogin from "../../state/auth/hooks/useLogin";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from "react-google-login";
import {Link} from "react-router-dom";
import AlertError from "../../components/Alert/error";
import VerificationEmail from "../login/components/VerifyEmail";
import UpdateProfile from "./components/UpdateProfile";
import {validateEmail, validatePhone} from "../../utils/help";
import ShowPdf from "../../components/Document";
import fileTerm from '../../assets/documents/terms-of-use.pdf';
import filePolicy from '../../assets/documents/privacy-policy.pdf';
const plainOptions = ['Crews', 'Client'];

const SignUp = ({location, history}) => {
    const [valueUserType, setValue] = React.useState('Crews');
    const [valueAgree, setValueAgree] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loginGoogle, loginFacebook] = useLogin();
    const [phone, setPhone] = useState('')
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [user, setUser] = useState({})
    const [isOpenUpdateUser, setOpenUpdateUser] = useState(false)
    const [isShowPdf, setShowPdf] = useState(false)
    const [filePdf, setFilePdf] = useState(null)

    const handleInputPhone = (e) => {
        const isInteger = /^[0-9]+$/;
        if (e.target.value === "" || isInteger.test(e.target.value)) {
            setPhone(e.target.value)
        }
    }

    const responseFacebook = (response) => {
        if (response.id) {
            loginFacebook(response);
        }
    }
    const responseGoogle = (response) => {
        if (response.profileObj) {
            loginGoogle(response.profileObj);
        }
    }
    const signInSocial = useMemo(() => {
        return <div className="mb-3">
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                render={renderProps => (
                    <img onClick={renderProps.onClick} width="40" style={{marginRight: 10}} className="mr-4 cursor-pointer" src='/images/fb.png' alt=""/>
                )}
            />
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Login"
                className="cursor-pointer"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                render={renderProps => (
                    <img onClick={renderProps.onClick} width="35" className="cursor-pointer" src='/images/gg.png' alt=""/>
                )}
            />
        </div>
    }, []);

    const onChange = value => {
        if (value.length)
            setValue(value.pop());
    };
    const onSignup = (values) => {
        if(!values.FirstName){
            return AlertError('Please input your first name!')
        }
        if(!values.LastName){
            return AlertError('Please input your last name!')
        }
        if(!values.Email){
            return AlertError('Please input your email!')
        }
        if(!validateEmail(values.Email)){
            return AlertError('Email invalid!')
        }
        if(!values.Password){
            return AlertError('Please input your password!')
        }
        if(!phone){
            return AlertError('Please input your phone!')
        }
        if(phone.length < 10){
            return AlertError('Phone number at least 10 characters!')
        }
        if(!validatePhone(phone)){
            return AlertError('Phone invalid!')
        }
        if(valueAgree.length === 0){
            return AlertError('Please check the checkbox I agree with Terms of Service and Privacy Policy!')
        }

        setLoading(true)
        userSignup({...values, Mobile: phone, UserType: valueUserType.toLowerCase() === 'crews' ? 'crews' : 'company'}).then(res => {
            if (res.data.userSignup.status) {
                // AlertSuccess(res.data.userSignup.Message)
                setUser(res.data.userSignup.data)
                setOpenUpdateUser(true)
            } else {
                AlertError(res.data.userSignup.Message)
            }
            setLoading(false);
        }).catch(err => {
            console.log(err);
        })
    }

    const closeUpdate = () => {
        setOpenUpdateUser(false)
        setVerifyEmail(true)
    }

    const showPdf = (file) => {
        setFilePdf(file)
        setShowPdf(true)
    }

    const closeModalShowPdf = () => {
        setShowPdf(false)
    }
    return (
        <React.Fragment>
            {!verifyEmail && !isOpenUpdateUser &&
                <Form
                    name="normal_signup"
                    initialValues={{remember: true}}
                    onFinish={onSignup}
                >
                    <div className="login-logo cursor-pointer">
                        <div className="thumb">
                            <img onClick={() => history.push('/home')} src="/images/logo.png" alt=""/>
                        </div>

                    </div>

                    <div className="signup-wrapper page-children">
                        <div className="head-signup">
                            <div className='title-signup'>
                                <h2>Sign up</h2>
                                <p>Fill your information below</p>
                            </div>
                            <div className='title-signup'>
                                <div className="d-flex justify-content-end align-items-center mb-3">
                                    <p className="mr-3 title-signup-other" style={{marginRight: 10}}>Sign up with </p>
                                    {signInSocial}
                                </div>
                            </div>
                        </div>
                        <div className="wp-inner-sign-up">
                            <div className="left">
                                <Form.Item
                                    className="w-100"
                                    name="FirstName"
                                    // rules={[{required: true, message: 'Please input your first name!'}]}
                                >
                                    <Input placeholder="First Name" className="w-100" prefix={<UserOutlined className="site-form-item-icon"/>}/>
                                </Form.Item>
                                <Form.Item
                                    className="w-100"
                                    name="LastName"
                                    // rules={[{required: true, message: 'Please input your last name!'}]}
                                >
                                    <Input placeholder="Last Name" className="w-100" prefix={<UserOutlined className="site-form-item-icon"/>}/>
                                </Form.Item>
                                <Form.Item
                                    className="w-100"
                                    name="Email"
                                    initialValue={location.state.email || ""}
                                    // rules={[{required: true, message: 'Please input your email!'}]}
                                >
                                    <Input placeholder="Email" className="w-100" prefix={<MailOutlined className="site-form-item-icon"/>}/>
                                </Form.Item>
                                <Form.Item
                                    name="Password"
                                    className="w-100"
                                    // rules={[{required: true, message: 'Please input your password!'}]}
                                >
                                    <Input.Password
                                        placeholder="Password"
                                        iconRender={visible => (visible ? 'Hide' : 'Show')}
                                        prefix={<LockFilled className="site-form-item-icon"/>}
                                    />
                                </Form.Item>
                                <Input placeholder="Phone" value={phone} onChange={handleInputPhone} className="w-100" prefix={<PhoneOutlined className="site-form-item-icon"/>}/>
                                {/*{error &&*/}
                                {/*    <div className="ant-form-item-explain ant-form-item-explain-error" >*/}
                                {/*        <div role="alert">{error}</div>*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </div>
                            <div className="right">
                                <Form.Item>
                                    <Checkbox.Group options={plainOptions} value={valueUserType} onChange={onChange}/>
                                </Form.Item>
                                <Form.Item>
                                    <Checkbox  onChange={e => setValueAgree(e.target.checked)}><p className='agree-term-policy'>I agree with <span onClick={() => showPdf(fileTerm)}>Terms of Service</span> and <span onClick={() => showPdf(filePolicy)}>Privacy Policy</span></p></Checkbox>
                                </Form.Item>
                                <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
                                    <Button loading={loading} htmlType="submit" className="w-100" id="btn-signup">
                                        Sign up
                                    </Button>
                                </Form.Item>
                                <div className='redirect-login'>
                                    <p>Already have an account? <Link to='/login'>Redirect to login</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            }
            {verifyEmail &&
                <VerificationEmail email={user.Email} accessToken={user.accessToken} />
            }
            {isOpenUpdateUser &&
                <UpdateProfile user={user} closeUpdate={closeUpdate} />
            }

            {isShowPdf &&  <ShowPdf  visible={isShowPdf} file={filePdf} type='term-policy' closeModal={closeModalShowPdf} />}
        </React.Fragment>
    );
};

export default SignUp;
