import React, {useMemo, useState} from "react";
import {useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement} from "@stripe/react-stripe-js";
import {Button, Form} from "antd";
import {useDispatch} from "react-redux";
import {addCard} from "../../../../state/payment/actions";
import AlertSuccess from "../../../Alert/success";
import AlertError from "../../../Alert/error";

const useOptions = () => {
      const options = useMemo(
          () => ({
                style: {
                      base: {
                            color: "#fff",
                            letterSpacing: "0.025em",
                            fontFamily: "Source Code Pro, monospace",
                            "::placeholder": {
                                  color: "#808080",
                                  fontSize : '13px'
                            }
                      },
                      invalid: {
                            color: "#ca3e47"
                      }

                }
          }),
          []
      );

      return options;
};

const SplitForm = ({onCloseModal}) => {
      const stripe = useStripe();
      const elements = useElements();
      const options = useOptions();
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false)
      const dispatch = useDispatch()
      const [error, setError] = useState(null);

      const saveCreditCard = async () => {
            setLoading(true)
            if (!stripe || !elements) {
                  return;
            }
            const cardNumberElement = elements.getElement(CardNumberElement)
            const token_card = await stripe.createToken(cardNumberElement)
            if(token_card.error){
                  setError(token_card.error);
                  setLoading(false)
                  return;
            }

            // console.log('token card', token_card)
            if(token_card && token_card.token){
                  const result = await dispatch(addCard(token_card.token.id, token_card.token.card.id, token_card.token.card.last4))
                  // console.log('result', result)
                  if(result.payload.card.addCards.status){
                        AlertSuccess(result.payload.card.addCards.Message)
                        setLoading(false)
                        onCloseModal()
                  } else{
                        setLoading(false)
                        AlertError(result.payload.card.addCards.Message)
                  }
            }
      }

      const ErrorMessage = ({ children }) => (
          <div className="ErrorMessage" role="alert">
                <svg width="13" height="13" viewBox="0 0 20 13">
                      <path
                          fill="#FFF"
                          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
                      />
                      <path
                          fill="#6772e5"
                          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
                      />
                </svg>
                {children}
          </div>
      );

      return (
          <div>
                <Form
                    form={form}
                    layout="vertical"
                    name="add-payment-method"
                    initialValues={{
                          'payment_method': 1 ,
                    }}

                    onFinish={saveCreditCard}
                >
                      <div className="info-card">
                            <label>
                                  Card number
                                  <CardNumberElement
                                      options={options}
                                      onChange={(event) => setError(event.error) }
                                  />
                            </label>
                            <div className='info-more'>
                                  <label>
                                        Expiration date
                                        <CardExpiryElement
                                            options={options}
                                            onChange={(event) => setError(event.error) }
                                        />
                                  </label>
                                  <label>
                                        Cvc
                                        <CardCvcElement
                                            options={options}
                                            onChange={(event) => setError(event.error) }
                                        />
                                  </label>
                            </div>
                      </div>

                      <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
                            {error && <ErrorMessage>{error.message}</ErrorMessage>}
                            <Button loading={loading} htmlType="submit" className="w-50" id="btn-add-payment-method">
                                  Create
                            </Button>
                      </Form.Item>
                </Form>
          </div>
      );
};

export default SplitForm;