import React, {useState} from 'react';
import {Button, Form, Input} from "antd";
import {MailOutlined, DoubleRightOutlined, LockOutlined} from "@ant-design/icons";
import './index.scss';
import {forgotPassword, checkOtp, changePassword} from "../../state/auth/actions";
import {useHistory} from "react-router-dom";
import AlertError from "../../components/Alert/error";
import AlertSuccess from "../../components/Alert/success";


const ForgotPassword = (props) => {
    const [loading, setLoading] = useState(false);
    const [isCheckOtp, setIsCheckOtp] = useState(false);
    const [email, setEmail] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false)
    const [userId , setUserId] = useState(null)
    const history = useHistory();

    const handleForgotPassword = async (values) => {
        if(!values.Email){
            return AlertError('Please enter the otp you received!')
        }
        setLoading(true)
        const response = await forgotPassword(values.Email);
        if (response.data.forgotPassword.status){
            AlertSuccess(response.data.forgotPassword.Message)
            setLoading(false)
            setEmail(values.Email)
            setIsCheckOtp(true)
        }else{
            AlertError(response.data.forgotPassword.Message)
            setLoading(false)
        }

    }
    const handleCheckOtp = async (values) => {
        if(!values.Otp){
            return AlertError('Please enter the otp you received!')
        }
        setLoading(true)
        setDisableButton(true);
        let data = {
            Email : email,
            Otp : values.Otp
        }
        const response = await checkOtp(data);
        if (response.data.Checkotp.status) {
            console.log(response.data.Checkotp)
            AlertSuccess(response.data.Checkotp.Message)
            setLoading(false)
            setIsCheckOtp(false)
            setUserId(response.data.Checkotp.data.UserId)
            setOpenChangePassword(true)
        } else {
            AlertError(response.data.Checkotp.Message)
            setLoading(false)
            setDisableButton(false);
        }
    }

    const onChangePassword = async (values) => {
        if(!values.Password){
            return AlertError('Please input new password!')
        }
        if(!values.ConfirmPassword){
            return AlertError('Please confirm new password!')
        }
        setLoading(true);
        let dataSubmit = {
            userId : userId,
            password : values.Password,
            confirmPassword : values.ConfirmPassword
        }
        let result = await changePassword(dataSubmit);
        if(result.data.changePassword.status){
            AlertSuccess(result.data.changePassword.Message)
            setLoading(false);
            history.push('/')
        } else{
            AlertError(result.data.changePassword.Message)
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <div className="login-logo cursor-pointer thumb">
                <img onClick={() => history.push('/home')} src="/images/logo.png" alt=""/>
            </div>
            <div className="forgot-password-page">
                {!isCheckOtp && !openChangePassword &&
                    <Form
                        className="w-40"
                        onFinish={handleForgotPassword}
                    >
                        <div className="left">
                            <h3 className='title'>Forgot Password</h3>
                            <p>
                                Enter Register Email ID
                                <br/>
                                (you'll receive an password reset link onto it)
                            </p>
                            <Form.Item
                                className="w-100"
                                name="Email"
                                // rules={[{required: true, message: 'Please input your email!'}]}
                            >
                                <Input placeholder="Email" className="w-100" prefix={<MailOutlined className="site-form-item-icon"/>}/>
                            </Form.Item>
                        </div>
                        <br/>
                        <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
                            <Button loading={loading} htmlType="submit" className="w-100" id="btn-forgot">
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                }
                {isCheckOtp  &&
                    <Form
                        className="w-40"
                        onFinish={handleCheckOtp}
                    >
                        <div className="header-form">
                            <div className="left">
                                <h3 className='title'>Check OTP</h3>
                                <p>
                                    Enter Otp you receiver from email
                                </p>
                            </div>
                            <div className="right">
                                <Button disabled={disableButton} onClick={() => setIsCheckOtp(false)}>Back <DoubleRightOutlined /></Button>
                            </div>
                        </div>
                        <Form.Item
                            className="w-100"
                            name="Otp"
                            // rules={[{required: true, message: 'Please input your Otp!'}]}
                        >
                            <Input placeholder="Otp" className="w-100" prefix={<LockOutlined className="site-form-item-icon"/>}/>
                        </Form.Item>
                        <br/>
                        <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
                            <Button loading={loading} htmlType="submit" className="w-100" id="btn-forgot">
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                }
                {openChangePassword &&
                    <Form
                        name="reset-password-form"
                        onFinish={onChangePassword}
                    >
                        <div className="wp-inner-reset-password">
                            <div className='title-change-password'>
                                <h3 className='title'>Reset Password</h3>
                                <p>Fill your information below</p>
                            </div>
                            <Form.Item
                                className="w-100"
                                name="Password"
                                // rules={[{required: true, message: 'Please input new password!'}]}
                            >
                                <Input.Password placeholder="New password" className="w-100" iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                prefix={<LockOutlined className="site-form-item-icon"/>}/>
                            </Form.Item>
                            <Form.Item
                                className="w-100"
                                name="ConfirmPassword"
                                // rules={[{required: true, message: 'Please confirm new password!'}]}
                            >
                                <Input.Password placeholder="Confirm password" className="w-100" iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                prefix={<LockOutlined className="site-form-item-icon"/>}/>
                            </Form.Item>

                            <Form.Item className="w-100">
                                <Button  loading={loading} htmlType="submit" className="w-100" id="btn_reset_password">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                    }
            </div>
        </React.Fragment>

    );
};

export default ForgotPassword;
