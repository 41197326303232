import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import './index.scss'
import {Button, Modal} from "antd";
import SplitForm from "./components/Form";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeForm = ({visible, closeModalCreateCard}) => {
      const onCloseModal = () => {
            closeModalCreateCard()
      }

      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-create-credit-card'
                    width={700}
                >
                      <div className="head-modal">
                            <p className="title">Add Card</p>
                            <Button onClick={closeModalCreateCard}>Close</Button>
                      </div>
                      <div className='wp-add-payment-method'>
                            <Elements stripe={stripePromise}>
                                  <SplitForm onCloseModal={onCloseModal} />
                            </Elements>
                      </div>
                </Modal>

          </div>


      );
};

export default StripeForm
