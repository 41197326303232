import React, {useState} from 'react';
import {Button, Modal, Upload} from "antd";
import './index.scss'
import {getBase64} from "../../../../utils/help";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {updateLicense} from "../../../../state/auth/actions";
import AlertError from "../../../../components/Alert/error";
import ModalResultSuccess from "../../../../components/Result";

const UploadDriveLicense = ({visible , closeModal}) => {
      const [licenseFrontFile , setLicenseFrontFile] = useState(null)
      const [licenseFrontImage , setLicenseFrontImage] = useState(null)
      const [licenseBackFile , setLicenseBackFile] = useState(null)
      const [licenseBackImage , setLicenseBackImage] = useState(null)
      const [loadingUploadFront , setLoadingUploadFront] = useState(false)
      const [loadingUploadBack , setLoadingUploadBack] = useState(false)
      const [loadingSubmit , setLoadingSubmit] = useState(false)
      const accessToken = localStorage.getItem('access_token')
      const [isOpenModalResult, setOpenModalResult] = useState(false)
      const [messageSuccess, setMessageSuccess] = useState(null)

      const uploadButtonLicenseFront = (
          <div>
                {loadingUploadFront ? <LoadingOutlined /> : <PlusOutlined />}
          </div>
      );

      const uploadButtonLicenseBack = (
          <div>
                {loadingUploadBack ? <LoadingOutlined /> : <PlusOutlined />}
          </div>
      );

      const handleUploadLicenseFront = info => {
            if (info.file.status === 'uploading') {
                  setLoadingUploadFront(true)
                  return;
            }
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  setLicenseFrontFile(info.file.originFileObj)
                  getBase64(info.file.originFileObj, imageUrl => {
                        setLicenseFrontImage(imageUrl)
                        setLoadingUploadFront(false)
                  });
            }
      };

      const handleUploadLicenseBack = info => {
            if (info.file.status === 'uploading') {
                  setLoadingUploadBack(true)
                  return;
            }
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  setLicenseBackFile(info.file.originFileObj)
                  getBase64(info.file.originFileObj, imageUrl => {
                        setLicenseBackImage(imageUrl)
                        setLoadingUploadBack(false)
                  });
            }
      };

      const saveDriveLicense = async () => {
            setLoadingSubmit(true)
            let formData = new FormData();
            formData.append('accessToken', accessToken)
            if(!licenseFrontFile){
                  setLoadingSubmit(false)
                  return AlertError('Please upload driver front license!')
            }
            if(!licenseBackFile){
                  setLoadingSubmit(false)
                  return AlertError('Please upload driver back license!')
            }
            formData.append('LicenseIdFront', licenseFrontFile);
            formData.append('LicenseIdBack', licenseBackFile);
            let result = await updateLicense(formData);
            if(result.data.status){
                  setMessageSuccess(result.data.Message)
                  setOpenModalResult(true)
                  setLoadingSubmit(false)
            }else {
                  setLoadingSubmit(false)
                  return AlertError(result.data.Message)
            }
      }

      const closeModalSuccess = () => {
            setOpenModalResult(false)
            closeModal(true)
      }

      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    width={500}
                    className='modal-upload-drive-license'
                >
                      <div className="head-modal">
                            <h5>Upload drive license</h5>
                            <Button onClick={() => closeModal(false)}>Close</Button>
                      </div>
                      <div className="body-modal">
                           <div className="upload-license">
                                 <div className="front-license">
                                       <p className='title'>Front of driver license</p>
                                       <Upload
                                           name="avatar"
                                           listType="picture-card"
                                           className="license-drive-upload"
                                           accept=".png, .jpg, .jpeg"
                                           showUploadList={false}
                                           action={process.env.REACT_APP_URL}
                                           onChange={handleUploadLicenseFront}
                                       >
                                             {licenseFrontImage ? <img src={licenseFrontImage} alt="avatar" style={{ width: '100%' }} /> : uploadButtonLicenseFront}
                                       </Upload>
                                 </div>
                                 <div className="back-license">
                                       <p className='title'>Back of driver license</p>
                                       <Upload
                                           name="avatar"
                                           listType="picture-card"
                                           className="license-drive-upload"
                                           accept=".png, .jpg, .jpeg"
                                           showUploadList={false}
                                           action={process.env.REACT_APP_URL}
                                           onChange={handleUploadLicenseBack}
                                       >
                                             {licenseBackImage ? <img src={licenseBackImage} alt="avatar" style={{ width: '100%' }} /> : uploadButtonLicenseBack}
                                       </Upload>
                                 </div>
                           </div>
                      </div>
                      <div className="footer-modal">
                            <Button className="skip" onClick={() => closeModal(false)}>Cancel</Button>
                            <Button loading={loadingSubmit} onClick={saveDriveLicense}>Upload & Verify</Button>
                      </div>
                </Modal>
                {isOpenModalResult && <ModalResultSuccess isSuccess={isOpenModalResult} messageSuccess={messageSuccess} closeModalSuccess={closeModalSuccess} />}
          </div>
      );
}

export default UploadDriveLicense;