import React, {useState} from 'react';
import './index.scss'
import {Button, Form, Input, Modal, notification} from "antd";
import {actionAppointment} from "../../../../state/appointment/action";

const ModalCancelAppointment= ({visible, userId, appointmentId, typeCancel, closeModal}) => {
      const [loading, setLoading] = useState(false)
      const [disableClose, setDisableClose] = useState(false)
      const [form] = Form.useForm();

      const cancelAppointment = async (values) => {
            setLoading(true)
            setDisableClose(true)

            const result = await actionAppointment(typeCancel, appointmentId, userId, values.reason)
            if(result.data.AppointmentAction.status){
                  notification.success({
                        message: result.data.AppointmentAction.Message,
                        className: 'custom-notify-message-success'
                  });
                  setLoading(false)
                  setDisableClose(false)
                  closeModal(appointmentId)
            } else{
                  setLoading(false)
                  setDisableClose(false)
                  return notification.warning({
                        message: result.data.AppointmentAction.Message,
                        className: 'custom-notify-message-success'
                  });
            }
      }
      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-cancel-appointment'
                    width={600}
                >
                      <div className='wp-cancel-appointment'>
                            <Form
                                form={form}
                                layout="vertical"
                                name="cancel-appointment"

                                onFinish={cancelAppointment}
                            >
                                  <div className="head-modal">
                                        <p className='title'>Cancel Appointment</p>
                                        <Button disabled={disableClose} onClick={closeModal}>Close</Button>
                                  </div>
                                  <div className='body-modal'>
                                        <Form.Item className='w-100'
                                                   name='reason'
                                                   rules={[{required: true, message: 'Please input your reason cancel appointment!'}]}

                                        >
                                              <Input.TextArea
                                                  placeholder="Enter reason"
                                                  autoSize={{ minRows: 6, maxRows: 8 }}
                                              />
                                        </Form.Item>
                                  </div>
                                  <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100 btn-action-appointment">
                                        <Button onClick={closeModal}>Cancel</Button>
                                        <Button loading={loading} htmlType="submit">
                                              Submit
                                        </Button>
                                  </Form.Item>
                            </Form>

                      </div>
                </Modal>
          </div>

      );
}

export default ModalCancelAppointment;