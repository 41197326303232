import React, {useState} from 'react';
import './index.scss'
import {Button, Form, Input} from "antd";
import {LockOutlined} from "@ant-design/icons";
import {changePassword} from "../../state/auth/actions";
import {useSelector} from "react-redux";
import AlertSuccess from "../../components/Alert/success";
import AlertError from "../../components/Alert/error";

const ChangePassword = (props) => {
    const [loading, setLoading] = useState(false);
    const {user} = useSelector(state => state.auth);
    const [form] = Form.useForm();

    const onChangePassword = async (values) => {
        if(!values.Password){
            return AlertError('Please input new password!')
        }
        if(!values.ConfirmPassword){
            return AlertError('Please confirm new password!')
        }
        setLoading(true);
        let dataSubmit = {
            userId : user.UserId,
            password : values.Password,
            confirmPassword : values.ConfirmPassword
        }
        let result = await changePassword(dataSubmit);
        if(result.data.changePassword.status){
            AlertSuccess(result.data.changePassword.Message)
            form.resetFields();
            setLoading(false);
        } else{
            AlertError(result.data.changePassword.Message)
            setLoading(false);
        }
    }
    return (
        <div className='change-password-page page-children h-100 pt-5'>
            <Form
                form={form}
                name="change_password"
                onFinish={onChangePassword}
            >
                <div className="wrapper">
                    <div className='title-change-password'>
                        <h2>Change password</h2>
                        <p>Fill your information below</p>
                    </div>
                    <Form.Item
                        className="w-100"
                        name="Password"
                        // rules={[{required: true, message: 'Please input new password!'}]}
                    >
                        <Input.Password placeholder="New password" className="w-100" iconRender={visible => (visible ? 'Hide' : 'Show')}
                               prefix={<LockOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>
                    <Form.Item
                        className="w-100"
                        name="ConfirmPassword"
                        // rules={[{required: true, message: 'Please confirm new password!'}]}
                    >
                        <Input.Password placeholder="Confirm password" className="w-100" iconRender={visible => (visible ? 'Hide' : 'Show')}
                               prefix={<LockOutlined className="site-form-item-icon"/>}/>
                    </Form.Item>

                    <Form.Item className="w-100">
                        <Button  loading={loading} htmlType="submit" className="w-100" id="btn_change_password">
                            Change
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}

export default ChangePassword;