import React, {useEffect, useMemo, useState} from 'react';
import {CheckSquareFilled, CloseOutlined, EnvironmentFilled, FieldTimeOutlined, LoadingOutlined, PictureOutlined} from "@ant-design/icons/lib/icons";
import {Avatar, Button, Col, Empty, Form, Input, Modal, Pagination, Row, Select, Spin} from "antd";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import axiosInstance from "../../axiosInstance";
import AlertError from "../../components/Alert/error";
import {getListServices} from "../../actions/commonActions";
import {USER_TYPE_CREW} from "../../constants";
import {useSelector} from "react-redux";
import './style.scss'
import {useHistory} from "react-router-dom";
import AlertSuccess from "../../components/Alert/success";


function ProjectPage() {
  const accessToken = localStorage.getItem('access_token');
  const history = useHistory();

  const {user} = useSelector(state => state.auth)
  const [project, setProject] = useState([])
  const [projectSelected, setProjectSelected] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalSignIn, setIsModalSignIn] = useState(false)
  const [loadingRaise, setLoadingRaise] = useState(false)
  const [location, setLocation] = useState('')
  const [position, setPosition] = useState('')
  const [listServices, setListServicesConfig] = useState([]);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
  });
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  });
  const getListProject = (page) => {
    axiosInstance.post('/listProject', {
      ...(position && {serviceId: position}),
      Location: location,
      Page: page || pagination.pageNumber,
      PerPage: pagination.pageSize,
      accessToken: accessToken || ""
    }).then(res => {
      if (res.data.data) {
        setProject(res.data.data || []);
        setPagination({...pagination, totalCount: res.data.total})
      }
      setIsLoading(false)
    }).catch(err => {
      console.log(err);
      AlertError(err.response?.message || "Server error. Please try again!")
      setIsLoading(false)
    })
  }
  useEffect(() => {
    setIsLoading(true);
    getListProject();
    const initDataService = async () => {
      let results = await getListServices()
      results = results.getConfig.data;
      setListServicesConfig(results.Services)
    }
    initDataService()
  }, [])
  const handleSelectLocation = async value => {
    try {
      setIsLoading(true);
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setLocation(value);
      const res = await axiosInstance.post('/listProject', {
        Location: value,
        ...(position && {serviceId: position}),
        Page: pagination.pageNumber,
        PerPage: pagination.pageSize,
        accessToken: accessToken || ""
      })
      if (res.data.data) {
        setProject(res.data.data || [])
      }
      setIsLoading(false)
      setCoordinates(latLng);
    } catch (err) {
      AlertError(err.response?.message)
      setIsLoading(false)
    }
  };

  const onSelectedProject = userId => {
    setProjectSelected(userId)
  }
  const handleChangePosition = async value => {
    try {
      setIsLoading(true);
      setPosition(value);
      const res = await axiosInstance.post('/listProject', {
        serviceId: value,
        Location: location,
        Page: pagination.pageNumber,
        PerPage: pagination.pageSize,
        accessToken: accessToken || ""
      })
      if (res.data.data) {
        setProject(res.data.data || [])
      }
      setIsLoading(false)
    } catch (err) {
      AlertError(err.response?.message)
      setIsLoading(false)
    }
  }

  async function handlePageChange(newPage) {
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
    getListProject(newPage);
  }

  const handleRaise = async (projectId) => {
    if (user && user.UserType === USER_TYPE_CREW) {
      try {
        setLoadingRaise(true);
        await axiosInstance.post("/raiseProject", {
          accessToken,
          ProjectId: projectId
        });
        getListProject();
        AlertSuccess("Apply Success!");
        setLoadingRaise(false);
      } catch (err) {
        setLoadingRaise(false);
      }
    } else {
      setIsModalSignIn(true)
    }
  }
  const renderListProject = useMemo(() => {
    if (isLoading) {
      return <div className="show-loading">
        <Spin indicator={<LoadingOutlined style={{fontSize: 24, color: "#feb034"}} spin/>}/>
      </div>
    }
    if (project.length) {
      return <Row className='list' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>{project.map(item =>
        <Col key={item.ProjectId} className="wp-info-crew" xs={{span: 24}} sm={{span: 24}} md={{span: 12}}>
          <div className="info-crew">
            <div className="info">
              <div className="thumb">
                <Avatar onClick={() => onSelectedProject(item.ProjectId)} size={48}
                        src={item.Image || `https://orbis-alliance.com/wp-content/themes/consultix/images/no-image-found-360x260.png`}/>
              </div>
              <div className="detail">
                <div className="name-rate">
                  <p onClick={() => onSelectedProject(item.ProjectId)} className="name">{item.Company}</p>
                </div>
                <p className="location"><FieldTimeOutlined/>&nbsp;{item.Date}</p>
                <p className="location"><EnvironmentFilled/>&nbsp;{item.State} {item.City}</p>
                <p className="location"><PictureOutlined/>&nbsp;{item.serviceName}</p>
                <p className="location">{item.Status ? <CheckSquareFilled/> : <CloseOutlined/>}&nbsp;{item.Status ? `OPEN` : `CLOSED`}</p>
                <Button loading={loadingRaise} disabled={loadingRaise || item.IsRaised} htmlType="submit" className="w-100" id="btn_apply" onClick={() => handleRaise(item.ProjectId)}>
                  {item.IsRaised ? "Applied" : "Raise"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      )}
      </Row>
    }
    return <div className='show-loading empty-data'>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    </div>
  }, [project, isLoading, loadingRaise]);
  return (
    <div className='project-page'>
      <div className="wp-inner">
        <div className="widget">
          <div className="widget-body">
            <div className="search-wrapper">
              <div className="title">
                Find a project near you
              </div>
              <div className="input-search">
                <div className="position">
                  <Select onChange={handleChangePosition} style={{width: "100%", height: "100%"}} placeholder='Select position' size="large" allowClear>
                    {listServices.map(service => (<Select.Option value={service.serviceId}>{service.serviceName}</Select.Option>))}
                  </Select></div>
                <div className="location">
                  <PlacesAutocomplete value={location}
                                      onChange={value => {
                                        setLocation(value)
                                      }}
                                      searchOptions={{
                                        componentRestrictions: {country: ['us']},
                                      }}
                                      onSelect={handleSelectLocation}
                  >

                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                      <div>
                        <Input className='input-search-gg' prefix={
                          <EnvironmentFilled style={{color: "white"}}/>}  {...getInputProps({placeholder: 'Enter location'})} />
                        <div>
                          {loading ? <div>...loading</div> : null}
                          {suggestions.map(suggestion => {
                            const style = {
                              backgroundColor: suggestion.active ? '#08ffc8' : '#fff',
                              padding: '10px'
                            }

                            return (
                              <div
                                key={suggestion.placeId} {...getSuggestionItemProps(suggestion, {style})}>
                                                                  <span
                                                                    key={suggestion.placeId}>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>

              </div>
            </div>
            <ul className="list-project">
              <Form.Item name='crewId'>
                {renderListProject}
              </Form.Item>
              <div className="mt-2 flex justify-content-end">
                {pagination.totalCount ? <Pagination pagination={pagination} onPageChange={handlePageChange}/> : null}
              </div>
            </ul>
            <Modal
              footer={null}
              open={isModalSignIn}
              maskClosable={false}
              closable={false}
              centered
              className='modal-create-credit-card'
              width={700}
            >
              <div className="head-modal d-flex justify-content-end">
                <Button onClick={() => setIsModalSignIn(false)}>Close</Button>
              </div>
              <div className='wp-add-payment-method text-center'>
                <p className='title'>Please login before using this feature</p>
                <Button onClick={() => history.push({pathname: '/login', search: `?type=raise`})
                } htmlType="submit" className="w-50" id="btn-add-payment-method">
                  Sign in
                </Button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectPage;
