import axiosInstance from '../../axiosInstance';

export const GET_LIST_NOTIFICATIONS = "GET_LIST_NOTIFICATIONS";
// export const ADD_NOTIFICATION = "ADD_NOTIFICATION";


export const listNotifications = async (pageNo) => {
    let accessToken = localStorage.getItem('access_token');
    let data = {
        "method": "notificatonList",
        "body": {
            "accessToken": accessToken,
            "pageNo": pageNo,
        }
    }
    let results = await axiosInstance.post('/notificatonList', data);
    return {
        type: GET_LIST_NOTIFICATIONS,
        payload: results.data
    };
}

// export const addNotification = (notification) => {
//     return {
//         type: ADD_NOTIFICATION,
//         payload: notification
//     };
// }
