import React, {useEffect, useState} from 'react';
import './index.scss'
import {Tabs, Avatar, Input, Button, Pagination, Popconfirm} from 'antd';
import {EnvironmentFilled, SearchOutlined, PlusSquareOutlined} from "@ant-design/icons/lib/icons";
import ModalShowProfile from "../../components/Profile";
import EmptyData from "../../components/Empty";
import Loading from "../../components/Loading";
import {
      confirmConnection,
      connectedList,
      pendingConnectionList, rejectConnection,
      unFollowConnection
} from "../../state/connection/action";
import {useDispatch, useSelector} from "react-redux";
import {
      NO_IMAGE,
      STATUS_CONFIRM_CONNECTION,
      STATUS_REJECT_CONNECTION,
      STATUS_UNFOLLOW_CONNECTION,
      USER_TYPE_CREW
} from "../../constants";
import ModalShowListConnections from "./components/ListConnections";
import AlertSuccess from "../../components/Alert/success";
import AlertError from "../../components/Alert/error";

const Connection = (props) => {
      const [tab, setTab] = useState('connections')
      const {listConnected} = useSelector(state => state.connections)
      const {listPending} = useSelector(state => state.connections)
      const [visibleModalShowProfile, setVisibleModalShowProfile] = useState(false)
      const [idUser , setIdUser] = useState(null)
      const [spinState, setSpinState] = useState(true)
      const {user} = useSelector(state => state.auth)
      const [isOpenModalListConnection, setOpenModalListConnection] = useState(false)
      const [searchTermConnected, setSearchTermConnected] = useState('')
      const [searchTermPending, setSearchTermPending] = useState('')
      const dispatch = useDispatch()
      const [pageConnected, setPageConnected] = useState(1);
      const [pagePending, setPagePending] = useState(1);
      const [totalPageConnected, setTotalPageConnected] = useState(1)
      const [totalPagePending, setTotalPagePending] = useState(1)
      const perPage = 10;
      const [loadingSubmit, setLoadingSubmit] = useState(false)
      const [loadingReject, setLoadingReject] = useState(false)

      const onChangeTab = (key) => {
            setSpinState(true)
            setTab(key)
      }

      useEffect(() => {
            let notificationData = JSON.parse(localStorage.getItem('notificationData'))
            if(notificationData){
                  onChangeTab(notificationData.tabActive)
            }
            localStorage.removeItem('notificationData')
      }, [])

      useEffect(() => {
            const initDataConnection = async () => {
                  if(tab === 'connections'){
                        setSpinState(true)
                        let page = pageConnected - 1;
                        const result = await dispatch(connectedList(searchTermConnected, page, perPage))
                        if(result.payload.searchConnected.rowcount){
                              setTotalPageConnected(result.payload.searchConnected.rowcount)
                        }
                        setSpinState(false)
                  } else{
                        setSpinState(true)
                        let page = pagePending - 1;
                        const result = await dispatch(pendingConnectionList(user.UserId, searchTermPending, page, perPage))
                        if(result.payload.pendingconnectionList.rowcount){
                              setTotalPagePending(result.payload.pendingconnectionList.rowcount)
                        }
                        setSpinState(false)
                  }
            }
            initDataConnection()
      }, [tab, pagePending, pageConnected])

      const searchData = async () => {
            if(tab === 'connections'){
                  if(spinState === false){
                        setSpinState(true)
                  }
                  const result = await dispatch(connectedList(searchTermConnected, 0 , perPage))
                  if(result.payload.searchConnected.rowcount){
                        setTotalPageConnected(result.payload.searchConnected.rowcount)
                  }
                  setSpinState(false)
                  setPageConnected(1)
            } else{
                  if(spinState === false){
                        setSpinState(true)
                  }
                  const result = await dispatch(pendingConnectionList(user.UserId, searchTermPending, 0 , perPage))
                  if(result.payload.pendingconnectionList.rowcount){
                        setTotalPagePending(result.payload.pendingconnectionList.rowcount)
                  }
                  setSpinState(false)
                  setPagePending(1)
            }
      }

      useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
                  searchData()
            }, 800)
            return () => clearTimeout(delayDebounceFn)
      }, [tab , searchTermPending, searchTermConnected])

      const unFollow = async (connectionId) => {
            setLoadingSubmit(true)
            const result = await dispatch(unFollowConnection(connectionId, STATUS_UNFOLLOW_CONNECTION , user.UserId ))
            if(result.payload.data.sendRequest.status){
                  AlertSuccess(result.payload.data.sendRequest.Message)
                  setLoadingSubmit(false)
            } else{
                  AlertError(result.payload.data.sendRequest.Message)
                  setLoadingSubmit(false)
            }
      }

      const onConfirm = async (connectionId) => {
            setIdUser(connectionId)
            setLoadingSubmit(true)
            const result = await dispatch(confirmConnection(connectionId, STATUS_CONFIRM_CONNECTION , user.UserId ))
            if(result.payload.data.sendRequest.status){
                  AlertSuccess(result.payload.data.sendRequest.Message)
                  setLoadingSubmit(false)
                  setIdUser(null)
            } else{
                  AlertError(result.payload.data.sendRequest.Message)
                  setLoadingSubmit(false)
                  setIdUser(null)
            }
      }

      const onReject = async (connectionId) => {
            setIdUser(connectionId)
            setLoadingReject(true)
            const result = await dispatch(rejectConnection(connectionId, STATUS_REJECT_CONNECTION , user.UserId ))
            if(result.payload.data.sendRequest.status){
                  AlertSuccess(result.payload.data.sendRequest.Message)
                  setLoadingReject(false)
                  setIdUser(null)
            } else{
                  AlertError(result.payload.data.sendRequest.Message)
                  setLoadingReject(false)
                  setIdUser(null)
            }
      }

      const renderListConnected = () => {
            return listConnected.map(item =>
                <div className="item" key={item.UserId}>
                      <div className="left">
                            <div className="thumb">
                                  <Avatar onClick={() => selectedUser(item.UserId)} size={24} src={item.ProfilePic ? item.ProfilePic : NO_IMAGE} />
                            </div>
                            <div className="name">
                                  <p onClick={() => selectedUser(item.UserId)}>{item.ConnectionName}</p>
                                  {item.Address &&
                                          <p className='address'> <EnvironmentFilled />{item.Address}</p>
                                  }
                            </div>
                      </div>
                      <div className="right">
                            <Popconfirm
                                title="Are you sure you want to unfollow this person?"
                                onConfirm={() => unFollow(item.UserId)}
                                okText="Yes"
                                cancelText="No"
                            >
                                  <Button loading={loadingSubmit} className='unfollow'>Unfollow</Button>
                            </Popconfirm>

                      </div>
                </div>
            )
      }

      const renderListInvites = () => {
            return listPending.map(item =>
                <div className="item" key={item.UserId}>
                      <div className="left">
                            <div className="thumb">
                                  <Avatar size={24} onClick={() => selectedUser(item.UserId)} src={item.ProfilePic ? item.ProfilePic : NO_IMAGE} />
                            </div>
                            <div className="name">
                                  <p onClick={() => selectedUser(item.UserId)}>{item.ConnectionName}</p>
                                  {item.Address &&
                                          <p className='address'> <EnvironmentFilled />{item.Address}</p>
                                  }
                            </div>
                      </div>
                      <div className="right">
                            {user && user.UserType !== USER_TYPE_CREW &&
                              <p className='pending'>Pending</p>
                            }
                            {user && user.UserType === USER_TYPE_CREW &&
                                    <div>
                                          <Button loading={loadingSubmit && idUser === item.UserId} className='confirm-connection' onClick={() => onConfirm(item.UserId)} >Confirm</Button>
                                          <Button loading={loadingReject && idUser === item.UserId} className='reject-connection' onClick={() => onReject(item.UserId)} >Reject</Button>
                                    </div>
                            }
                      </div>
                </div>
            )
      }

      const closeModal = () => {
            setVisibleModalShowProfile(false)
            setIdUser(null)
            setOpenModalListConnection(false)
      }
      const selectedUser = (userId) => {
            setVisibleModalShowProfile(true)
            setIdUser(userId)
      }

      return (
          <div className='connections-page page-children'>
                <div className='connection'>
                      <Tabs activeKey={tab} onChange={onChangeTab}>
                            <Tabs.TabPane tab="Connections" key="connections">

                                  <div className="wp-tab-body">
                                        <div className="head-tab">
                                             <h5 className="title-body">List Connections</h5>
                                        </div>
                                        <div className="form-search">
                                              <Input placeholder="Search ..."
                                                     className='w-100'
                                                     value={searchTermConnected}
                                                     onChange={(e) => setSearchTermConnected(e.target.value)}
                                              />
                                              <SearchOutlined className='icon-search'/>
                                        </div>
                                        {!spinState ?
                                            <div className="content-tab">
                                                  {listConnected.length > 0 ?
                                                      <div className="list">
                                                            {renderListConnected()}
                                                            <div className="wp-pagination">
                                                                  <Pagination className='pagination-custom' pageSize={perPage} current={pageConnected} total={totalPageConnected} onChange={(page, pageSize) => setPageConnected(page)} />
                                                            </div>
                                                      </div>
                                                  :
                                                      <div className='widget-empty-data'>
                                                            <EmptyData/>
                                                      </div>
                                                  }
                                            </div>
                                        :
                                            <Loading/>
                                        }
                                  </div>

                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Invites" key="invites">
                                  <div className="wp-tab-body">
                                        <div className="head-tab">
                                              <h5 className="title-body">List Invites</h5>
                                              {!spinState && user?.UserType !== USER_TYPE_CREW &&
                                                <Button onClick={() => setOpenModalListConnection(true)}><PlusSquareOutlined /></Button>
                                              }

                                        </div>
                                        <div className="form-search">
                                              <Input placeholder="Search ..."
                                                     className='w-100'
                                                     value={searchTermPending}
                                                     onChange={(e) => setSearchTermPending(e.target.value)}
                                              />
                                              <SearchOutlined className='icon-search'/>
                                        </div>
                                        {!spinState ?
                                            <div className="content-tab">
                                                  {listPending.length > 0 ?
                                                      <div className="list">
                                                            {renderListInvites()}
                                                            <div className="wp-pagination">
                                                                  <Pagination className='pagination-custom' pageSize={perPage} current={pagePending} total={totalPagePending} onChange={(page, pageSize) => setPagePending(page)} />
                                                            </div>
                                                      </div>
                                                      :
                                                      <div className='widget-empty-data'>
                                                            <EmptyData/>
                                                      </div>
                                                  }
                                            </div>
                                            :
                                            <Loading/>
                                        }
                                  </div>
                            </Tabs.TabPane>
                      </Tabs>
                </div>
                {visibleModalShowProfile && idUser && <ModalShowProfile idUser={idUser} visible={visibleModalShowProfile}  closeModal={closeModal} />}
                {isOpenModalListConnection  && <ModalShowListConnections visible={isOpenModalListConnection} authId={user.UserId}  closeModal={closeModal} invitesData={listPending} />}
          </div>
      );
}

export default Connection;