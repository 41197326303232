import React, {useRef} from 'react';
import {MailFilled, PhoneFilled, QuestionCircleOutlined} from "@ant-design/icons";
import {Space} from "antd";
import './style.scss';


function AboutUsPage() {
  const sliderRef = useRef();
  return (
    <div className='about-us-page'>
      <div className='banner-slide mb-4'>
        <img src="https://i.ibb.co/9n4g7tz/bg-about.png" width="100%" alt=""/>
      </div>
      <div className="information">
        <div className="top-info">
          <div className="info-top">
            <img src="/images/logo.png" width="150" alt=""/>
            <p className="location"><MailFilled/>support@crewsnow.com</p>
          </div>
          <div className="info-bottom">
            <Space direction="vertical">
              <span className="sub-title">ABOUT US</span>
              <div className="slogan">
                <span className="slogan-content"><strong>CREWS NOW</strong> is your one-stop destination for crews anywhere you need them with easy no hassle, no haggle pricing so the power is in the palm of your hands.
                  Hire crews & production industry professionals on-demand, at any time & any place around the World.  All this from your computer or mobile device efficiently, effectively & affordably.
                <p>The power shift has JUST begun, and the choice is now yours.</p>
                </span>
              </div>
            </Space>
          </div>
          <div className="info-bottom">
            <Space direction="vertical">
              <span className="sub-title">How Does it work?</span>
              <div className="slogan">
                <span className="slogan-content">
                  <p>Sign Up or Login and obtain trusted services.</p>
                  Types of Crews - Directors, Cinematographers, Camera Operators, Photographers, Sound Engineers, Lighting/Gaffer, Grip/Electric, HMAU (Hair & Makeup Artist), Stylist, DIT/1stAC, PA, Video Editor, CGI Visual Effects Artist, 2D Animators and many more.
                </span>
              </div>
            </Space>
          </div>
          <div className="info-bottom">
            <Space direction="vertical">
              <span className="sub-title">How to use Crews Now?</span>
              <div className="slogan">
                 <span className="slogan-content">
                    <p>Choose from over 30+ services and book professional crews within the website application.  A professional crew member will be notified of the confirmed location, date and time slot.</p>
                        Book a crew by simply adding your location, choosing a service, selecting a member and then mention details like date, time, extra notes and add attachments if needed.
                        You may need to wait for the crews’ confirmation and once it is done, you can check the details in scheduled appointment details.
                 </span>
              </div>
            </Space>
          </div>
        </div>
      </div>
      <div className="information mt-4">
        <div className="top-info">
          <div className="info-faq">
            <Space direction="vertical w-100">
              <span className="sub-title">FAQ</span>
              <span className="sub-question d-flex align-items-center gap-2"><QuestionCircleOutlined/> How to book crew?</span>
              <div className="slogan mb-2">
               <span className="slogan-content">
                  <p>First select the location desired, Next select a service you wish to utilize and then go through crew member's details and select one that fits what you are looking for.</p>
                  <p>Make sure you provide all the project details, request a booking and wait for the confirmation.</p>
                  <p>Once confirmed you can proceed for the payment as per the terms. There is a limited ability to call through the app and connect with the selected crew member as needed.</p>
                </span>
              </div>
              <span className="text-white">
                  <p>Crew Profiles - We provide a variety of professional crews to help you. You can check their work details, ratings, and location.</p>
                  <p>Transparent and easy payments - Seamless payment options.</p>
                </span>
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsPage;
