import React, {useState} from 'react';
import {Avatar, Button, Form, Input, notification, Select, Upload} from "antd";
import { UserOutlined, CameraFilled, EnvironmentOutlined, PhoneOutlined, VerifiedOutlined} from "@ant-design/icons";
import './index.scss';
import {useDispatch, useSelector} from "react-redux";
import {editProfile, setCurrentUser} from "../../state/auth/actions";
import {getBase64} from "../../utils/help";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import {NO_IMAGE, USER_TYPE_CREW} from "../../constants";
import {useHistory} from "react-router-dom";
import AlertError from "../../components/Alert/error";

const EditProfile = () => {
    const [loading, setLoading] = useState(false)
    const {user} = useSelector(state => state.auth)
    const [avatar, setAvatar] = useState(user.ProfilePic);
    const [fileAvatar, setFileAvatar] = useState(null)
    const [location, setLocation] = useState(user.Location)
    const [coordinates, setCoordinates] = React.useState({
        lat: null,
        lng: null
    });
    const dispatch = useDispatch()
    const history = useHistory();

    const statusVaccine = [
        {
            type : 0,
            name : 'No',
        },
        {
            type : 1,
            name : 'Vaccinated',
        },
        {
            type : 2,
            name : 'Fully vaccinated',
        }
    ]

    const handleChangeAvatar = info => {
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                {
                    setAvatar(imageUrl)
                }
            );
            setFileAvatar(info.file.originFileObj)
        }
    };

    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setLocation(value);
        setCoordinates(latLng);
    };

    const handleUpdateProfile = async (values) => {
        if(!values.FirstName){
            return AlertError('Please input your first name!')
        }
        if(!values.LastName){
            return AlertError('Please input your last name!')
        }
        if(!values.MobileNo){
            return AlertError('Please input your phone!')
        }
        if(!location){
            return AlertError('Please input your location!')
        }
        setLoading(true);
        if(location === ''){
            setLoading(false)
            return;
        }
        let data = {
            ...values,
            UserId: user.UserId,
            UserType: user.UserType,
            Email : user.Email,
            UserName : user.UserName,
            UserExpirence : user.UserExpirence
        }
        let formData = new FormData();
        Object.keys(data).forEach(item => formData.append(item, data[item]))
        formData.append('profile', fileAvatar)
        formData.append('Location', location)
        formData.append('Latitude', coordinates.lat ? coordinates.lat : user.Latitude)
        formData.append('Longitude', coordinates.lng ? coordinates.lng : user.Longitude)
        const result = await editProfile(formData);
        if(result.data.editProfile.status === true){
            notification.success({
                message: result.data.editProfile.Message,
                className: 'custom-notify-message-success'
            });
            dispatch(setCurrentUser(result.data.editProfile.data))
            setLoading(false);
            history.push('/profile')
        } else{
            notification.warning({
                message: result.data.editProfile.Message,
                className: 'custom-notify-message-success'
            });
            setLoading(false);
        }
    }
    return (
        <React.Fragment>
            <div className="update-profile-page page-children">
                <div className="form-update-profile">
                    <div className="title">
                        <h2>Edit Profile</h2>
                        <p>Fill you information below</p>
                    </div>
                    <Form
                        className="w-100"
                        name='update-profile'
                        onFinish={handleUpdateProfile}
                        initialValues={{
                            'FirstName': user.FirstName,
                            'LastName': user.LastName,
                            'MobileNo': user.MobileNo,
                            'CompanyName': user.CompanyName,
                            'Location' : user.Location,
                            'AboutMe' : user.AboutMe,
                        }}
                    >
                        <div className="wrapper-inner">
                            <div className="left">
                                <div className="image-profile ml-3 text-center">
                                    <p className="d-flex align-items-center ">
                                        <UserOutlined className="icon-user"/>
                                        Upload your profile picture
                                    </p>
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        accept=".jpg,.png,.jpeg"
                                        action={process.env.REACT_APP_URL}
                                        // action="http://crew.kendemo.com"
                                        onChange={handleChangeAvatar}
                                    >
                                        {avatar ? <Avatar size={120} src={avatar} /> : <Avatar size={120} src={NO_IMAGE} />}
                                        <CameraFilled className="icon-upload"/>
                                    </Upload>
                                </div>
                                <div className="name">
                                    <Form.Item
                                        className="w-100"
                                        name="FirstName"
                                        // rules={[{required: true, message: 'Please input your first name!'}]}
                                    >
                                        <Input placeholder="First name" className="w-100" prefix={<UserOutlined className="site-form-item-icon"/>}/>
                                    </Form.Item>
                                    <Form.Item
                                        className="w-100"
                                        name="LastName"
                                        // rules={[{required: true, message: 'Please input your last name!'}]}
                                    >
                                        <Input placeholder="Last name" className="w-100" prefix={<UserOutlined className="site-form-item-icon"/>}/>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="right">
                                <Form.Item
                                    className="w-100"
                                    name="MobileNo"
                                    // rules={[{required: true, message: 'Please input your phone!'}]}
                                >
                                    <Input placeholder="Phone" className="w-100" prefix={<PhoneOutlined className="site-form-item-icon"/>}/>
                                </Form.Item>
                                {/*<Form.Item*/}
                                {/*    className="w-100"*/}
                                {/*    name="CompanyName"*/}
                                {/*>*/}
                                {/*    <Input placeholder="Company name" className="w-100"*/}
                                {/*           prefix={<HomeOutlined className="site-form-item-icon"/>}/>*/}
                                {/*</Form.Item>*/}
                                <Form.Item
                                    // className={`${location === '' ? 'w-100 ant-form-item-has-error' : 'w-100'}`}
                                    // rules={[{required: true, message: 'Please input your location!'}]}
                                >
                                    <PlacesAutocomplete value={location}
                                                        onChange={value => setLocation(value)}
                                                        searchOptions={{
                                                            // types: ['address'],
                                                            componentRestrictions: { country: ['us'] },
                                                        }}
                                                        onSelect={handleSelect}
                                    >

                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <Input  prefix={<EnvironmentOutlined className="location-icon"/>}   {...getInputProps({placeholder: 'Location' })} />
                                                <div>
                                                    {loading ? <div>...loading</div> : null}
                                                    {suggestions.map(suggestion => {
                                                        const style = {
                                                            backgroundColor : suggestion.active ? '#08ffc8' : '#fff' , padding : '10px'
                                                        }
                                                        return (
                                                            <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                                <span key={suggestion.placeId}>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {/*{location === '' &&*/}
                                    {/*    <div className="ant-form-item-explain ant-form-item-explain-error" >*/}
                                    {/*        <div role="alert">Please input your location!</div>*/}
                                    {/*    </div>*/}
                                    {/*}*/}

                                </Form.Item>
                                <Form.Item name='AboutMe'>
                                    <Input.TextArea autoSize={{ minRows: 5, maxRows: 8 }} placeholder="About me" />
                                </Form.Item>

                                {user.UserType === USER_TYPE_CREW &&
                                <Form.Item>
                                    <Form.Item name="vaccine_status" className='status-vaccine'>
                                        <Select placeholder="Covid-19 status?">
                                            {statusVaccine.map(item =>
                                                <Select.Option key ={item.type} value={item.type}>{item.name}</Select.Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className='icon-vaccine'>
                                        <VerifiedOutlined />
                                    </Form.Item>
                                </Form.Item>
                                }
                                <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
                                    <Button loading={loading} htmlType="submit" className="w-100" id="btn-update-profile">
                                        Save
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>

            </div>
        </React.Fragment>
    );
};

export default EditProfile;
