import React, {useState} from 'react';
import {Button,Modal} from "antd";
import './index.scss'
import ModalFinalPayAppointment from "../../../History/components/Pay";
import {PAYMENT_PAID} from "../../../../constants";

const ModalCheckAppointment = ({visible, appointment , closeModal}) =>  {
      const [isOpenFinalPay, setOpenFinalPay] = useState(false)

      const closeModalPayment = (appointmentId , isPay) => {
            setOpenFinalPay(false)

            if(appointmentId && isPay === PAYMENT_PAID){
                  closeModal(isPay)
            }
      }
      return (
          <React.Fragment>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-check-appointment'
                    width={500}
                >
                      <div className="head-modal">
                            <h5>Notification</h5>
                            <Button onClick={closeModal}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <div className="content-check">
                                  <p>Your previous appointment payment is pending! (ID: #{appointment.appointmentId})</p>
                                  <p>Please make a payment so that you can book other appointments.</p>
                            </div>
                            <div className="btn-pay w-100">
                                  <Button className='w-100' onClick={() => setOpenFinalPay(true)}>Pay now</Button>
                            </div>
                      </div>
                </Modal>
                {isOpenFinalPay && appointment && <ModalFinalPayAppointment  visible={isOpenFinalPay} appointmentId={appointment.appointmentId} appointmentPrice={appointment.Price} crewName={`${appointment.CrewsFirstName} ${appointment.CrewsLastName}`} crewProfileImage={appointment.CrewProfilePic} maxTip={appointment.MaxTip}  closeModal={closeModalPayment} />}
          </React.Fragment>

      );
}

export default ModalCheckAppointment;