import React, {useEffect} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import BaseStyles from './base-styles';
import PrivateRoute from './private-route';
import Login from '../views/login';
import Home from '../views/home/index';
import Layout from "../Layout";
import {StateProvider} from "../state";
import reducers from '../state/reducers';
import {useSelector} from "react-redux";
import PublicRoute from "./public-route";
import SignUp from "../views/SignUp";
import ForgotPassword from "../views/ForgotPassword";
import EditProfile from "../views/EditProfile";
import Setting from "../views/Settings";
import History from "../views/History";
import Service from "../views/Service";
import CompletedProfile from "../views/login/components/CompletedProfile";
import Profile from "../views/Profile";
import ChangePassword from "../views/ChangePassword";
import Contact from "../views/Contact";
import Notifications from "../views/Notifications";
import Schedule from "../views/Schedule";
import Earning from "../views/Earning";
import Appointment from "../views/Appointment/client";
import Booking from "../views/Booking/clients";
import Payment from "../views/Payment";
import Connection from "../views/Connection";
import {messageFirebase} from '../init-fcm'
import AlertNotification from "../components/Alert/message";
import DownloadApp from "../views/DownloadApp";
import CrewsPage from "../views/Crews";
import ProjectPage from "../views/Project";
import HomePage from "../views/HomePage";
import AboutUsPage from "../views/AboutUsPage";
import CreateProject from "../views/CreateProject";
import MyRaisedPage from "../views/MyRaised";
import MyProjectPage from "../views/MyProject";
import {USER_TYPE_CREW} from "../constants";
// import {addNotification} from "../state/notifications/action";

const Root = () => {
  const {auth} = useSelector(state => state)
  // const [notification, setNotification] = useState(null);
  // const dispatch = useDispatch()


  useEffect(() => {
    if (!messageFirebase) return
    // receive notification server receiver
    messageFirebase.onMessage(payload => {
      console.log('payload message', payload)
      if (!payload?.data) {
        return;
      }
      if (payload.data.message) {
        // setNotification(payload.data)
        AlertNotification(payload.data.message)
      }
      // AlertNotification('new message but not yet processed!')
    });
  }, [])

  // useEffect(() => {
  //     if(notification){
  //         AlertNotification(notification.message)
  //         // dispatch(addNotification(notification))
  //     }
  // }, [notification])

  const initialState = {
    auth
  };

  return (
    <StateProvider initialState={initialState} reducer={reducers}>
      <BaseStyles/>

      <Router>
        <Switch>
          <PublicRoute restricted={true} component={SignUp} path="/signup" exact/>
          <PublicRoute restricted={true} component={Login} path="/login" exact/>
          <PublicRoute restricted={true} component={ForgotPassword} path="/forgot-password" exact/>
          <PublicRoute component={DownloadApp} path="/app" exact/>
          <Layout>
            <Switch>
              <Route restricted={true} component={CrewsPage} path="/search-crew" exact/>
              <Route restricted={true} component={ProjectPage} path="/search-project" exact/>
              <PublicRoute exact path='/' component={HomePage}/>
              <PublicRoute exact path='/about-us' component={AboutUsPage}/>
              <PrivateRoute path="/home" component={Home}/>
              <PrivateRoute path="/my-raised" component={MyRaisedPage}/>
              <PrivateRoute path="/my-project" component={MyProjectPage}/>
              <PrivateRoute path="/create-project" component={CreateProject}/>
              <PrivateRoute path="/complete-profile" component={CompletedProfile}/>
              <PrivateRoute path="/edit-profile" component={EditProfile}/>
              <PrivateRoute path="/appointment" component={Appointment}/>
              <PrivateRoute path="/history" component={History}/>
              <PrivateRoute path="/profile" component={Profile}/>
              <PrivateRoute path="/setting" component={Setting}/>
              <PrivateRoute path="/service" component={Service}/>
              <PrivateRoute path="/booking" component={Booking}/>
              <PrivateRoute path="/change-password" component={ChangePassword}/>
              <PrivateRoute path="/contact" component={Contact}/>
              <PrivateRoute path="/notifications" component={Notifications}/>
              <PrivateRoute path="/schedule" component={Schedule}/>
              <PrivateRoute path="/earning" component={Earning}/>
              <PrivateRoute path="/payment" component={Payment}/>
              <PrivateRoute path="/connection" component={Connection}/>
              <Redirect to='/'/>
            </Switch>
          </Layout>
          <Redirect to='/'/>
        </Switch>
      </Router>
    </StateProvider>
  );
};

export default Root;
