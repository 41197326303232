import React, {useState} from 'react';
import {Button, Col, Form, Input, Modal, Row} from "antd";
import './index.scss'
import {createAccountStripe, saveBankAccount, updateConnectAccount} from "../../../../state/auth/actions";
import AlertError from "../../../../components/Alert/error";
import ModalResultSuccess from "../../../../components/Result";
import {getAddressFromZipcode} from "../../../../utils/help";

const AddBankAccount = ({visible , user, closeModal}) => {
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false)
      const [isUpdate , setUpdate] = useState(false)
      const [isOpenModalResult, setOpenModalResult] = useState(false)
      const [messageSuccess, setMessageSuccess] = useState(null)

      const onSubmitAddBank = async (values) => {
            try {
                  let zipcode = values.zipcode
                  let obj = getAddressFromZipcode(zipcode)
                  if(!obj){
                        return AlertError('Postal code invalid!')
                  }
                  setLoading(true)
                  let dataSubmit = {
                        email : user.Email,
                        first_name : values.first_name,
                        last_name : values.last_name,
                        ssn_last_4 : values.ssn,
                        city : values.city,
                        country : values.country,
                        postal_code : values.zipcode,
                        line1 : values.address,
                        month : values.month,
                        year : values.year,
                        day : values.day,
                        state : values.state,
                        tos_acceptance : '1'
                  }
                  if(!isUpdate){
                        const resultSaveAccount = await createAccountStripe(dataSubmit)
                        if(resultSaveAccount.createConnectAccount.status){
                              setUpdate(true)
                              let dataSubmitBank = {
                                    routing_number : values.routing_number,
                                    account_holder_name : values.account_name,
                                    account_number : values.account_number,
                                    account_holder_type : 'individual',
                                    country : values.country,
                                    currency : 'usd'
                              }
                              const resultSaveBank = await saveBankAccount(dataSubmitBank)
                              if(resultSaveBank.createBankAccount.status){
                                    setMessageSuccess(resultSaveBank.createBankAccount.Message)
                                    setOpenModalResult(true)
                                    setLoading(false)
                              } else{
                                    setLoading(false)
                                    return AlertError(resultSaveBank.createBankAccount.Message)
                              }
                        } else{
                              setLoading(false)
                              return AlertError(resultSaveAccount.createConnectAccount.Message)
                        }
                  } else{
                        const resultSaveAccount = await updateConnectAccount(dataSubmit)
                        if(resultSaveAccount.updateConnectAccount.status){
                              let dataSubmitBank = {
                                    routing_number : values.routing_number,
                                    account_holder_name : values.account_name,
                                    account_number : values.account_number,
                                    account_holder_type : 'individual',
                                    country : values.country,
                                    currency : 'usd'
                              }
                              const resultSaveBank = await saveBankAccount(dataSubmitBank)
                              if(resultSaveBank.createBankAccount.status){
                                    setMessageSuccess(resultSaveBank.createBankAccount.Message)
                                    setOpenModalResult(true)
                                    setLoading(false)
                              } else{
                                    setLoading(false)
                                    return AlertError(resultSaveBank.createBankAccount.Message)
                              }
                        } else{
                              setLoading(false)
                              return AlertError(resultSaveAccount.updateConnectAccount.Message)
                        }
                  }

            } catch (e) {
                  setLoading(false)
            }
      }

      const closeModalSuccess = () => {
            setOpenModalResult(false)
            closeModal(true)
      }

      const onChangeZipcode = (e) => {
            let zipcode = e.target.value
            let obj = getAddressFromZipcode(zipcode)
            if(obj){
                  form.setFieldsValue({
                        city : obj.city,
                        state : obj.state,
                        country : obj.country
                  });
            }
      }

      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    width={800}
                    className='modal-add-bank-account'
                >
                      <div className="head-modal">
                            <h5>Add bank detail</h5>
                            <Button onClick={closeModal}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                      routing_number : '',
                                      country : 'US'
                                }}
                                onFinish={onSubmitAddBank}
                                name='form-add-bank-account'
                            >
                                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col lg={12} md={12} sm={24}>
                                              <Form.Item
                                                  label="First name"
                                                  name="first_name"
                                                  rules={[{ required: true, message: 'Please input your first name!' }]}
                                              >
                                                    <Input placeholder='first name' />
                                              </Form.Item>
                                        </Col>
                                        <Col lg={12} md={12} sm={24}>
                                              <Form.Item
                                                  label="Last name"
                                                  name="last_name"
                                                  rules={[{ required: true, message: 'Please input your last name!' }]}
                                              >
                                                    <Input placeholder='last name' />
                                              </Form.Item>
                                        </Col>
                                  </Row>
                                  <Form.Item
                                      label="Account name"
                                      name="account_name"
                                      rules={[{ required: true, message: 'Please input your account name!' }]}
                                  >
                                        <Input placeholder='account holder name' />
                                  </Form.Item>
                                  <Form.Item
                                      label="Account number"
                                      name="account_number"
                                      rules={[{ required: true, message: 'Please input your account number!' }]}

                                  >
                                        <Input type='number' placeholder='account number' />
                                  </Form.Item>
                                  <Form.Item
                                      label="Routing number"
                                      name="routing_number"
                                      rules={[{ required: true, message: 'Please input your routing number!' }]}
                                  >
                                        <Input type='number'  placeholder='routing number'/>
                                  </Form.Item>
                                  <Form.Item
                                      label="Ssn"
                                      name="ssn"
                                      rules={[
                                                { required: true, message: 'Please input your ssn!' },
                                                { max: 4, message: 'Ssn maximum length 4 characters!' },
                                          ]}
                                  >
                                        <Input type='number' placeholder='ssn' />
                                  </Form.Item>
                                  <Form.Item
                                      label="Zipcode"
                                      name="zipcode"
                                      rules={[{ required: true, message: 'Please input your zipcode!' }]}
                                  >
                                        <Input type='number' placeholder='zipcode' onChange={onChangeZipcode}/>
                                  </Form.Item>
                                  <Form.Item
                                      label="Address"
                                      name="address"
                                      rules={[{ required: true, message: 'Please input your address!' }]}
                                  >
                                        <Input placeholder='address' />
                                  </Form.Item>
                                  <Form.Item
                                      label="City"
                                      name="city"
                                      rules={[{ required: true, message: 'Please input your city!' }]}
                                  >
                                        <Input placeholder='city' />
                                  </Form.Item>
                                  <Form.Item
                                      label="State"
                                      name="state"
                                      rules={[{ required: true, message: 'Please input your state!' }]}
                                  >
                                        <Input placeholder='state' />
                                  </Form.Item>
                                  <Form.Item
                                      label="Country"
                                      name="country"
                                      rules={[{ required: true, message: 'Please input your country!' }]}
                                  >
                                        <Input placeholder='country' />
                                  </Form.Item>
                                  <p className="title-input-birthday">Birthday</p>
                                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col lg={8} md={8} sm={8}>
                                              <Form.Item
                                                  label="MM"
                                                  name="month"
                                                  rules={[
                                                      { required: true, message: 'Please input your month!' },
                                                      { min: 2, message: 'Month minimum length 2 characters!' },
                                                      { max: 2, message: 'Month maximum length 2 characters!' },
                                                  ]}
                                              >
                                                    <Input type='number' placeholder='Eg. 02'/>
                                              </Form.Item>
                                        </Col>
                                        <Col lg={8} md={8} sm={24}>
                                              <Form.Item
                                                  label="DD"
                                                  name="day"
                                                  rules={[
                                                        { required: true, message: 'Please input your day!' },
                                                        { min: 2, message: 'Day minimum length 2 characters!' },
                                                        { max: 2, message: 'Day maximum length 2 characters!' },
                                                  ]}
                                              >
                                                    <Input type='number' placeholder='Eg. 03' />
                                              </Form.Item>
                                        </Col>
                                        <Col lg={8} md={8} sm={24}>
                                              <Form.Item
                                                  label="YYYY"
                                                  name="year"
                                                  rules={[
                                                        { required: true, message: 'Please input your year!' },
                                                        { min: 4, message: 'Year minimum length 4 characters!' },
                                                        { max: 4, message: 'Year maximum length 4 characters!' },
                                                  ]}
                                              >
                                                    <Input type='number' placeholder='Eg. 1991' />
                                              </Form.Item>
                                        </Col>
                                  </Row>
                                  <Form.Item className='w-100'>
                                        <Button loading={loading} htmlType='submit'  className='w-100 btn-submit-add-bank'>Submit</Button>
                                  </Form.Item>
                                  <div className='btn-skip'>
                                        <Button onClick={closeModal}>Skip - add later</Button>
                                  </div>
                            </Form>
                      </div>
                </Modal>
                {isOpenModalResult && <ModalResultSuccess isSuccess={isOpenModalResult} messageSuccess={messageSuccess} closeModalSuccess={closeModalSuccess} />}
          </div>
      );
}

export default AddBankAccount;