import React from 'react';
import Header from "../components/header";
import Content from "../components/content";

const Layout = (props) => {
    return (
        <>
            <Header/>
            <Content>
                {props.children}
            </Content>
        </>
    );
};

export default Layout;
