import React, {useState, useRef, useEffect} from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import './index.scss'
import {getNearByCrews} from "../../../../state/appointment/action";


const Marker = ({ children }) => children;

export default function MapBooking({lat , lng , address, onSelectedUser}) {
      const mapRef = useRef();
      const [bounds, setBounds] = useState(null);
      const [zoom, setZoom] = useState(10);
      const [center, setCenter] = useState({lat: 52.6376, lng: -1.135171 , address : ''});
      const [listCrews, setListCrews] = useState([])
      const [locationCurrent, setLocationCurrent] = useState(
            {lat: 52.6376, lng: -1.135171 , address : ''}
      )

      useEffect(() => {
            if(lat && lng && parseFloat(lat) !== 0 && parseFloat(lng) !== 0 && address !== '' && lat !== locationCurrent.lat && lng !== locationCurrent.lng){
                  setCenter({
                        lat : lat,
                        lng : lng,
                        address : address
                  })
                  setLocationCurrent({
                        lat : lat,
                        lng : lng,
                        address : address
                  })
                  const getListCrewNearBy = async () => {
                        try {
                              const result = await getNearByCrews(lat, lng, 0)
                              let listCrews = result.data.getNearByCrews.data
                              listCrews = listCrews.filter((set => f => !set.has(f.UserId) && set.add(f.UserId))(new Set()));
                              setListCrews(listCrews)
                        } catch (e) {
                              console.log(e);
                        }
                  }
                  getListCrewNearBy()
            }

      }, [lat, lng, address, locationCurrent])

      const points = listCrews.map(crew => ({
            properties: { cluster: false, crewId: crew.UserId, crewName : `${crew.FirstName} ${crew.LastName}` },
            geometry: {
                  type: "Point",
                  coordinates: [
                        parseFloat(crew.Longitude),
                        parseFloat(crew.Latitude)
                  ]
            }
      }));

      const { clusters, supercluster } = useSupercluster({
            points,
            bounds,
            zoom,
            options: { radius: 75, maxZoom: 20 }
      });

      const selectedUser = (userId) => {
            onSelectedUser(userId)
      }

      return (
            <div style={{ height: "100vh", width: "100%" }}>
                  <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                        center={center}
                        defaultZoom={5}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map }) => {
                              mapRef.current = map;
                        }}
                        onChange={({ zoom, bounds }) => {
                              setZoom(zoom);
                              setBounds([
                                    bounds.nw.lng,
                                    bounds.se.lat,
                                    bounds.se.lng,
                                    bounds.nw.lat
                              ]);
                        }}
                  >
                        {clusters.map(cluster => {
                              const [longitude, latitude] = cluster.geometry.coordinates;
                              const {
                                    cluster: isCluster,
                                    point_count: pointCount
                              } = cluster.properties;

                              if (isCluster) {
                                    return (
                                          <Marker
                                                key={`cluster-${cluster.id}`}
                                                lat={latitude}
                                                lng={longitude}
                                          >
                                                <div
                                                      className="cluster-marker"
                                                      style={{
                                                            width: `${15 + (pointCount / points.length) * 20}px`,
                                                            height: `${15 + (pointCount / points.length) * 20}px`
                                                      }}
                                                      onClick={() => {
                                                            const expansionZoom = Math.min(
                                                                  supercluster.getClusterExpansionZoom(cluster.id),
                                                                  20
                                                            );
                                                            mapRef.current.setZoom(expansionZoom);
                                                            mapRef.current.panTo({ lat: latitude, lng: longitude });
                                                      }}
                                                >
                                                      {pointCount}
                                                </div>
                                          </Marker>
                                    );
                              }

                              return (
                                    <Marker
                                          key={`crime-${cluster.properties.crewId}`}
                                          lat={latitude}
                                          lng={longitude}
                                    >
                                          <div
                                                className="pin bounce"
                                                style={{ backgroundColor: 'red', cursor: 'pointer' }}
                                                title={cluster.properties.crewName}
                                                onClick={() => selectedUser(cluster.properties.crewId)}
                                          />
                                          <div className="pulse" />
                                    </Marker>
                              );
                        })}
                  </GoogleMapReact>
            </div>
      );
}
