import axiosInstance from './../axiosInstance'

export const getListServices = async () => {
    let data = {"method": "getConfig"};
    const services = await axiosInstance.post('/getConfig', data);
    return services.data;
}

export const getListBudgets = async () => {
    const accessToken = localStorage.getItem('access_token')
    let data = {
        "method": "getBudgetList",
        "body": {
            "accessToken" : accessToken
        },
    };
    return await axiosInstance.post('/getBudgetList', data);
}

// export const getListAllCrews =  () => {
//     return new Promise((resolve, reject) => {
//         const accessToken = localStorage.getItem('access_token')
//         let data = {
//             "body": {
//                 "Latitude": "33.1266674",
//                 "pageNo": "0",
//                 "serviceId": 1,
//                 "accessToken": 'WJrn536ViUMHFAocMm47URU927zBOajEwwLWnYlSEkNdoEmdircAXDxVoPILyz00Rmp7RI8ifVskpq2dqQQLcrKRzif51EQNr2hMCBzXalG6I1ej2f4ld1geaX6FTbN754AJKonf8C9TiqnQSGSEwut3HWOSD9evhAwvj40a8pZksclgx5PkhCJeeK6KmPFZYyB3XOCvL9posZtYbCIvuxbg6U1BmGYuZOk3hPZTzspWNqVMKdGAsDWQRuh9fx0',
//                 "levelType": 1,
//                 "Longitude": "-96.659149"
//             },
//             "method": "getCrewsListByService"
//         };
//         axiosInstance.post('/getCrewsListByService', data).then(
//               (res) => {
//                   console.log('res', res);
//                   return resolve(res.data.getCrewsListByService)
//               },
//               (err) => {
//                   return reject(err)
//               }
//         )
//     })
//
// }