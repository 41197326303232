import authReducer from './auth/reducers';
import serviceReducer from './service/reducers';
import notificationReducer from './notifications/reducer';
import {combineReducers} from "redux";
import paymentsReducer from "./payment/reducers";
import connectionReducer from "./connection/reducer";
// import {LOGOUT} from "./auth/actions";

// const rootReducer = combineReducers({
//     auth: authReducer,
//     services: serviceReducer,
//     payments : paymentsReducer,
//     connections : connectionReducer
// });

const appReducer = combineReducers({
    auth: authReducer,
    services: serviceReducer,
    payments : paymentsReducer,
    connections : connectionReducer,
    notifications : notificationReducer,
})

const rootReducer = (state, action) => {
    if (action.type === 'auth/CLEAR_USER') {
        // return appReducer(undefined, action)
        localStorage.removeItem('persist:root')
        return appReducer(undefined, action);
    }
    return appReducer(state, action)
}

export default rootReducer;