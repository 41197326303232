import React, {useState} from 'react';
import {Button, Modal, TimePicker} from "antd";
import './index.scss'
import {addTimeAppointment} from "../../../../state/appointment/action";
import AlertError from "../../../../components/Alert/error";
import AlertSuccess from "../../../../components/Alert/success";

const ModalAddTimeAppointment = ({visible, appointmentId ,  closeModal}) =>  {
      const [time, setTime] = useState(null)
      const [loading, setLoading] = useState(false
      )
      const saveAddTime = async () => {
            setLoading(true)
            const result = await addTimeAppointment(appointmentId, time)
            if(result.data.addextratime.status){
                  setLoading(false)
                  AlertSuccess(result.data.addextratime.Message)
                  closeModal()
            }else{
                  setLoading(false)
                  AlertError(result.data.addextratime.Message)
            }
      }
      return (
          <Modal
              footer={null}
              visible={visible}
              maskClosable={false}
              closable={false}
              centered
              className='modal-add-time-appointment'
              width={500}
          >
                <div className="head-modal">
                      <h5>Add time appointment</h5>
                      <Button onClick={closeModal}>Close</Button>
                </div>
                <div className="body-modal">
                      <TimePicker  format='HH:mm' className='w-100' onChange={(time, timeString) => {
                            console.log('time', time)
                            console.log('timestring', timeString)
                            setTime(timeString)
                      }} />
                      <div className="button-add-time">
                            <Button loading={loading} className='w-100' onClick={saveAddTime}>Save</Button>
                      </div>
                </div>
          </Modal>
      );
}

export default ModalAddTimeAppointment;