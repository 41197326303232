import React from 'react';
import {Button, Modal} from "antd";
import {CloseOutlined} from "@ant-design/icons/lib/icons";
import './index.scss'

const ModalResultError = ({isError, messageSuccess , closeModalError}) => {
      return (
          <div>
                <Modal
                    footer={null}
                    visible={isError}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-result-success'
                >
                      <div className="body-modal">
                            <CloseOutlined />
                            <p>{messageSuccess}</p>
                            <Button onClick={closeModalError}>Close</Button>
                      </div>
                </Modal>
          </div>
      );
}

export default ModalResultError;