import React, {useEffect, useState} from 'react';
import './index.scss'
import {Avatar, Button, Image, Popconfirm, Rate, Spin} from "antd";
import {InfoCircleOutlined, DoubleLeftOutlined, MessageFilled} from "@ant-design/icons";
import {appointmentNotify, detailAppointment} from "../../../../state/appointment/action";
import {
    ACCEPT_APPOINTMENT_FOR_CREW,
    ACTION_ARRIVE_APPOINTMENT,
    ACTION_START_APPOINTMENT,
    APPOINTMENT_COMPLETE,
    APPOINTMENT_PENDING,
    APPOINTMENT_SCHEDULE,
    ATTACHMENT_TYPE_SEND_MAIL,
    ATTACHMENT_TYPE_UPLOAD_FILE,
    ATTACHMENT_TYPE_USB,
    CANCEL_APPOINTMENT_FOR_CLIENT,
    CREW_STATUS_ARRIVE_APPOINTMENT,
    CREW_STATUS_COMPLETE_APPOINTMENT,
    CREW_STATUS_START_APPOINTMENT,
    DECLINE_APPOINTMENT_FOR_CREW,
    NO_IMAGE,
    PAYMENT_PAID,
    PAYMENT_UNPAID,
    USER_TYPE_CLIENT,
    USER_TYPE_CREW
} from "../../../../constants";
// import Countdown from "react-countdown";
import ModalAddTimeAppointment from "../AddTime";
import ModalFinalPayAppointment from "../../../History/components/Pay";
import {useSelector} from "react-redux";
import AlertSuccess from "../../../../components/Alert/success";
import AlertError from "../../../../components/Alert/error";
import ShowPdf from "../../../../components/Document";
import ChatWidget from "../../../../components/Chat";
import moment from 'moment'

const ScheduleDetail = ({appointmentSelected, closeDetail, onAcceptedAppointment, onDeclineAppointment, onCompleteAppointment, onCancelAppointment, loading})  => {
    const [spinState, setSpinState] = useState(true)
    const [appointment, setAppointment] = useState(null)
    const [visible, setVisible] = useState(false)
    const [isOpenFinalPay, setOpenFinalPay] = useState(false)
    const {user} = useSelector(state => state.auth)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [isShowPdf, setShowPdf] = useState(false)
    const [filePdf, setFilePdf] = useState(null)
    const [isShowChat, setShowChat] = useState(false)
    const [disableBtn, setDisableBtn] = useState(true)
    const notifyLocal = localStorage.getItem('notificationData')

    useEffect(() => {
        let appointmentId = appointmentSelected
        if(notifyLocal){
            let notificationData = JSON.parse(localStorage.getItem('notificationData'))
            appointmentId = notificationData.appointmentId
            localStorage.removeItem('notificationData')
        }

        const onDetailAppointment =  async () => {
            try {
                setSpinState(true)
                let result = await detailAppointment(appointmentId)
                setAppointment(result.data.appointmentDetails.data)
                setSpinState(false)
            } catch (e) {
                console.log(e)
            }
        }
        onDetailAppointment()
    }, [notifyLocal, appointmentSelected])

    useEffect(() => {
        const disableChatCall = () => {
            if(appointment){
                let dateCurrent = new Date();
                dateCurrent = dateCurrent.setHours(0,0,0,0);
                let timeCurrent = new Date().toLocaleString([], {hour: '2-digit', minute: '2-digit' , second :'2-digit' });
                let timeAppointment = moment(appointment.AppointmentStartTime).add(0.5, 'hours').format('HH:mm:ss ');
                let dateAppointment = new Date(appointment.AppointmentStartTime).setHours(0,0,0,0);
                if((dateCurrent > dateAppointment || (dateCurrent === dateAppointment &&  timeCurrent > timeAppointment)) && appointment.AppointmentStatus === APPOINTMENT_SCHEDULE){
                    setDisableBtn(false)
                }
            }
        }
        disableChatCall()
    }, [appointment])

    const onCloseDetail = () => {
        closeDetail(appointment.appointmentId, appointment.paymentStatus)
    }

    const closeModal = (appointmentId , isPay, tips) => {
        if(typeof appointmentId === 'number' && isPay === PAYMENT_PAID){
            let newAppointmentData = appointment
            newAppointmentData.paymentStatus = isPay
            newAppointmentData.tipPrice = tips
            setAppointment(newAppointmentData)
        }
        setVisible(false)
        setOpenFinalPay(false)
    }

    const confirmAcceptAppointment = () => {
        onAcceptedAppointment(appointment.appointmentId, ACCEPT_APPOINTMENT_FOR_CREW)
    }

    const confirmDeclineAppointment = () => {
        onDeclineAppointment(appointment.appointmentId, DECLINE_APPOINTMENT_FOR_CREW)
    }

    const arriveAppointment = async () => {
        setLoadingSubmit(true)
        const result = await appointmentNotify(appointment.appointmentId, ACTION_ARRIVE_APPOINTMENT)
        if(result.appointmentNotify.status){
            let newDataAppointment = appointment
            newDataAppointment.crewStatus = CREW_STATUS_START_APPOINTMENT
            setAppointment(newDataAppointment)
            AlertSuccess(result.appointmentNotify.Message)
            setLoadingSubmit(false)
        } else{
            AlertError(result.appointmentNotify.Message)
            setLoadingSubmit(false)
        }
    }

    const startAppointment = async () => {
        setLoadingSubmit(true)
        const result = await appointmentNotify(appointment.appointmentId, ACTION_START_APPOINTMENT)
        if(result.appointmentNotify.status){
            let newDataAppointment = appointment
            newDataAppointment.crewStatus = CREW_STATUS_COMPLETE_APPOINTMENT
            setAppointment(newDataAppointment)
            AlertSuccess(result.appointmentNotify.Message)
            setLoadingSubmit(false)
        } else{
            AlertError(result.appointmentNotify.Message)
            setLoadingSubmit(false)
        }
    }

    const closeModalShowPdf = () => {
        setShowPdf(false)
    }

    const closeChat = () => {
        setShowChat(false)
    }

    return (
        <React.Fragment>
            {!isShowChat ?
                <div className='schedule schedule-page-detail page-children'>
                    {appointment &&
                    <div className="back">
                        <Button className='btn-back' onClick={onCloseDetail}><DoubleLeftOutlined />Back</Button>
                    </div>
                    }
                    {!spinState ?
                        <React.Fragment>
                            <div className="widget-info">
                                <h6 className="title"><InfoCircleOutlined className='icon-info' />Crew Information</h6>
                                <div className="info-crew">
                                    <div className="info-top">
                                        <div className="thumb">
                                            <Avatar size={80} src={appointment.CrewProfilePic ? appointment.CrewProfilePic : NO_IMAGE} />
                                        </div>
                                        <div className="info">
                                            {/*<p className="name">{appointment.CrewsFirstName} {appointment.CrewsLastName} {(appointment.AppointmentStatus === APPOINTMENT_PENDING || appointment.AppointmentStatus === APPOINTMENT_SCHEDULE) && <div className='call-message'><Button disabled={disableBtn}  onClick={() => setShowChat(true) }><MessageFilled  /></Button> <Button disabled={disableBtn}><PhoneFilled rotate={100}/></Button></div>  }</p>*/}
                                            <p className="name">{appointment.CrewsFirstName} {appointment.CrewsLastName} {(appointment.AppointmentStatus === APPOINTMENT_PENDING || appointment.AppointmentStatus === APPOINTMENT_SCHEDULE) && <Button disabled={disableBtn}  onClick={() => setShowChat(true) }><MessageFilled  /></Button> }</p>
                                            <div className='rate'>
                                                <Rate allowHalf disabled defaultValue={parseFloat(appointment.ratings)} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="action">
                                        <p className='amount'>${appointment.Price}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="work-detail">
                                <h6 className="title"><InfoCircleOutlined className='icon-info' />Appointment Detail</h6>
                                <div className='info-work'>
                                    <div className="item">
                                        <div className="title">
                                            <p>Appointment ID</p>
                                        </div>
                                        <div className="detail">
                                            <p>#{appointment.appointmentId}</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="title">
                                            <p>Request Service</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.appointmentName}</p>
                                        </div>
                                    </div>
                                    {appointment.ViewingOutlet &&
                                        <div className="item">
                                            <div className="title">
                                                <p>Viewing outlet</p>
                                            </div>
                                            <div className="detail">
                                                <p>{appointment.ViewingOutlet}</p>
                                            </div>
                                        </div>
                                    }
                                    {appointment.FileTypeEdit &&
                                    <div className="item">
                                        <div className="title">
                                            <p>File type that need to be edited</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.FileTypeEdit}</p>
                                        </div>
                                    </div>
                                    }
                                    {appointment.LengthOfSource &&
                                    <div className="item">
                                        <div className="title">
                                            <p>Length of source material</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.LengthOfSource}</p>
                                        </div>
                                    </div>
                                    }
                                    {appointment.FinalLengthDesired &&
                                    <div className="item">
                                        <div className="title">
                                            <p>Final file length desired</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.FinalLengthDesired}</p>
                                        </div>
                                    </div>
                                    }
                                    {appointment.OutputType &&
                                    <div className="item">
                                        <div className="title">
                                            <p>Output file type</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.OutputType}</p>
                                        </div>
                                    </div>
                                    }
                                    {appointment.MasterFilesNeeded &&
                                    <div className="item">
                                        <div className="title">
                                            <p>Master file needed</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.MasterFilesNeeded}</p>
                                        </div>
                                    </div>
                                    }
                                    <div className="item">
                                        <div className="title">
                                            <p>Location</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.Location}</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="title">
                                            <p>Start time</p>
                                        </div>
                                        <div className="detail">
                                            {/*<p>{moment(appointment.Date).format('MM-DD-YYYY')} - {appointment.StartTime}</p>*/}
                                            <p>{appointment.AppointmentTime}</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="title">
                                            <p>Duration</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.Hours ? `${parseFloat(appointment.Hours).toFixed(1)} hour(s)` : `${parseFloat(appointment.Days).toFixed(1)} day(s)`}</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="title">
                                            <p>Offer price</p>
                                        </div>
                                        <div className="detail">
                                            <p>${appointment.OfferPrice}{appointment.Hours ? `/hour` : `/day`}</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="title">
                                            <p>Total</p>
                                        </div>
                                        <div className="detail">
                                            <p>${appointment.Price}</p>
                                        </div>
                                    </div>
                                    {appointment.tipPrice &&
                                    <div className="item">
                                        <div className="title">
                                            <p>Tips</p>
                                        </div>
                                        <div className="detail">
                                            <p>${(parseFloat(appointment.tipPrice)).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    }
                                    <div className="item">
                                        <div className="title">
                                            <p>Note</p>
                                        </div>
                                        <div className="detail">
                                            <p>{appointment.appointmentDetail ? appointment.appointmentDetail : 'No info'}</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="title">
                                            <p>Attachment of booking</p>
                                        </div>
                                        <div className="detail detail-attachments">
                                            <div>
                                                <div className="title-attachment">
                                                    <p>List pictures <span>{appointment.ClientAttchment.image.length === 0 ?  `(No image attachment)` : `( ${appointment.ClientAttchment.image.length} )`}</span></p>
                                                </div>
                                                <div className="body-attachment">
                                                    <div className='list'>
                                                        {appointment.ClientAttchment.image.length > 0 &&
                                                        appointment.ClientAttchment.image.map((item, index) =>
                                                            <div className='item' key={index} >
                                                                <Image src={item} />
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="title-attachment">
                                                    <p>Documents <span>{appointment.ClientAttchment.document.length === 0 ?  `(No document attachment)` : `( ${appointment.ClientAttchment.document.length} )`}</span></p>
                                                </div>
                                                <div className="body-attachment">
                                                    <div className='list list-document-attachment'>
                                                        {appointment.ClientAttchment.document.length > 0 &&
                                                        appointment.ClientAttchment.document.map((item, index) =>
                                                            <div className='item' key={index} >
                                                                <p onClick={() => {
                                                                    setFilePdf(item)
                                                                    setShowPdf(true)
                                                                }}>File attachment {index + 1}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {appointment.AppointmentStatus === APPOINTMENT_COMPLETE &&
                                        <div className="item">
                                        <div className="title">
                                            <p>Document of work result</p>
                                        </div>
                                        {(appointment.paymentStatus === PAYMENT_PAID && user && user.UserType !== USER_TYPE_CREW) || user.UserType === USER_TYPE_CREW ?
                                            <React.Fragment>
                                                {appointment.AttachmentType === ATTACHMENT_TYPE_USB &&
                                                <div className="detail">
                                                    <p className='attachment-name'>Give a USB hard drive</p>
                                                </div>
                                                }
                                                {appointment.AttachmentType === ATTACHMENT_TYPE_SEND_MAIL &&
                                                <div className="detail">
                                                    <p className='attachment-name'>Email to: {appointment.CrewsAttchment.email[0]}</p>
                                                </div>
                                                }
                                                {appointment.AttachmentType === ATTACHMENT_TYPE_UPLOAD_FILE &&
                                                <div className="detail detail-attachments">
                                                    <div className="attachment-complete">
                                                        <div className='title-at'>
                                                            <p>Links <span>{appointment.CrewsAttchment.Links.length === 0 && `(No links)`}</span></p>
                                                        </div>
                                                        {appointment.CrewsAttchment.Links.length > 0  &&
                                                        <div className='value-at'>
                                                            <p>{appointment.CrewsAttchment.Links[0] }</p>
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className="attachment-complete">
                                                        <div className='title-at'>
                                                            <p>File <span>{appointment.CrewsAttchment.document.length === 0 && `(No files)`}</span></p>
                                                        </div>
                                                        {appointment.CrewsAttchment.document.length > 0 &&
                                                        <div className='value-at'>
                                                            <p onClick={() => {
                                                                setFilePdf(appointment.CrewsAttchment.document[0])
                                                                setShowPdf(true)
                                                            }}>File attachment</p>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                                }
                                            </React.Fragment>
                                            :
                                            <div className="detail">
                                                <p className='attachment-name'>Please pay to receive the attachment result</p>
                                            </div>
                                        }
                                    </div>
                                    }
                                </div>
                            </div>
                            {/*{appointment.AppointmentStatus === APPOINTMENT_PENDING &&*/}
                            {/*    <div className="time-schedule">*/}
                            {/*        <p>Timer start automatically once scheduled time comes</p>*/}
                            {/*        <h4><Countdown date={new Date(appointment.AppointmentStartTime)} /></h4>*/}
                            {/*        /!*<Button onClick={() => setVisible(true)}>Add Time</Button>*!/*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {(appointment.AppointmentStatus === APPOINTMENT_PENDING  || appointment.AppointmentStatus === APPOINTMENT_PENDING) && user?.UserType === USER_TYPE_CLIENT &&
                                <div className='action-appointment'>
                                    <Button className='btn-decline-appointment' disabled={loading} onClick={() => onCancelAppointment(appointment.appointmentId, CANCEL_APPOINTMENT_FOR_CLIENT)}>Cancel</Button>
                                </div>
                            }
                            {appointment.AppointmentStatus === APPOINTMENT_COMPLETE && appointment.paymentStatus === PAYMENT_UNPAID  && user && user.UserType !== USER_TYPE_CREW &&
                                <div className='pay-appointment'>
                                    <Button onClick={() => setOpenFinalPay(true)} className='btn-pay-appointment w-50'>Pay</Button>
                                </div>
                            }
                            {appointment.AppointmentStatus === APPOINTMENT_PENDING  && user && user.UserType === USER_TYPE_CREW &&
                                <div className='action-appointment'>
                                <Popconfirm
                                    title="Are you sure to delete this task?"
                                    onConfirm={confirmAcceptAppointment}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button loading={loading} className='btn-accept-appointment'>Accepted</Button>
                                </Popconfirm>
                                <Button className='btn-decline-appointment' disabled={loading} onClick={confirmDeclineAppointment}>Decline</Button>
                            </div>
                            }
                            {appointment.AppointmentStatus === APPOINTMENT_SCHEDULE  && appointment.crewStatus === CREW_STATUS_ARRIVE_APPOINTMENT  && user && user.UserType === USER_TYPE_CREW &&
                                <div className='action-appointment'>
                                    <Button className='btn-accept-appointment w-50' loading={loadingSubmit} onClick={arriveAppointment}>Arrive</Button>
                                </div>
                            }
                            {appointment.AppointmentStatus === APPOINTMENT_SCHEDULE && appointment.crewStatus === CREW_STATUS_START_APPOINTMENT  && user && user.UserType === USER_TYPE_CREW &&
                                <div className='action-appointment'>
                                    <Button className='btn-accept-appointment w-50' loading={loadingSubmit} onClick={startAppointment}>Start</Button>
                                </div>
                            }
                            {appointment.AppointmentStatus === APPOINTMENT_SCHEDULE && appointment.crewStatus === CREW_STATUS_COMPLETE_APPOINTMENT  && user && user.UserType === USER_TYPE_CREW &&
                                <div className='action-appointment'>
                                    <Button className='btn-accept-appointment w-50' onClick={() => onCompleteAppointment(appointment.appointmentId, appointment.ClientEmail)}>Complete</Button>
                                </div>
                            }
                        </React.Fragment>
                        :
                        <div className='wp-loading-data'>
                            <Spin tip="Loading..." spinning={spinState} className='spin-loading-data'>
                            </Spin>
                        </div>
                    }
                    {visible && appointment && <ModalAddTimeAppointment visible={visible} appointmentId={appointment.appointmentId} closeModal={closeModal} />}
                    {isOpenFinalPay && appointment && <ModalFinalPayAppointment  visible={isOpenFinalPay} appointmentId={appointment.appointmentId} appointmentPrice={appointment.Price} crewName={appointment.CrewsName} crewProfileImage={appointment.CrewProfilePic} maxTip={appointment.MaxTip} closeModal={closeModal} />}
                    {isShowPdf &&  <ShowPdf  visible={isShowPdf} file={filePdf} closeModal={closeModalShowPdf} />}
                </div>
            :
                <React.Fragment>
                    {user.UserType === USER_TYPE_CREW ?
                        <ChatWidget chatWith={{userId: appointment.ClientID, name: appointment.ClientName, avatar: appointment.ClientProfilePic}} userChatId={appointment.CrewsId} appointmentId={appointment.appointmentId} closeChat={closeChat} />
                        :
                        <ChatWidget chatWith={{userId: appointment.CrewsId, name: `${appointment.CrewsFirstName} ${appointment.CrewsLastName}`, avatar: appointment.CrewProfilePic}} userChatId={appointment.ClientID} appointmentId={appointment.appointmentId} closeChat={closeChat} />
                    }
                </React.Fragment>

            }

        </React.Fragment>
    );
}

export default ScheduleDetail;