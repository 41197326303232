import React, {useEffect, useRef, useState} from 'react';
import './index.scss'
import {Avatar, Button, Input, Popover, Form} from "antd";
import {DoubleLeftOutlined} from "@ant-design/icons";
import {NO_IMAGE} from "../../constants";
import {db} from "./../../init-fcm";
import moment from 'moment'
import {sendChatMessage} from "../../state/auth/actions";

const  ChatWidget = ({chatWith, userChatId, appointmentId , closeChat}) => {
      const [listMessage, setListMessage] = useState([])
      const [form] = Form.useForm();
      const [message, setMessage] = useState('')
      const messageEl = useRef(null);

      const onCloseDetail = () => {
            closeChat()
      }

      useEffect(() => {
            if (messageEl) {
                  messageEl.current.addEventListener('DOMNodeInserted', event => {
                        const { currentTarget: target } = event;
                        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
                  });
            }
      }, [])

      useEffect(() => {
            const getDataChat = async  () => {
                  db.ref(`chats/${appointmentId}`).on("value", snapshot => {
                        let list = typeof(snapshot.val()) != 'undefined' && snapshot.val() != null && snapshot.val().messages ? Object.values(snapshot.val().messages) : [];
                        setListMessage(list)
                  });
            }
            getDataChat()
      }, [appointmentId])

      const renderDataChat = () => {
            return listMessage.map((item , index) =>
                <div key={index} className={`bubble ${chatWith.userId === item.sender ? 'you' : 'me'}`} >
                      <Popover placement={`${chatWith.userId === item.sender ? 'right' : 'left'}`} content={moment(item.sentAt).format('LLL')}>
                            <p>{item.message}</p>
                      </Popover>
                </div>
            )
      }

      const handleInputChange = (e) => {
            setMessage(e.target.value)
      }

      const submitChat = async (e) => {
            e.preventDefault();
            form.resetFields();
            if(message){
                  await sendChatMessage(appointmentId, e.target.value)
                  setMessage('')
            }
      }

      return (
          <div className="widget-chat page-children">
                <div className="back">
                      <Button className='btn-back' onClick={onCloseDetail}><DoubleLeftOutlined />Back</Button>
                </div>
                <div className="chat-system">
                      <div className="hamburger"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu mail-menu d-lg-none"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg></div>
                      <div className="chat-box" style={{height: 'calc(100vh - 232px)'}}>
                            <div className="chat-box-inner" style={{height : '100%'}}>
                                  <div className="chat-meta-user chat-active">
                                        <div className="current-chat-user-name">
                                        <span>
                                              <Avatar shape="square" size={48}  src={chatWith.avatar ? chatWith.avatar : NO_IMAGE} alt="dynamic-image"/>
                                        </span>
                                        </div>
                                        <div className="current-chat-name">
                                              <span className="name">{chatWith.name}</span>
                                        </div>
                                  </div>
                                  <div className="chat-conversation-box ps" ref={messageEl}>
                                        <div id="chat-conversation-box-scroll" className="chat-conversation-box-scroll">
                                              <div className="chat active-chat">
                                                    {renderDataChat()}
                                              </div>
                                        </div>
                                  </div>
                                  <div className="chat-footer chat-active">
                                        <div className="chat-input">
                                              <Form name="control-hooks" form={form} className="chat-form" >
                                                    <Form.Item className='icon-form-chat'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" /></svg>
                                                    </Form.Item>
                                                    <Form.Item name="message">

                                                          <Input className="mail-write-box form-control" placeholder="Message"
                                                                 onPressEnter={submitChat}
                                                                 onChange={handleInputChange}
                                                          />
                                                    </Form.Item>
                                              </Form>
                                        </div>
                                  </div>
                            </div>
                      </div>
                </div>
          </div>
      );
}

export default ChatWidget;