import React from 'react';
import './index.scss'
import {PlusOutlined} from "@ant-design/icons/lib/icons";
import {Badge, Button, Calendar, Timeline} from "antd";
const moment = require('moment');

const Schedule = (props) => {
    const onDateChange = (date) =>  {
        console.log(date.format('YYYY-MM-DD'));
    }
    const getListData = (value) => {
        let listData;
        switch (value.date()) {
            case 8:
                listData = [
                    { content: 'This is usual event.' },
                ];
                break;
            case 10:
                listData = [
                    { content: 'This is warning event.' },
                ];
                break;
            case 15:
                listData = [
                    { content: 'This is warning event' },
                ];
                break;
            default:
        }
        return listData || [];
    }
    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            listData.map(item => (
                <Badge status='success' />
            ))
        );
    }
    return (
        <div className='schedule-page page-children'>
            <div className="calendar">
                <div className="header-widget">
                    <Button><PlusOutlined />Create schedule</Button>
                </div>
                <div className="calendar-schedule">
                    <Calendar  className='custom-calendar' fullscreen={false} onChange={onDateChange} dateCellRender={dateCellRender} />
                </div>,
            </div>
            <div className="event">
                <h6 className="title">June 16 (Today)</h6>
                <Timeline>
                    <Timeline.Item>
                        <div>
                            <p className="time">{moment().format('hh:mm A')} - {moment().format('hh:mm A')}</p>
                            <p className="content">Pre wedding shoot</p>
                        </div>
                    </Timeline.Item>

                    <Timeline.Item>
                        <div>
                            <p className="time">{moment().format('hh:mm A')} - {moment().format('hh:mm A')}</p>
                            <p className="content">Pre wedding shoot</p>
                        </div>
                    </Timeline.Item>

                    <Timeline.Item>
                        <div>
                            <p className="time">{moment().format('hh:mm A')} - {moment().format('hh:mm A')}</p>
                            <p className="content">Pre wedding shoot</p>
                        </div>
                    </Timeline.Item>

                </Timeline>
            </div>
        </div>
    );
}

export default Schedule;