import axiosInstance from "../../axiosInstance";

export const listHistoryAppointmentCompleteAPI = async (pageNo) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  pageNo: pageNo,
                  accessToken: accessToken
            },
            method: "appointmentHistoryCompleted"
      }
      return await axiosInstance.post('/appointmentHistoryCompleted', dataSubmit )
}

export const listHistoryAppointmentCancelAPI = async (pageNo) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  pageNo: pageNo,
                  accessToken: accessToken
            },
            method: "appointmentHistoryCancel"
      }
      return await axiosInstance.post('/appointmentHistoryCancel', dataSubmit )
}

export const checkPriceAppointment = async (data) => {
      return await axiosInstance.post('/CheckPriceAppointment', data )
}

export const listAppointmentPending = async (pageNo = "0") => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  pageNo: pageNo,
                  accessToken: accessToken
            },
            method: "pendingAppointment"
      }
      return await axiosInstance.post('/pendingAppointment', dataSubmit )
}

export const listAppointmentSchedule = async (pageNo = "0") => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  pageNo: pageNo,
                  accessToken: accessToken
            },
            method: "scheduledAppointment"
      }
      return await axiosInstance.post('/scheduledAppointment', dataSubmit )
}

export const detailAppointment = async (pageNo = "0") => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  accessToken: accessToken,
                  appointmentId: pageNo
            },
            method: "appointmentDetails"
      }
      return await axiosInstance.post('/appointmentDetails', dataSubmit )
}

export const getNearByCrews = async (lat, lng, distance = 350) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  accessToken: accessToken,
                  Longitude: lng,
                  Latitude: lat,
                  distance : distance,
            },
            method: "getNearByCrews"
      }
      return await axiosInstance.post('/getNearByCrews', dataSubmit )
}

export const actionAppointment = async (type , appointmentId , userId , reason = null) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  accessToken: accessToken,
                  Action: type,
                  appointmentId: appointmentId,
                  UserId: userId,
                  RejectReason: reason,
            },
            method: "AppointmentAction"
      }
      return await axiosInstance.post('/AppointmentAction', dataSubmit )
}

export const addTimeAppointment = async (appointmentId , time) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  accessToken: accessToken,
                  appointmentId: appointmentId,
                  extrahour: time,
            },
            method: "addextratime"
      }
      return await axiosInstance.post('/addextratime', dataSubmit )
}

export const finalPayment = async (appointmentId , note, rate) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  accessToken: accessToken,
                  appointmentId: appointmentId,
                  Comments: note,
                  ratings: rate,
            },
            method: "finalpayment"
      }
      return await axiosInstance.post('/finalpayment', dataSubmit )
}

export const checkAppointment = async () => {
      let accessToken = localStorage.getItem('access_token')
      let formData = new FormData();
      formData.append('accessToken', accessToken)
      return await axiosInstance.post('/checkAppointment', formData )
}

export const appointmentNotify = async (appointmentId, status) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  accessToken: accessToken,
                  appointmentId: appointmentId,
                  status: status,
            },
            method: "appointmentNotify"
      }
      const response =  await axiosInstance.post('/appointmentNotify', dataSubmit )
      return response.data
}


export const uploadAttachmentAppointmentComplete = async (formData) => {
      const response =  await axiosInstance.post('/uploadAttachment', formData )
      return response.data
}

export const addTip = async (appointmentId, price) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  accessToken: accessToken,
                  appointmentId: appointmentId,
                  Price: price,
            },
            method: "addTip"
      }
      const response =  await axiosInstance.post('/payment/addTip', dataSubmit )
      return response.data
}