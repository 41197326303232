import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";
import CompletedProfile from "../../views/login/components/CompletedProfile";


const PrivateRoute = ({component: Component, ...rest}) => {
      const {user} = useSelector(state => state.auth);

      return (
          <Route
              {...rest}
              render={props => {
                    if (localStorage.getItem('access_token')) {
                          if (user && user.UserName === '') {
                                return <CompletedProfile {...props}/>;
                          }
                          return <Component {...props} />
                    }
                    return <Redirect to={{pathname: '/', state: {from: props.location}}}/>
              }
              }
          />
      );
};

export default PrivateRoute;
