import { GET_LIST_NOTIFICATIONS} from "./action";

let initialState = {
    notifications: [],
};
const notifications =  (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_LIST_NOTIFICATIONS:
            let newDataNotifications = [...action.payload.notificatonList.data, ...state.notifications ]
            newDataNotifications = newDataNotifications.filter((set => f => !set.has(f.NotificationId) && set.add(f.NotificationId))(new Set()));
            return {
                notifications: newDataNotifications
            }
        // case ADD_NOTIFICATION:
        //     return {
        //         notifications: [...action.payload.notification, ...state.notifications]
        //     }
        default:
            return state;
    }
}
export default notifications

