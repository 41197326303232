import React, {useState} from 'react';
import {Button, Modal} from "antd";
import './index.scss'
import { Document, Page, pdfjs   } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ShowPdf = ({visible, file , type, closeModal}) => {
      const [numPages, setNumPages] = useState(null);
      const [pageNumber, setPageNumber] = useState(1);

      function onDocumentLoadSuccess({ numPages }) {
            setNumPages(numPages);
      }
      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-show-pdf'
                >
                      <div className="head-modal">
                            <Button onClick={closeModal}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <Document
                                file={{ url: type === 'term-policy' ? file : `https://cors-anywhere.herokuapp.com/${file}`}}
                                onLoadSuccess={onDocumentLoadSuccess}
                                className='wp-pdf'
                                // error={`Failed to load PDF file: ${file}`}
                                error= {<div><p>Failed to load PDF file!</p><a target="_blank" rel="noreferrer" href={file} > {file}</a></div>}
                            >
                                  <Page pageNumber={pageNumber}/>
                                  <div className='page-controls'>
                                        <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}><LeftOutlined /></Button>
                                        <span>{pageNumber} of {numPages}</span>
                                        <Button  disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)}><RightOutlined /></Button>
                                  </div>
                            </Document>
                      </div>
                </Modal>
          </div>
      );
}

export default ShowPdf;