import {createGlobalStyle} from 'styled-components';

const BaseStyles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  margin: 0;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/images/bg_auth.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;

};
html { font-family: 'Montserrat', sans-serif; }
* { box-sizing: border-box; };

.page-children{
  width: 80%;
  margin: 2rem auto 0;
}
h1{font-size : 2.5rem};
h2{font-size : 2rem};
h3{font-size : 1.75rem};
h4{font-size : 1.5rem};
h5{font-size : 1.25rem};
h6{font-size : 1rem};
p{margin-bottom : 1rem};
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
.ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input {
        background: #1a1a1a !important;
}
.ant-form-item-has-error .ant-input-affix-wrapper{
      border : 1px solid red !important;
}
.empty-data {
   .ant-empty-normal {
       margin: 10px;

       .ant-empty-image {
           .ant-empty-img-simple {
                width: 30px;
                height: 25px;
           }
       }

       .ant-empty-description {
           color: #808080;
       }
   }
}
`;

export default BaseStyles;