import React, {useCallback, useEffect, useState} from 'react';
import './index.scss'
import {Avatar, Button, Empty, Form, Image, Input, Modal, notification, Popconfirm, Rate, Upload} from 'antd';
import {PictureOutlined, FormOutlined, CameraOutlined, EnvironmentOutlined, InfoCircleOutlined, PhoneOutlined, MailOutlined, CameraFilled, DeleteFilled, PlusSquareOutlined, PlaySquareOutlined, CloudUploadOutlined, EditOutlined, VerifiedOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getBase64, getVaccineStatusName, validateVideoVimeo, validateVideoYoutube} from "../../utils/help";
import {
    addPortfolio,
    deletePortfolio,
    editProfile,
    getProfileUser,
    listPortfolioUser,
    setCurrentUser, updateLicense
} from "../../state/auth/actions";
import {NO_IMAGE, USER_TYPE_CREW} from "../../constants";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from 'react-modal-video'
import AlertError from "../../components/Alert/error";

const Profile = (props) => {
    const [loading, setLoading] = useState(false);
    const {user, portfolios} = useSelector(state => state.auth);
    const [avatar, setAvatar] = useState(null);
    const [isOpen, setOpen] = useState(false)
    const [isOpenModalAdd, setOpenModalAdd] = useState(false)
    const [urlSelected , setUrlSelected] = useState(null)
    const [chanel , setChanel] = useState(null)
    const dispatch = useDispatch()
    const [typePortfolio, setTypePortfolio] = useState(null)
    const [form] = Form.useForm();
    const accessToken = localStorage.getItem('access_token')
    const [fileListUpload, setFileListUpload] = useState([])
    const [fileLicenseFront, setFileLicenseFront] = useState(null)
    const [fileLicenseBack, setFileLicenseBack] = useState(null)
    const inputRef = React.useRef(null);

    const getProfile = useCallback ( async () => {
        let result = await getProfileUser(user.UserId)
        setAvatar(result.data.getProfile.data.ProfilePic)
        dispatch(setCurrentUser(result.data.getProfile.data))
    }, [dispatch, user?.UserId])

    useEffect(() => {
        getProfile()
    }, [getProfile])

    const initDataPortfolioUser = useCallback ( async () => {
        if(user.UserType === USER_TYPE_CREW){
            await dispatch(listPortfolioUser(user.UserId))
        }
    }, [dispatch, user?.UserType, user?.UserId])

    useEffect(() => {
        initDataPortfolioUser()
    }, [initDataPortfolioUser])

    const handleChangeAvatar = async info => {
        setLoading(true);
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                {
                    setAvatar(imageUrl)
                }
            );
            let dataSubmit = {
                UserId: user.UserId,
                UserType: user.UserType,
                Email : user.Email,
                UserName : user.UserName,
                FirstName : user.FirstName,
                LastName : user.LastName,
                Location : user.Location,
                CompanyName : user.CompanyName,
                MobileNo : user.MobileNo,
                AboutMe : user.AboutMe,
                UserExpirence : user.UserExpirence
            }
            let formData = new FormData();
            Object.keys(dataSubmit).forEach(item => formData.append(item, dataSubmit[item]))
            formData.append('profile', info.file.originFileObj)
            const result = await editProfile(formData);
            if(result.data.editProfile.status === true){
                notification.success({
                    message: 'Change image profile success!',
                    className: 'custom-notify-message-success'
                });
                dispatch(setCurrentUser(result.data.editProfile.data));
                await getProfile()
                setLoading(false);
            } else{
                notification.warning({
                    message: result.data.editProfile.Message,
                    className: 'custom-notify-message-success'
                });
                setLoading(false);
            }
        }
    };

    const renderStatistical = () => {
        return (
            user &&
            <div className='statistical'>
                {user.UserType !== USER_TYPE_CREW &&
                <div>
                    <p>{parseInt(user.hiredMembers) > 0 ? ('0' + parseInt(user.hiredMembers)).slice(-2) : parseInt(user.hiredMembers)}</p>
                    <p>Hired</p>
                </div>
                }
                {user.UserType === USER_TYPE_CREW &&
                <div className='exp-crew'>
                    <EditOutlined className='icon-edit-exp' onClick={() => {
                        inputRef.current.focus({
                            cursor: 'end',
                        });
                    }} />
                    <Input onBlur={updateExperience} name='exp_user'  defaultValue={user && user.UserExpirence} className='inp-exp' ref={inputRef} />
                    <p>Experience</p>
                </div>
                }

                <div>
                    <p>{parseInt(user.UserAppoinment) > 0 ? ('0' + parseInt(user.UserAppoinment)).slice(-2) : parseInt(user.UserAppoinment)}</p>
                    <p>Appointment</p>
                </div>
                <div>
                    <p>{parseInt(user.UserConnection) > 0 ? ('0' + parseInt(user.UserConnection)).slice(-2) : parseInt(user.UserConnection)}</p>
                    <p>Connection</p>
                </div>
            </div>
        )
    }

    const updateExperience = async (values) => {
        if(values.target.value && parseInt(values.target.value) !== parseInt(user.UserExpirence) ){
            let dataSubmit = {
                UserId: user.UserId,
                UserType: user.UserType,
                Email : user.Email,
                UserName : user.UserName,
                FirstName : user.FirstName,
                LastName : user.LastName,
                Location : user.Location,
                CompanyName : user.CompanyName,
                MobileNo : user.MobileNo,
                AboutMe : user.AboutMe,
                UserExpirence : values.target.value
            }
            let formData = new FormData();
            Object.keys(dataSubmit).forEach(item => formData.append(item, dataSubmit[item]))
            const result = await editProfile(formData);
            if(result.data.editProfile.status === true){
                notification.success({
                    message: 'Change experience success!',
                    className: 'custom-notify-message-success'
                });
                await getProfile()
            } else{
                notification.warning({
                    message: result.data.editProfile.Message,
                    className: 'custom-notify-message-success'
                });
            }
        }
    }

    const setOpenModalVideo = (url, chanel) => {
        setOpen(true)
        setUrlSelected(url)
        setChanel(chanel)
    }
    const normFile = (e) => {

        let files = e.fileList.map(item => item.originFileObj)
        setFileListUpload(files)

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const normFileLicenseFront = (e) => {
        if(e.fileList.length > 0){
            setFileLicenseFront(e.fileList[0].originFileObj)
        } else{
            setFileLicenseFront(null)
        }

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const normFileLicenseBack = (e) => {
        if(e.fileList.length > 0){
            setFileLicenseBack(e.fileList[0].originFileObj)
        } else{
            setFileLicenseBack(null)
        }

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const openModalAddPortfolio = (type) => {
        setOpenModalAdd(true)
        setTypePortfolio(type)
    }
    const closeModalAddPortfolio = () => {
        form.resetFields()
        setOpenModalAdd(false)
        setTypePortfolio(null)
        setFileListUpload([])
    }
    const onSavePortfolio = async (values) => {
        setLoading(true)
        let formData = new FormData();
        formData.append('accessToken', accessToken)
        if (typePortfolio === 'links'){
            if(!values.Links){
                setLoading(false)
                return AlertError('Please input your links!')
            }
            if(!validateVideoYoutube(values.Links) && !validateVideoVimeo(values.Links)){
                setLoading(false)
                return AlertError('Link invalid! Only allow youtube & vimeo links')
            }
            formData.append('Links', values.Links)
        }
        if(typePortfolio === 'image/video'){
            if(fileListUpload.length === 0){
                setLoading(false)
                return AlertError('Please choose image or video!')
            }
            let listTypeImage = ['image/jpeg', 'image/gif', 'image/png']
            let listTypeVideo = ['video/mp4', 'video/x-msvideo']
            let listFileImage = fileListUpload.filter(item =>  listTypeImage.includes(item.type))
            let listFileVideo = fileListUpload.filter(item => listTypeVideo.includes(item.type))
            Object.keys(listFileImage).forEach((key, i) => {
                formData.append('AttachmentImage[]', listFileImage[key]);
            });
            Object.keys(listFileVideo).forEach((key, i) => {
                formData.append('AttachmentVideo[]', listFileVideo[key]);
            });
        }
        if(typePortfolio === 'license'){
            if(fileLicenseFront){
                formData.append('LicenseIdFront', fileLicenseFront);
            }
            if(fileLicenseBack){
                formData.append('LicenseIdBack', fileLicenseBack);
            }
        }
        if(typePortfolio === 'links' || typePortfolio === 'image/video'){
            let result = await addPortfolio(formData);
            if(result.data.addPortfolio.status){
                notification.success({
                    message: result.data.addPortfolio.Message,
                    className: 'custom-notify-message-success'
                });
                setLoading(false)
                await initDataPortfolioUser()
                closeModalAddPortfolio()
            }else {
                notification.warning({
                    message: result.data.addPortfolio.Message,
                    className: 'custom-notify-message-success'
                });
                setLoading(false)
            }
        }
        if(typePortfolio === 'license'){
            let result = await updateLicense(formData);
            if(result.data.status){
                notification.success({
                    message: result.data.Message,
                    className: 'custom-notify-message-success'
                });
                setLoading(false)
                await getProfile()
                closeModalAddPortfolio()
            }else {
                notification.warning({
                    message: result.data.Message,
                    className: 'custom-notify-message-success'
                });
                setLoading(false)
            }
        }
    }
    const onDeletePortfolio =  async (id) => {
        let formData = new FormData();
        formData.append('accessToken', accessToken)
        formData.append('id', id)
        const result = await dispatch(deletePortfolio(formData, id))
        if(result.payload.data.status){
            notification.success({
                message: result.payload.data.Message,
                className: 'custom-notify-message-success'
            });
        }else {
            notification.warning({
                message: result.payload.data.Message,
                className: 'custom-notify-message-success'
            });
        }
    }

    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <div className='profile-page  page-children'>
            <div className="profile pt-5 pl-lg-5 pr-lg-5">
                <div className="header-profile">
                    <div className="info-profile">
                        <div className='avatar-profile'>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                accept=".jpg,.png,.jpeg"
                                action={process.env.REACT_APP_URL}
                                // action="http://crew.kendemo.com"
                                onChange={handleChangeAvatar}
                            >

                                {avatar ? <Avatar size={120} src={avatar}/> : <Avatar size={120} src={NO_IMAGE} />}
                                <CameraFilled className="icon-upload"/>
                            </Upload>
                        </div>
                        <div className='info'>
                            <h4 className='name'>{user && user.FirstName} {user && user.LastName}</h4>
                            <span className='username'>{user && user.UserName}</span>
                            {user && user.UserType === USER_TYPE_CREW &&
                                <React.Fragment>
                                    <div className='rate'>
                                        <Rate disabled allowHalf  defaultValue={user && user.ratings} />
                                    </div>
                                    <p className='address'> <VerifiedOutlined className='icon-address' />{user && getVaccineStatusName(user.vaccine_status)}</p>
                                </React.Fragment>

                            }
                            <p className='address'> <EnvironmentOutlined className='icon-address' />{user && user.Location ? user.Location : 'No info'}</p>
                        </div>
                    </div>

                    <div className='info-work'>
                        {renderStatistical()}
                        <div className='edit-profile'>
                            <Link to='edit-profile'><Button className={`${user?.UserType === USER_TYPE_CREW ? 'w-45' : 'w-100'}`}  icon={<FormOutlined />} >Edit Profile</Button></Link>
                            {user?.UserType === USER_TYPE_CREW &&
                                <Link to='service'><Button className='w-45 edit-service-btn' icon={<FormOutlined />} >Edit Service</Button></Link>
                            }
                        </div>
                    </div>
                </div>

                <div className='body-profile'>
                    <div className='about-me'>
                        <p className='title'> <InfoCircleOutlined className='icon-info' />About</p>
                        <div>
                            <p>{user && user.AboutMe && user.AboutMe !== '' ? user.AboutMe : 'No personal description updates yet!'}</p>
                        </div>
                    </div>
                    {user && user.UserType === USER_TYPE_CREW &&

                          <React.Fragment>
                              <div className='portfolio'>
                                  <div className="header-widget">
                                      <p className='title'> <InfoCircleOutlined className='icon-info' />Links</p>
                                      <Button onClick={() => openModalAddPortfolio('links')}><PlusSquareOutlined /></Button>
                                  </div>
                                  <div className="widget">
                                      {portfolios.filter(item => item.MediaType === 'links').length ?
                                            <div className='list'>
                                                {portfolios.filter(item => item.MediaType === 'links').map(item =>
                                                      <div className='item' key={item.id} >
                                                          <img src={item.MediaThumb} alt="" onClick={() => setOpenModalVideo( item.PortfolioLink.includes("youtube.com") ? item.PortfolioLink.split('/').slice(-1)[0].replace('watch?v=','') : item.PortfolioLink.split('/').slice(-1)[0].split('?')[0], item.PortfolioLink.includes("youtube.com") ? 'youtube' : 'vimeo')}/>
                                                          <PlaySquareOutlined className='open-model' onClick={() => setOpenModalVideo(item.PortfolioLink.split('/').slice(-1)[0].replace('watch?v=','') , 'youtube')}/>

                                                          <Popconfirm
                                                                placement="top"
                                                                title="Are you sure to delete?"
                                                                onConfirm={() => onDeletePortfolio(item.id)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                          >
                                                              <Button className='btn-delete-portfolio'><DeleteFilled  className='icon-delete-portfolio' /></Button>
                                                          </Popconfirm>
                                                      </div>
                                                )}
                                            </div>
                                            : <div className='empty-data'>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </div>
                                      }

                                  </div>
                              </div>

                              <div className='portfolio'>
                                  <div className="header-widget">
                                      <p className='title'> <PictureOutlined className='icon-info' />Portfolio</p>
                                      <Button onClick={() => openModalAddPortfolio('image/video')}><PlusSquareOutlined /></Button>
                                  </div>
                                  <div className="widget">
                                      <p className="title">Images</p>
                                      {portfolios.filter(item => item.MediaType === 'image').length > 0 ?
                                            <div className='list'>
                                                {portfolios.filter(item => item.MediaType === 'image').map(item =>
                                                      <div className='item' key={item.id}>
                                                          <Image src={item.Media} />
                                                          <Popconfirm
                                                                placement="top"
                                                                title="Are you sure to delete?"
                                                                onConfirm={() => onDeletePortfolio(item.id)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                          >
                                                              <Button className='btn-delete-portfolio'><DeleteFilled  className='icon-delete-portfolio' /></Button>
                                                          </Popconfirm>
                                                      </div>
                                                )}
                                            </div>
                                            : <div className='empty-data'>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </div>
                                      }

                                  </div>
                                  <div className="widget">
                                      <p className="title">Videos</p>
                                      {portfolios.filter(item => item.MediaType === 'video').length > 0 ?
                                            <div className='list'>
                                                {portfolios.filter(item => item.MediaType === 'video').map(item =>
                                                      <div className='item' key={item.id}>
                                                          <video onClick={() => setOpenModalVideo(item.Media, 'custom')}>
                                                              <source src={item.Media} type="video/mp4" />
                                                          </video>
                                                          <PlaySquareOutlined className='open-model' onClick={() => setOpenModalVideo(item.Media, 'custom')}/>
                                                          <Popconfirm
                                                                placement="top"
                                                                title="Are you sure to delete?"
                                                                onConfirm={() => onDeletePortfolio(item.id)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                          >
                                                              <Button className='btn-delete-portfolio'><DeleteFilled  className='icon-delete-portfolio' /></Button>
                                                          </Popconfirm>
                                                      </div>
                                                )}
                                            </div>
                                            : <div className='empty-data'>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </div>
                                      }

                                  </div>
                              </div>
                          </React.Fragment>

                    }

                    {/*{user && user.UserType === USER_TYPE_CREW &&*/}
                    {/*<div className='portfolio'>*/}
                    {/*    <div className="header-widget">*/}
                    {/*        <p className='title'> <InfoCircleOutlined className='icon-info' />License</p>*/}
                    {/*        <Button className='btn-update-license' onClick={() => openModalAddPortfolio('license')}><FormOutlined /></Button>*/}
                    {/*    </div>*/}
                    {/*    <div className="widget">*/}
                    {/*        {user && user.LicenseIdFront === '' && user.LicenseIdBack === '' ?*/}
                    {/*            <div className='empty-data'>*/}
                    {/*                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />*/}
                    {/*            </div>*/}
                    {/*            :*/}
                    {/*            <div className='list'>*/}
                    {/*                <div className='item'>*/}
                    {/*                    {user && user.LicenseIdFront &&*/}
                    {/*                        <Image src={user.LicenseIdFront} />*/}
                    {/*                    }*/}
                    {/*                </div>*/}
                    {/*                <div className='item'>*/}
                    {/*                    {user && user.LicenseIdBack &&*/}
                    {/*                        <Image src={user.LicenseIdBack} />*/}
                    {/*                    }*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*}*/}

                    <div className='contact-info'>
                        <p className='title'> <InfoCircleOutlined className='icon-info' />Contact info</p>
                        <div>
                            <p> <EnvironmentOutlined className='icon-contact' />{user && user.Location ? user.Location : 'No info location'}</p>
                            <p> <PhoneOutlined className='icon-contact' />{user && user.MobileNo && user.MobileNo !== '' ? user.MobileNo : 'No info mobile no'}</p>
                            <p> <MailOutlined className='icon-contact' />{user && user.Email}</p>
                        </div>
                    </div>

                    <ModalVideo channel={chanel} autoplay isOpen={isOpen} url={urlSelected} videoId ={urlSelected} onClose={() => setOpen(false)} />

                    {/*Modal add portfolio*/}
                    <Modal visible={isOpenModalAdd}
                           footer={null}
                           maskClosable={false}
                           closable={false}
                           centered
                           className='modal-add-portfolio'
                           width={700}
                    >
                        <div className="header-modal">
                            <p className="title">
                                {typePortfolio === 'links' && 'Add Link'}
                                {typePortfolio === 'image/video' && 'Add Image/ Video'}
                                {typePortfolio === 'license' && 'Update License'}
                            </p>
                            <Button onClick={() => closeModalAddPortfolio()}>Close</Button>
                        </div>
                        <div className="body-modal">
                            <Form
                                form={form}
                                name='portfolio'
                                onFinish={onSavePortfolio}
                                layout='vertical'
                            >
                                {typePortfolio === 'links' &&
                                    <div className="wp-inner">
                                        <div className="body-wp">
                                            <Form.Item
                                                name="Links"
                                                // rules={[{ required: true, message: 'Please input your links!' }]}
                                            >
                                                <Input placeholder='Links' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                }
                                {typePortfolio === 'image/video' &&
                                    <div className="wp-inner">
                                    <div className="body-wp">
                                        <Form.Item
                                            name="file"
                                            valuePropName="fileList"
                                            getValueFromEvent={normFile}
                                        >
                                            <Upload name="logo"
                                                    listType="picture"
                                                    accept=".png, .jpg, .jpeg , .mp4"
                                                    maxCount={10}
                                                    customRequest={dummyRequest}
                                            >
                                                <Button  className='btn-upload-file' icon={<CloudUploadOutlined/>}>Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                }
                                {typePortfolio === 'license' &&
                                    <div className="wp-inner">
                                    <div className="body-wp">
                                        <Form.Item
                                            name="file_license_front"
                                            valuePropName="fileList"
                                            getValueFromEvent={normFileLicenseFront}
                                            label="License Front"
                                        >
                                            <Upload name="LicenseIdFront"
                                                    action={process.env.REACT_APP_URL}
                                                    listType="picture"
                                                    accept=".png, .jpg, .jpeg"
                                                    maxCount={1}
                                                    customRequest={dummyRequest}
                                            >
                                                <Button  className='btn-upload-file' icon={<CloudUploadOutlined/>}>Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item
                                            name="file_license_back"
                                            valuePropName="fileList"
                                            getValueFromEvent={normFileLicenseBack}
                                            label="License Back"
                                        >
                                            <Upload name="LicenseIdBack"
                                                    customRequest={dummyRequest}
                                                action={process.env.REACT_APP_URL}
                                                listType="picture"
                                                accept=".png, .jpg, .jpeg"
                                                maxCount={1}
                                            >
                                                <Button  className='btn-upload-file' icon={<CloudUploadOutlined/>}>Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                }

                                <Form.Item >
                                    <Button loading={loading} id='btn-add-portfolio' htmlType="submit" className='w-100'>
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        {typePortfolio === 'links' &&
                            <div className="footer-modal">
                                <div className="note">
                                    <p>Note : </p>
                                    <div className="detail-note">
                                        <p>- Copy video URL</p>
                                        <p>- Link example :</p>
                                        <ul>
                                            <li>https://youtu.be/c8UUWkUJu6E</li>
                                            <li>https://www.youtube.com/watch?v=c8UUWkUJu6E</li>
                                            <li>http://vimeo.com/62092214</li>
                                            <li>https://www.vimeo.com/62092214</li>
                                            <li>https://vimeo.com/62092214?query=foo</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default Profile;