import React, {useState} from 'react';
import {Button, Form, Input, Select} from "antd";
import {EnvironmentOutlined, PhoneOutlined, UserOutlined, HomeOutlined, VerifiedOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {editProfile, logout} from "../../../../state/auth/actions";
import PlacesAutocomplete, {geocodeByAddress, getLatLng,} from 'react-places-autocomplete';
import {USER_TYPE_CLIENT, USER_TYPE_CREW} from "../../../../constants";
import './index.scss'
import AlertError from "../../../../components/Alert/error";
import {validatePhone} from "../../../../utils/help";

const UpdateProfile = ({user, closeUpdate}) => {
      const [loading, setLoading] = useState(false);
      const [location, setLocation] = useState('')
      const [coordinates, setCoordinates] = React.useState({
            lat: null,
            lng: null
      });

      const history = useHistory();
      const dispatch = useDispatch();

      const statusVaccine = [
            {
                  type : 0,
                  name : 'No',
            },
            {
                  type : 1,
                  name : 'Vaccinated',
            },
            {
                  type : 2,
                  name : 'Fully vaccinated',
            }
      ]

      const handleLogout = async () => {
            await dispatch(logout());
            history.push('/');
      };

      const handleUpdateProfile = async (values) => {
            if(!values.UserName){
                  return AlertError('Please input your user name!')
            }
            if(!values.MobileNo){
                  return AlertError('Please input your phone!')
            }
            if(values.MobileNo.length < 10){
                  return AlertError('Phone number at least 10 characters!')
            }
            if(!validatePhone(values.MobileNo)){
                  return AlertError('Phone invalid!')
            }
            if(user.UserType !== USER_TYPE_CREW && !values.CompanyName){
                  return AlertError('Please input your company name!')
            }
            if(!location){
                  return AlertError('Please input your location!')
            }

            setLoading(true);
            if(location === ''){
                  setLoading(false)
                  return;
            }
            let data = {
                  ...values,
                  FirstName: user.FirstName,
                  LastName: user.LastName,
                  UserId: user.UserId,
                  UserType: user.UserType,
                  Email : user.Email
            }
            let form = new FormData();
            Object.keys(data).forEach(item => form.append(item, data[item]))
            form.append('Location', location)
            form.append('Latitude', coordinates.lat)
            form.append('Longitude', coordinates.lng)
            const result = await editProfile(form);
            if(result.data.editProfile.status === true){
                  // AlertSuccess(result.data.editProfile.Message)
                  setLoading(false);
                  closeUpdate()
            } else{
                  AlertError(result.data.editProfile.Message)
                  setLoading(false);
            }
      }

      const handleSelect = async value => {
            const results = await geocodeByAddress(value);
            const latLng = await getLatLng(results[0]);
            setLocation(value);
            setCoordinates(latLng);
      };

      return (
          <div className="update-profile-after-sign-up">
                <div className="form-update-profile">
                      <div className="head-widget">
                            <h4>Update Profile</h4>
                            <Button onClick={handleLogout}>Sign out</Button>
                      </div>
                      <Form
                          name="form_update_profile_sign_up"
                          className="form-input"
                          initialValues={{
                                MobileNo: user.MobileNo
                          }}
                          onFinish={handleUpdateProfile}
                      >
                            <Form.Item
                                className="w-100"
                                name="UserName"
                                // rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                  <Input placeholder="User Name" className="w-100"
                                         prefix={<UserOutlined className="site-form-item-icon"/>}/>
                            </Form.Item>

                            <Form.Item
                                className="w-100"
                                name="MobileNo"
                                // rules={[{required: true, message: 'Please input your phone!'}, {min: 10, message: 'Phone number at least 10 characters!'}]}
                            >
                                  <Input type='number' placeholder="Phone" className="w-100"
                                         prefix={<PhoneOutlined className="site-form-item-icon"/>

                                         }/>
                            </Form.Item>
                            {user.UserType === USER_TYPE_CLIENT ?
                                <Form.Item
                                    className="w-100"
                                    name="CompanyName"
                                    // rules={[{required: true, message: 'Please input your company name!'}]}
                                >
                                      <Input placeholder="Company Name" className="w-100"
                                             prefix={<HomeOutlined className="site-form-item-icon"/>}/>
                                </Form.Item> : null}

                            <Form.Item
                                className="w-100"
                                // rules={[{required: true, message: 'Please input your location!'}]}
                            >
                                  <PlacesAutocomplete value={location}
                                                      onChange={value => setLocation(value)}
                                                      searchOptions={{
                                                            // types: ['address'],
                                                            componentRestrictions: { country: ['us'] },
                                                      }}
                                                      onSelect={handleSelect}>

                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                  <Input  prefix={<EnvironmentOutlined className="location-icon"/>}   {...getInputProps({placeholder: 'Location'})} />
                                                  <div>
                                                        {loading ? <div>...loading</div> : null}
                                                        {suggestions.map((suggestion) => {
                                                              const style = {
                                                                    backgroundColor : suggestion.active ? '#08ffc8' : '#fff' , padding : '10px'
                                                              }
                                                              return (
                                                                  <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                                        {suggestion.description}
                                                                  </div>
                                                              );
                                                        })}
                                                  </div>
                                            </div>
                                        )}
                                  </PlacesAutocomplete>
                            </Form.Item>
                            {user.UserType === USER_TYPE_CREW &&
                                  <Form.Item>
                                        <Form.Item name="vaccine_status" className='status-vaccine'>
                                              <Select placeholder="Covid-19 status?">
                                                    {statusVaccine.map(item =>
                                                        <Select.Option key ={item.type} value={item.type}>{item.name}</Select.Option>
                                                    )}
                                              </Select>
                                        </Form.Item>
                                        <Form.Item className='icon-vaccine'>
                                              <VerifiedOutlined />
                                        </Form.Item>
                                  </Form.Item>
                            }

                            <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
                                  <Button loading={loading} htmlType="submit" className="w-100" id="btn-update-profile">
                                        Update
                                  </Button>
                                  <br/>
                            </Form.Item>
                      </Form>
                </div>
          </div>
      );
};

export default UpdateProfile;
