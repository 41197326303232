import {
      SEND_REQUEST_CONNECTION,
      LIST_CONNECTED,
      LIST_PENDING,
      UNFOLLOW,
      CONFIRM_CONNECTION,
      REJECT_CONNECTION
} from "./action";


let initialState = {
      listConnected: [],
      listPending : []
};

const payments =  (state = initialState, action = {}) => {
      switch (action.type) {
            case LIST_CONNECTED:
                  return {
                        ...state,
                        listConnected : action.payload.searchConnected.data
                  }
            case LIST_PENDING:
                  return {
                        ...state,
                        listPending : action.payload.pendingconnectionList.data
                  }
            case SEND_REQUEST_CONNECTION:
                  let newConnectionPending = Object.keys(action.payload.result.sendRequest.data).length > 0 ? [action.payload.result.sendRequest.data] : []
                  if(newConnectionPending.length > 0 ){
                        newConnectionPending[0].UserId = action.payload.connectionData.UserId
                        newConnectionPending[0].connectionID = action.payload.connectionData.UserId
                        newConnectionPending[0].ProfilePic = action.payload.connectionData.ProfilePic
                        newConnectionPending[0].Address = action.payload.connectionData.Address
                        newConnectionPending[0].ConnectionName = action.payload.connectionData.ConnectionName
                  }
                  return {
                        ...state,
                        listPending: newConnectionPending.concat(state.listPending)
                  }
            case UNFOLLOW:
                  return {
                        ...state,
                        listConnected : [...state.listConnected].filter(item => item.UserId !== action.payload.connectionId)
                  }
            case CONFIRM_CONNECTION:
                  return {
                        ...state,
                        listPending :  [...state.listPending].filter(item => item.UserId !== action.payload.connectionId)
                  }
            case REJECT_CONNECTION:
                  return {
                        ...state,
                        listPending :  [...state.listPending].filter(item => item.UserId !== action.payload.connectionId)
                  }
            default:
                  return state;
      }
}

export default payments