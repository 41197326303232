import React, {useEffect, useState} from 'react';
import {Switch, Button} from 'antd';
import {PictureOutlined} from "@ant-design/icons/lib/icons";
import {BellOutlined, ApartmentOutlined, EyeFilled, QuestionOutlined, LockOutlined, RightOutlined, CreditCardOutlined} from '@ant-design/icons';
import {Link, useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {NOT_PUSH_NOTIFICATION, PUSH_NOTIFICATION, USER_TYPE_CREW} from "../../constants";
import {logout, updateSettings} from "../../state/auth/actions";
import AlertSuccess from "../../components/Alert/success";
import AlertError from "../../components/Alert/error";
import './index.scss';

const Setting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth);
  const [checked, setChecked] = useState(user && user.IsPush === '1')
  const [loading, setLoading] = useState(false)

  const handleLogout = async () => {
    await dispatch(logout());
    history.push('/');
  };

  const onSwitch = (checked) => {
    setChecked(checked)
    setLoading(true)
  }

  useEffect(() => {
    const settings = async () => {
      let isPush = checked ? PUSH_NOTIFICATION : NOT_PUSH_NOTIFICATION
      const result = await dispatch(updateSettings(isPush))
      if (result.payload.result.settings.status) {
        AlertSuccess(result.payload.result.settings.Message)
      } else {
        AlertError(result.payload.result.settings.Message)
      }
      setLoading(false)
    }

    let isPushNotification = user.IsPush === PUSH_NOTIFICATION
    if (checked !== isPushNotification) {
      settings()
    }
  }, [checked, dispatch, user])

  return (
    user &&
    <div className="setting-wrapper-page">
      <div className="setting">
        <h3>Settings</h3>
        <div className="item">
          <div className="d-flex align-items-center text-white">
            <BellOutlined className="mr-2"/><span>Notification</span>
          </div>
          <Switch loading={loading} defaultChecked={checked} onChange={onSwitch}/>
        </div>

        {user.UserType === USER_TYPE_CREW ?
          <>
            <div className="item">
              <div className="d-flex cursor-pointer align-items-center text-white">
                <ApartmentOutlined className="mr-2"/><Link to="/service">Service</Link>
              </div>
              <EyeFilled onClick={_ => history.push('/service')} style={{cursor: "pointer"}}/>
            </div>
            <div className="item">
              <div className="d-flex cursor-pointer align-items-center text-white">
                <PictureOutlined className="mr-2"/><Link to="/my-raised">My Raised</Link>
              </div>
              <EyeFilled onClick={_ => history.push('/my-raised')} style={{cursor: "pointer"}}/>
            </div>
          </>
          : null
        }
        {user.UserType !== USER_TYPE_CREW ?
          <>
            <div className="item">
              <div className="d-flex cursor-pointer align-items-center text-white">
                <PictureOutlined className="mr-2"/><Link to="/create-project">Create Project</Link>
              </div>
              <RightOutlined/>
            </div>
            <div className="item">
              <div className="d-flex cursor-pointer align-items-center text-white">
                <PictureOutlined className="mr-2"/><Link to="/my-project">My Project</Link>
              </div>
              <RightOutlined/>
            </div>
            <div className="item">
              <div className="d-flex cursor-pointer align-items-center text-white">
                <CreditCardOutlined className="mr-2"/><Link to="/payment">Payment</Link>
              </div>
              <RightOutlined/>
            </div>
          </> : null
        }
        <div className="item">
          <div className="d-flex align-items-center text-white">
            <LockOutlined className="mr-2"/><Link to="/change-password">Change password</Link>
          </div>
          <RightOutlined/>
        </div>

        <div className="item">
          <div className="d-flex align-items-center text-white">
            <LockOutlined className="mr-2"/><Link to="#">support@crewsnow.com</Link>
          </div>
          <RightOutlined/>
        </div>

        <div className="item">
          <div className="d-flex align-items-center text-white">
            <QuestionOutlined className="mr-2"/><Link to="#">FAQ</Link>
          </div>
        </div>
      </div>
      <div className='logout'>
        <Button type="primary" danger onClick={() => handleLogout()} className='w-50'>
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default Setting;
