import React, {useState} from 'react';
import {Avatar, Button, Form, Input, Modal, Rate} from "antd";
import './index.scss'
import {NO_IMAGE, PAYMENT_PAID} from "../../../../constants";
import {finalPayment} from "../../../../state/appointment/action";
import AlertError from "../../../../components/Alert/error";
import ModalResultSuccess from "../../../../components/Result";
import ModalTipAppointment from "../Tip";

const ModalFinalPayAppointment = ({visible, appointmentId, appointmentPrice , crewName, crewProfileImage , maxTip, closeModal}) =>  {
      const [loading, setLoading] = useState(false)
      const [isOpen, setOpen] = useState(false)
      const [message, setMessage] = useState(null)
      const [isOpenTip, setOpenTip] = useState(null)

      const finalPaymentAppointment = async (values) => {
           try {
                 setLoading(true)
                 let note = values.note ? values.note : ''
                 let rate = values.rate ? values.rate : '0'
                 const result = await finalPayment(appointmentId, note, rate)
                 if(result.data.finalpayment.status){
                       setMessage(result.data.finalpayment.Message)
                       setOpen(true)
                       setLoading(false)
                 } else{
                       setLoading(false)
                       AlertError(result.data.finalpayment.Message, 6)
                 }
           } catch (e) {
                 console.log(e)
           }
      }

      const closeModalResult = () => {
            setOpen(false)
            setMessage(null)
            setOpenTip(true)
      }

      const closeModalTip = (tips) => {
            setOpenTip(false)
            closeModal(appointmentId, PAYMENT_PAID, tips)
      }

      return (
          <React.Fragment>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-final-pay-appointment'
                    width={500}
                >
                      <div className="head-modal">
                            <h5>Appointment payment</h5>
                            <Button onClick={closeModal}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <div className="avatar">
                                  <Avatar size={48} src={crewProfileImage ? crewProfileImage : NO_IMAGE} />
                                  <p className="name-crew">{crewName}</p>
                            </div>
                            <Form
                                name="form-final-payment"
                                layout='vertical'
                                onFinish={finalPaymentAppointment}
                                initialValues={{ rate: 5 }}
                            >
                                  <Form.Item
                                      label="Please rate my service"
                                      name="rate"
                                      className='rate-service'
                                  >
                                        <Rate allowHalf />
                                  </Form.Item>
                                  <Form.Item
                                      name="note"
                                  >
                                        <Input placeholder='Your comment here'/>
                                  </Form.Item>
                                  <div className="price-appointment">
                                        <p>Appointment price : ${appointmentPrice}</p>
                                  </div>
                                  <Form.Item>
                                        <div className="button-pay">
                                              <Button onClick={closeModal}>Cancel</Button>
                                              <Button loading={loading} htmlType="submit" className='btn-pay-now'>Pay now</Button>
                                        </div>
                                  </Form.Item>
                            </Form>
                      </div>
                </Modal>
                {isOpen && <ModalResultSuccess isSuccess={isOpen} messageSuccess={message} closeModalSuccess={closeModalResult} />}
                {isOpenTip && <ModalTipAppointment visible={isOpenTip} appointmentId={appointmentId} crewName={crewName} crewProfileImage={crewProfileImage} maxTip={maxTip ? parseInt(maxTip) : 50} closeModal={closeModalTip} />}
          </React.Fragment>
      );
}

export default ModalFinalPayAppointment;