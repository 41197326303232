import axiosInstance from "../../axiosInstance";
export const LIST_CONNECTED = "LIST_CONNECTED";
export const LIST_PENDING = "LIST_PENDING";
export const SEND_REQUEST_CONNECTION = "SEND_REQUEST_CONNECTION";
export const UNFOLLOW = "UNFOLLOW";
export const CONFIRM_CONNECTION = "CONFIRM_CONNECTION";
export const REJECT_CONNECTION = "REJECT_CONNECTION";

export const sendRequestConnection = async (userId, connectionId, status, connectionData) => {
      let dataSubmit = {
            body: {
                  ConnectionId: connectionId,
                  Status: status,
                  UserId: userId,
            },
            method: "sendRequest"
      }
      const result = await axiosInstance.post('/sendRequest', dataSubmit )
      return {
            type: SEND_REQUEST_CONNECTION,
            payload: {
                  result : result.data,
                  connectionData : connectionData
            }
      };
}

export const pendingConnectionList = async (userId, txtSearch = '', pageNo , perPage ) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  UserId: userId,
                  pageNo : pageNo,
                  txtSearch : txtSearch,
                  accessToken: accessToken,
                  perPage: perPage,
            },
            method: "pendingconnectionList"
      }
      const result =  await axiosInstance.post('/pendingconnectionList', dataSubmit )
      return {
            type: LIST_PENDING,
            payload: result.data
      };
}

export const connectedList = async (txtSearch = '', pageNo, perPage ) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  pageNo : pageNo,
                  txtSearch : txtSearch,
                  accessToken: accessToken,
                  perPage: perPage,
            },
            method: "searchConnected"
      }
      const result =  await axiosInstance.post('/searchConnected', dataSubmit )
      return {
            type: LIST_CONNECTED,
            payload: result.data
      };
}

export const connectionList = async (userId , txtSearch = '', pageNo = 0 , perPage = 20 ) => {
      let accessToken = localStorage.getItem('access_token')
      let dataSubmit = {
            body: {
                  UserId : userId ,
                  pageNo : pageNo,
                  txtSearch : txtSearch,
                  accessToken: accessToken,
                  perPage: perPage,
            },
            method: "searchConnection"
      }
      return await axiosInstance.post('/searchConnection', dataSubmit )
}

export const unFollowConnection = async (connectionId, status, userId) => {
      let dataSubmit = {
            body: {
                  ConnectionId: connectionId,
                  Status: status,
                  UserId: userId,
            },
            method: "sendRequest"
      }
      const response =  await axiosInstance.post('/sendRequest', dataSubmit )
      return {
            type: UNFOLLOW,
            payload: {
                  data : response.data,
                  connectionId : connectionId
            }
      };
}

export const confirmConnection = async (connectionId, status, userId) => {
      let dataSubmit = {
            body: {
                  ConnectionId: connectionId,
                  Status: status,
                  UserId: userId,
            },
            method: "sendRequest"
      }
      const response =  await axiosInstance.post('/sendRequest', dataSubmit )
      return {
            type: CONFIRM_CONNECTION,
            payload: {
                  data : response.data,
                  connectionId : connectionId
            }
      };
}

export const rejectConnection = async (connectionId, status, userId) => {
      let dataSubmit = {
            body: {
                  ConnectionId: connectionId,
                  Status: status,
                  UserId: userId,
            },
            method: "sendRequest"
      }
      const response =  await axiosInstance.post('/sendRequest', dataSubmit )
      return {
            type: REJECT_CONNECTION,
            payload: {
                  data : response.data,
                  connectionId : connectionId
            }
      };
}