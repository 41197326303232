import React, {useState} from 'react';
import {Button, Modal} from "antd";
import ModalAddService from "../ModalAddService";
import './index.scss'
import {logout} from "../../../../state/auth/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import FileDoneOutlined from "@ant-design/icons/lib/icons/FileDoneOutlined";

const ModalCheckService = ({visible}) => {
    const [visibleAddService, setVisibleAddService] = useState(false);
    const dispatch = useDispatch()
    const history = useHistory();
    const showModalAddService = () => {
        setVisibleAddService(true);
    }
    const handleLogout = async () => {
        await dispatch(logout());
        history.push('/');
    };
    const closeModalAddService = (visibleAddService) => {
        setVisibleAddService(visibleAddService)
    }
    return (
        <div>
            <Modal
                footer={null}
                visible={visible}
                maskClosable={false}
                closable={false}
                centered
                className='modal-require-add-service'
            >
                <div>
                    <div className="header-modal">
                        <Button onClick={handleLogout}>Sign out</Button>
                    </div>
                    <div className="body-modal">
                        <FileDoneOutlined />
                        <p>Please add a service you can do </p>
                        <Button onClick={showModalAddService}>Add Service</Button>
                    </div>
                </div>
            </Modal>
            <ModalAddService visible={visibleAddService} closeModalAddService={closeModalAddService}  />
        </div>
    );
}

export default ModalCheckService;