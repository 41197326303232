import {ADD_CARD, DELETE_CARD, LIST_CARD, LIST_PAYMENT_HISTORY} from "./actions";

let initialState = {
    cards: [],
    histories : []
};

const payments =  (state = initialState, action = {}) => {
    switch (action.type) {
        case LIST_CARD:
            return {
                ...state,
                cards : action.payload.data.findCreditcardList.data
            }
        case ADD_CARD:
            let newCard = Object.keys(action.payload.card.addCards.data).length > 0 ? [action.payload.card.addCards.data] : []
            if(newCard.length > 0 ){
                 newCard[0].id = action.payload.card_id
                 newCard[0].last4 = action.payload.last4

            }
            return {
                ...state,
                cards: newCard.concat(state.cards)
            }
        case DELETE_CARD:
            return {
                ...state,
                cards : state.cards.filter(item => item.id !== action.payload.data.deleteCard.data.id)
            }
        case LIST_PAYMENT_HISTORY:
            return {
                ...state,
                histories : action.payload.data.paymentHistory.data
            }
        default:
            return state;
    }
}

export default payments

