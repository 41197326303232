import {notification} from "antd";
import {
    FULLY_VACCINATED_VACCINE_STATUS, FULLY_VACCINATED_VACCINE_STATUS_NAME,
    NONE_VACCINE_STATUS,
    NONE_VACCINE_STATUS_NAME,
    VACCINATED_VACCINE_STATUS,
    VACCINATED_VACCINE_STATUS_NAME
} from "../constants";
const zipcodes = require('zipcodes');

export const getBase64 = (img, callback) =>  {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export const getFirstDayWeek = () => {
    const curr = new Date();
    let date =  new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const getLastDayWeek = () => {
    const curr = new Date();
    let date =  new Date(curr.setDate(curr.getDate() - curr.getDay() + 7 ));
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

// export const convertTimeStampToString = (timestamp) => {
//     let date = new Date(timestamp)
//     return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
// }

export const formatCurrency = (number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
}

export const openNotificationWithIcon = (title, message, type, icon = null) => {
    notification[type]({
        message: title,
        description: message,
        duration: 25,
        icon
    });
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    const re = /^\d+$/
    return re.test(String(phone).toLowerCase());
}

export const validateVideoYoutube = (link) => {
    //eslint-disable-next-line
    const re = /https?:\/\/(?:youtu\.be\/|(?:[a-z]{2,3}\.)?youtube\.com\/watch(?:\?|#\!)v=)([\w-]{11}).*/gi
    return re.test(String(link).toLowerCase());
}
export const validateVideoVimeo = (link) => {
    //eslint-disable-next-line
    const re = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    return re.test(String(link).toLowerCase());
}

export const getAddressFromZipcode =  (zip) => {
    let hills = zipcodes.lookup(zip);
    if(hills){
        return {
            lat : hills.latitude,
            lng : hills.longitude,
            state : hills.state,
            city : hills.city,
            country : hills.country
        }
    } else{
        return null
    }
}

export const getVaccineStatusName = (type) => {
    let name = ''
    switch(type) {
        case NONE_VACCINE_STATUS:
            name = NONE_VACCINE_STATUS_NAME
            // code block
            break;
        case VACCINATED_VACCINE_STATUS:
            name = VACCINATED_VACCINE_STATUS_NAME
            // code block
            break;
        case FULLY_VACCINATED_VACCINE_STATUS:
            name = FULLY_VACCINATED_VACCINE_STATUS_NAME
            // code block
            break;
        default:
            name = NONE_VACCINE_STATUS_NAME
    }
    return name;
}

export const checkObjectEmptyOrNull = (object) => {
    return  !object || (object &&  Object.keys(object).length === 0)
}
