import React, {useEffect, useState} from 'react';
import {Avatar, Button, Modal, Image, Rate, Form, Select} from "antd";
import { EnvironmentOutlined , PictureOutlined, ScheduleOutlined , InfoCircleOutlined, PlaySquareOutlined, PhoneOutlined, MailOutlined, VerifiedOutlined} from "@ant-design/icons/lib/icons";
import './index.scss'
import {getProfileUser} from "../../state/auth/actions";
import {getListPortfolioOfCrew} from "../../state/crew/actions";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from 'react-modal-video'
import {useDispatch, useSelector} from "react-redux";
import {sendRequestConnection} from "../../state/connection/action";
import {NO_IMAGE, STATUS_INVITATION_CONNECTION, USER_TYPE_CREW} from "../../constants";
import AlertSuccess from "../Alert/success";
import AlertError from "../Alert/error";
import Loading from "../Loading";
import EmptyData from "../Empty";
import {useHistory} from 'react-router-dom'
import {getVaccineStatusName} from "../../utils/help";
const google = window.google;

const ModalShowProfile= ({visible, idUser,  closeModal}) => {
      const [profile , setProfile] = useState(null)
      const [spinState, setSpinState] = useState(true)
      const [portfolios, setPortfolios] = useState([])
      const [isOpen, setOpen] = useState(false)
      const [urlSelected , setUrlSelected] = useState(null)
      const [chanel , setChanel] = useState(null)
      const {user} = useSelector(state => state.auth)
      const [loading, setLoading] = useState(false)
      const [services, setServices] = useState([])
      const [isOpenModalBookNow, setOpenModalBookNow] = useState(false)
      const history = useHistory()
      const dispatch = useDispatch()
      const [location, setLocation] = useState('')

      useEffect(() => {
            const initDataProfile = () => {
                  try {
                        setSpinState(true)
                        Promise.all([
                              getProfileUser(idUser),
                              getListPortfolioOfCrew(idUser)
                        ]).then(async (res) => {
                              let userData = res[0].data.getProfile.data;
                              let portfolioData = res[1].data.getUserPortfolio.data;
                              setProfile(userData)
                              if(userData.UserType === USER_TYPE_CREW){
                                    setServices(userData.services)
                                    setPortfolios(portfolioData)
                              }
                              if(userData?.Latitude && userData?.Longitude){
                                    const geocoder = new google.maps.Geocoder();
                                    const latLng = {
                                          lat: parseFloat(userData.Latitude),
                                          lng: parseFloat(userData.Longitude),
                                    };
                                    let response = await geocoder.geocode({ location: latLng })
                                    if(response && response.results[0]){
                                          let city = response.results[0].formatted_address.split(',')[1]
                                          let state = response.results[0].formatted_address.split(',')[2]
                                          setLocation(`${city}, ${state}`)
                                    }
                              }
                              setSpinState(false)
                        })
                  } catch (e) {
                        console.log(e)
                  }
            }
            initDataProfile()
      }, [idUser, visible])

      const setOpenModalVideo = (url, chanel) => {
            setOpen(true)
            setUrlSelected(url)
            setChanel(chanel)
      }

      const onFollow = async () => {
            setLoading(true)
            let connectionData = {
                  connectionID: profile.UserId,
                  ProfilePic: profile.ProfilePic,
                  Address: profile.Address,
                  ConnectionName: `${profile.FirstName} ${profile.LastName}`,
                  UserId: profile.UserId
            }
            const result = await dispatch(sendRequestConnection(user.UserId , profile.UserId, STATUS_INVITATION_CONNECTION, connectionData))
            if(result.payload.result.sendRequest.status){
                  setLoading(false)
                  setProfile({...profile, ConnectionStatus: 'pending' })
                  AlertSuccess(result.payload.result.sendRequest.Message)
            } else{
                  setLoading(false)
                  AlertError(result.payload.result.sendRequest.Message)
            }
      }
      const renderListServices = () => {
            if(services.length > 0){
                  return services.map(item =>
                      <Select.Option key={item.serviceId} value={item.serviceId}>{item.service.serviceName}</Select.Option>
                  )
            }
      }

      const bookNow = async (values) => {
            setLoading(true)
            if(!values.service){
                  return AlertError('Please select service !')
            }

            let serviceSelect = services.filter(item => item.serviceId === values.service)
            let dataServiceSelect = {
                  serviceId : serviceSelect[0].serviceId,
                  ServiceType : serviceSelect[0].service.ServiceType,
                  serviceName : serviceSelect[0].service.serviceName,
                  servicePrice : serviceSelect[0].service.servicePrice,
                  LevelType : serviceSelect[0].service.LevelType,
                  ViewingOutlet : serviceSelect[0].service.ViewingOutlet,
                  FileTypeEdit : serviceSelect[0].service.FileTypeEdit,
                  LengthOfSource : serviceSelect[0].service.LengthOfSource,
                  FinalLengthDesired : serviceSelect[0].service.FinalLengthDesired,
                  OutputType : serviceSelect[0].service.OutputType,
                  MasterFilesNeeded : serviceSelect[0].service.MasterFilesNeeded,
            }
            let profileCrew = profile
            profileCrew.Location = location ? location : profileCrew.Location
            profileCrew.dayMaxPrice = serviceSelect[0].dayMaxPrice
            profileCrew.dayMinPrice = serviceSelect[0].dayMinPrice
            profileCrew.serviceMinPrice = serviceSelect[0].serviceMinPrice
            profileCrew.serviceMaxPrice = serviceSelect[0].serviceMaxPrice
            profileCrew.serviceName = serviceSelect[0].service.serviceName
            profileCrew.servicePrice = serviceSelect[0].service.servicePrice
            let appointmentData = {
                  crew : profileCrew,
                  service : dataServiceSelect
            }
            await localStorage.setItem('appointment', JSON.stringify(appointmentData));
            if(history.location.pathname === '/booking'){
                  closeModal()
                  setLoading(false)
                  return
            }
            history.push("/booking");
            setLoading(false)
      }

      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-show-profile'
                    width={1000}
                >
                      <div className="head-modal">
                            <h4 className="title">Profile</h4>
                            <Button onClick={closeModal}>Close</Button>
                      </div>
                      {!spinState ?
                              <div className='result-detail-page w-100'>
                                  <div className="header-profile">
                                        <div className="profile">
                                              <div className='avatar-profile'>
                                                    <Avatar size={120} src={profile.ProfilePic ? profile.ProfilePic : NO_IMAGE} />
                                              </div>
                                              <div className='info'>
                                                    <h4 className='name'>{profile.FirstName} {profile.LastName}</h4>
                                                    <span className='username'>{profile.UserName}</span>
                                                    <div className='rate'>
                                                          <Rate  allowHalf  disabled defaultValue={profile.ratings} />
                                                    </div>
                                                    <p className='address'> <VerifiedOutlined className='icon-address' />{profile && getVaccineStatusName(profile.vaccine_status)}</p>
                                                    <p className='address'> <EnvironmentOutlined className='icon-address' />{location ? location : 'No info' }</p>
                                              </div>
                                        </div>
                                        <div className='info-work'>
                                              <div className='statistical'>
                                                    {profile.UserType !== USER_TYPE_CREW &&
                                                          <div>
                                                                <p>{parseInt(profile.hiredMembers) > 0 ? ('0' + parseInt(profile.hiredMembers)).slice(-2) : parseInt(profile.hiredMembers)}</p>
                                                                <p>Hired</p>
                                                          </div>
                                                    }
                                                    {profile.UserType === USER_TYPE_CREW &&
                                                          <div>
                                                                <p>{profile.UserExpirence && parseInt(profile.UserExpirence) > 0 ? ('0' + parseInt(profile.UserExpirence)).slice(-2) : profile.UserExpirence ? parseInt(profile.UserExpirence) : 0}</p>
                                                                <p>Experience</p>
                                                          </div>
                                                    }
                                                    <div>
                                                          <p>{parseInt(profile.UserAppoinment) > 0 ? ('0' + parseInt(profile.UserAppoinment)).slice(-2) : parseInt(profile.UserAppoinment)}</p>
                                                          <p>Appointment</p>
                                                    </div>
                                                    <div>
                                                          <p>{parseInt(profile.UserConnection) > 0 ? ('0' + parseInt(profile.UserConnection)).slice(-2) : parseInt(profile.UserConnection)}</p>
                                                          <p>Connection</p>
                                                    </div>
                                              </div>
                                              {profile.UserType === USER_TYPE_CREW &&
                                                    <div className='action'>
                                                          {!profile.ConnectionStatus &&
                                                          <Button loading={loading} onClick={onFollow}>Follow</Button>
                                                          }
                                                          <Button onClick={() => setOpenModalBookNow(true)}>Book now</Button>
                                                    </div>
                                              }
                                        </div>
                                  </div>
                                  <div className='body-profile'>
                                        <div className='about-me'>
                                              <h6 className='title'> <InfoCircleOutlined className='icon-info' />About</h6>
                                              <p>{profile.AboutMe ? profile.AboutMe : 'No personal description updates yet!'}</p>
                                        </div>
                                        {profile.UserType === USER_TYPE_CREW &&
                                            <React.Fragment>
                                                  <div className='portfolio'>
                                                        <h6 className='title'> <PictureOutlined className='icon-info' />Links</h6>
                                                        <div className="widget">
                                                              {portfolios.filter(item => item.MediaType === 'links').length ?
                                                                  <div className='list'>
                                                                        {portfolios.filter(item => item.MediaType === 'links').map(item =>
                                                                            <div className='item' key={item.id} >
                                                                                  <img src={item.MediaThumb} alt="" onClick={() => setOpenModalVideo( item.PortfolioLink.split('/').slice(-1)[0].replace('watch?v=',''), 'youtube')}/>
                                                                                  <PlaySquareOutlined className='open-model' onClick={() => setOpenModalVideo(item.PortfolioLink.split('/').slice(-1)[0].replace('watch?v=','') , 'youtube')}/>
                                                                            </div>
                                                                        )}
                                                                  </div>
                                                                  :
                                                                  <EmptyData/>
                                                              }
                                                        </div>
                                                  </div>
                                                  <div className='portfolio'>
                                                        <h6 className='title'> <PictureOutlined className='icon-info' />Portfolio</h6>
                                                        <div className="widget">
                                                              <p className="title">Images</p>
                                                              {portfolios.filter(item => item.MediaType === 'image').length > 0 ?
                                                                  <div className='list'>
                                                                        {portfolios.filter(item => item.MediaType === 'image').map(item =>
                                                                            <div className='item' key={item.id}>
                                                                                  <Image src={item.Media} />
                                                                            </div>
                                                                        )}
                                                                  </div>
                                                                  :
                                                                  <EmptyData/>
                                                              }

                                                        </div>
                                                        <div className="widget">
                                                              <p className="title">Videos</p>
                                                              {portfolios.filter(item => item.MediaType === 'video').length > 0 ?
                                                                  <div className='list'>
                                                                        {portfolios.filter(item => item.MediaType === 'video').map(item =>
                                                                            <div className='item' key={item.id}>
                                                                                  <video onClick={() => setOpenModalVideo(item.Media, 'custom')}>
                                                                                        <source src={item.Media} type="video/mp4" />
                                                                                  </video>
                                                                                  <PlaySquareOutlined className='open-model' onClick={() => setOpenModalVideo(item.Media, 'custom')}/>
                                                                            </div>
                                                                        )}
                                                                  </div>
                                                                  :
                                                                  <EmptyData/>
                                                              }
                                                        </div>
                                                  </div>
                                                  <div className='services-profile'>
                                                        <h6 className='title'> <ScheduleOutlined className='icon-info' />Services</h6>
                                                        <div className='list'>
                                                              {profile.services.length > 0 ?
                                                                  profile.services.map(item =>
                                                                      <div key={item.id} className="item">
                                                                            <div className="name">
                                                                                  <p>{item.service.serviceName}</p>
                                                                            </div>
                                                                            <div className="price">
                                                                                  <div>
                                                                                        <p>Price ( per/hour )</p>
                                                                                        <p>${parseFloat(item.serviceMaxPrice).toFixed(2)}</p>
                                                                                  </div>
                                                                                  <div>
                                                                                        <p>Price ( per/day )</p>
                                                                                        <p>${parseFloat(item.dayMaxPrice).toFixed(2)}</p>
                                                                                  </div>
                                                                            </div>
                                                                      </div>
                                                                  )
                                                                  :
                                                                  <EmptyData/>
                                                              }
                                                        </div>
                                                  </div>
                                            </React.Fragment>
                                        }
                                        <div className='contact-info'>
                                              <h6 className='title'> <InfoCircleOutlined className='icon-info' />Contact</h6>
                                              <div>
                                                    <p> <PhoneOutlined className='icon-contact' />Contact admin</p>
                                                    <p> <MailOutlined className='icon-contact' />Contact admin</p>
                                              </div>
                                        </div>
                                  </div>

                              </div>
                      :
                          <Loading/>
                      }
                </Modal>
                <ModalVideo channel={chanel} autoplay isOpen={isOpen} url={urlSelected} videoId ={urlSelected} onClose={() => setOpen(false)} />
                <Modal
                    footer={null}
                    visible={isOpenModalBookNow}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-book-crew-now'
                    width={500}
                >
                      <div className="head-modal">
                            <h4 className="title">Book Crew Now</h4>
                            <Button onClick={() => setOpenModalBookNow(false)}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <Form
                                className="w-100"
                                name='book-crew-now'
                                layout="vertical"
                                onFinish={bookNow}
                            >
                                  <Form.Item
                                      label="Select service"
                                      name="service"
                                      rules={[{ required: true, message: 'Please select service!' }]}
                                  >
                                        <Select
                                            placeholder="Select service"
                                        >
                                              {renderListServices()}
                                        </Select>
                                  </Form.Item>
                                  <Form.Item>
                                        <Button loading={loading} htmlType='submit' className='btn-book-now w-100'>Book now</Button>
                                  </Form.Item>
                            </Form>
                      </div>
                </Modal>
          </div>

      );
}

export default ModalShowProfile;