import {LOGIN, LOGIN_SOCIAL, LOGOUT, GET_LIST_PORTFOLIO, AUTH_USER, DELETE_PORTFOLIO, UPDATE_SETTING} from './actions';
// import {GET_LIST_NOTIFICATIONS} from "../notifications/action";

let INITIAL_STATE = {
    logged: false,
    user: null,
    portfolios : [],
    // notifications: [],
};

const auth =  (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN:
            INITIAL_STATE = {
                ...INITIAL_STATE,
                logged: action.payload.checkLogin ? action.payload.checkLogin.status : false,
                user: action.payload.checkLogin ? action.payload.checkLogin.data : null
            };
            return INITIAL_STATE
        case LOGIN_SOCIAL:
            INITIAL_STATE = {
                ...INITIAL_STATE,
                logged: action.payload.socialLogin ? action.payload.socialLogin.status : false,
                user: action.payload.socialLogin ? action.payload.socialLogin.data : null
            };
            return INITIAL_STATE

        case AUTH_USER :
            INITIAL_STATE = {
                ...INITIAL_STATE,
                user: action.payload ? action.payload.data : null
            };
            return INITIAL_STATE
        case GET_LIST_PORTFOLIO :
            INITIAL_STATE = {
                ...INITIAL_STATE,
                portfolios: action.payload.getUserPortfolio ? action.payload.getUserPortfolio.data : []
            };
            return INITIAL_STATE
        case DELETE_PORTFOLIO:
            return {
                ...state,
                portfolios : state.portfolios.filter(item => item.id !== action.payload.id)
            }
        case UPDATE_SETTING:
            let newUser = {...state.user}
            newUser.IsPush = action.payload.isPush
            return {
                ...state,
                user : newUser
            }
        // case GET_LIST_NOTIFICATIONS:
        //     let newDataNotifications = [...action.payload.notificatonList.data, ...state.notifications]
        //     newDataNotifications = newDataNotifications.filter((set => f => !set.has(f.NotificationId) && set.add(f.NotificationId))(new Set()));
        //     return {
        //         ...state,
        //         notifications: newDataNotifications
        //     }
        // case ADD_NOTIFICATION:
        //     return {
        //             ...state,
        //         notifications: [...action.payload.notification, ...state.notifications]
        //     }
        case LOGOUT:
            return  {
                logged: false,
                user: null,
                portfolios : [],
                notifications: []
            };
        default:
            return state;
    }
};
export default auth