import axiosInstance from '../../axiosInstance';

export const GET_LIST_SERVICE = "GET_LIST_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const ADD_SERVICE = "ADD_SERVICE";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const SET_SERVICE_EDIT = "SET_SERVICE_EDIT";

export const addService = async (data) => {
    const result = await axiosInstance.post('/service/add', data);
    return {
        type: ADD_SERVICE,
        payload: result
    };
}

export const getListServiceOfUser = async () => {
    let accessToken = localStorage.getItem('access_token');
    let formData = new FormData();
    formData.append("accessToken", accessToken)
    let data = await axiosInstance.post('/service/list', formData);
    return {
        type: GET_LIST_SERVICE,
        payload: data
    };
}

export const deleteService = async (id) => {
    let accessToken = localStorage.getItem('access_token');
    let formData = new FormData();
    formData.append("accessToken", accessToken)
    formData.append("id", id)
    const result = await axiosInstance.post('/service/delete', formData);
    return {
        type: DELETE_SERVICE,
        payload: result
    };
}

export const setServiceEdit = (service) => {
    return {
        type : SET_SERVICE_EDIT,
        payload : {
            service : service
        }
    }
};

export const editService = async (data) => {
    const result = await axiosInstance.post('/service/update', data);
    return {
        type: EDIT_SERVICE,
        payload: result
    };
}
