import {applyMiddleware, compose, createStore} from "redux";
import ReduxThunk from 'redux-thunk';
import promiseMiddleware from "redux-promise";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import rootReducer from "./state/reducers";
const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2
};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

const configStore = () => {
    const store = createStore(
        persistedReducer,
        composeEnhancer(applyMiddleware(ReduxThunk, promiseMiddleware)),
    );
    return store;
};


export default configStore;