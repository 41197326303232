import {notification} from "antd";

const AlertError = (message, duration) => {
      return (
          notification.warning({
                message: message,
                className: 'custom-notify-message-success',
                duration : duration ? duration : 2
          })
      )
}
export default AlertError