import React from 'react';
import {Spin} from "antd";
import './index.scss'

const Loading = (props) => {
      return (
          <div className='wp-loading-data'>
                <Spin tip="Loading..."  className='spin-loading-data'>
                </Spin>
          </div>
      );
}

export default Loading;