import React from 'react';
import './index.scss'
import {List, Avatar, Button} from 'antd';
import {useSelector} from "react-redux";
import {
      TYPE_APPOINTMENT_ACCEPT,
      TYPE_APPOINTMENT_ACCEPT_REQUEST,
      TYPE_APPOINTMENT_BOOK, TYPE_APPOINTMENT_CANCEL, TYPE_APPOINTMENT_COMPLETE, TYPE_APPOINTMENT_EDIT,
      TYPE_INVITE_ACCEPT,
      TYPE_INVITE_SEND
} from "../../constants";
import {useHistory } from "react-router-dom";

const Notification = ({closeDropdown}) => {
      const {notifications} = useSelector(state => state.notifications);
      const history = useHistory()
      // const dispatch = useDispatch()
      // const menuAllNotification = (
      //     <Menu>
      //         <Menu.Item key="0">
      //             <a href="https://www.aliyun.com"><CheckOutlined />Mark all as Read</a>
      //         </Menu.Item>
      //         <Menu.Item key="1">
      //             <a href="https://www.antgroup.com"><CloseOutlined />Remove all notification</a>
      //         </Menu.Item>
      //     </Menu>
      // );
      // const menu = (
      //     <Menu>
      //         <Menu.Item key="0">
      //             <a href="https://www.aliyun.com"><CheckOutlined />Mark as Read</a>
      //         </Menu.Item>
      //         <Menu.Item key="1">
      //             <a href="https://www.antgroup.com"><CloseOutlined />Remove this notification</a>
      //         </Menu.Item>
      //     </Menu>
      // );

      const readNotification = async (item) => {
            // console.log(item)
            let typeNotify = item.ActionType
            if(typeNotify === TYPE_INVITE_SEND){
                  let notificationData = {
                        tabActive : 'invites'
                  }
                  await localStorage.setItem('notificationData', JSON.stringify(notificationData));
                  closeDropdown()
                  return history.push('/connection')
            }
            if(typeNotify === TYPE_INVITE_ACCEPT){
                  closeDropdown()
                  return history.push('/connection')
            }
            const listTypeSeeDetailInAppointmentPage = [
                  TYPE_APPOINTMENT_BOOK ,
                  TYPE_APPOINTMENT_ACCEPT ,
                  TYPE_APPOINTMENT_ACCEPT_REQUEST,
                  TYPE_APPOINTMENT_EDIT
            ]
            if(listTypeSeeDetailInAppointmentPage.includes(typeNotify)){
                  let notificationData = {
                        appointmentId : item.appointmentId,
                        seeDetail : true
                  }
                  await localStorage.setItem('notificationData', JSON.stringify(notificationData));
                  closeDropdown()
                  return history.push('/appointment')
            }
            const listTypeSeeDetailInHistoryPage = [
                  TYPE_APPOINTMENT_CANCEL ,
                  TYPE_APPOINTMENT_COMPLETE
            ]
            if(listTypeSeeDetailInHistoryPage.includes(typeNotify)){
                  let notificationData = {
                        appointmentId : item.appointmentId,
                        seeDetail : true
                  }
                  await localStorage.setItem('notificationData', JSON.stringify(notificationData));
                  closeDropdown()
                  return history.push('/history')
            }
      }

      const seeAllNotifications = () => {
            closeDropdown()
            history.push('/notifications')
      }
      return (
          <div className='wp-notification'>
                <div className='header-notification'>
                      <div className="title">
                            <h5>Notifications</h5>
                      </div>
                      {/*<div className="action">*/}
                      {/*    <Dropdown overlayClassName='dropdown-action-notification-header' overlay={menuAllNotification} trigger={['click']}>*/}
                      {/*        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
                      {/*            <DashOutlined />*/}
                      {/*        </a>*/}
                      {/*    </Dropdown>*/}
                      {/*</div>*/}
                </div>
                <List
                    itemLayout="horizontal"
                    className='notification'
                    dataSource={notifications}
                    renderItem={item => (
                        <List.Item onClick={() => readNotification(item)}>
                              <List.Item.Meta
                                  avatar={<Avatar
                                      src={item.ProfilePic && item.ProfilePic !== '' ? item.ProfilePic : `https://orbis-alliance.com/wp-content/themes/consultix/images/no-image-found-360x260.png`}/>}
                                  title={item.message}
                                  description={item.NotificationTime}
                              />
                              {/*<div className="action">*/}
                              {/*    <Dropdown overlayClassName='dropdown-action-notification-header' overlay={menu} trigger={['click']}>*/}
                              {/*        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
                              {/*            <DashOutlined />*/}
                              {/*        </a>*/}
                              {/*    </Dropdown>*/}
                              {/*</div>*/}
                        </List.Item>
                    )}
                />
                <div className='footer'>
                      <Button onClick={seeAllNotifications}>See all notifications</Button>
                </div>
          </div>
      );
}

export default Notification;