import axiosInstance from '../../axiosInstance';

export const LIST_CARD = "LIST_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const ADD_CARD = "ADD_CARD";
export const LIST_PAYMENT_HISTORY = "LIST_PAYMENT_HISTORY";

export const addCard = async (card_token, card_id, last4) => {
    let accessToken = localStorage.getItem('access_token');
    const dataSubmit = {
        method: "addCards",
        body: {
            card_token: card_token,
            accessToken: accessToken
        }
    }
    const result = await axiosInstance.post('/addCards', dataSubmit);
    return {
        type: ADD_CARD,
        payload: {
            card : result.data,
            card_id : card_id,
            last4 : last4
        }
    };
}

export const getListCard = async (pageNo = 0) => {
    let accessToken = localStorage.getItem('access_token');
    const dataSubmit = {
        method: "findCreditcardList",
        body: {
            pageNo: pageNo,
            accessToken: accessToken
        }
    }
    let result = await axiosInstance.post('/findCreditcardList', dataSubmit);
    return {
        type: LIST_CARD,
        payload: result
    };
}

export const deleteCard = async (id) => {
    let accessToken = localStorage.getItem('access_token');
    const dataSubmit = {
        method: "deleteCard",
        body: {
            id: id,
            accessToken: accessToken
        }
    }
    const result = await axiosInstance.post('/deleteCard', dataSubmit);
    return {
        type: DELETE_CARD,
        payload: result
    };
}

export const getListPaymentHistory = async (pageNo = 0) => {
    let accessToken = localStorage.getItem('access_token');
    const dataSubmit = {
        method: "paymentHistory",
        body: {
            pageNo: pageNo,
            accessToken: accessToken
        }
    }
    let result = await axiosInstance.post('/payment/paymentHistory', dataSubmit);
    return {
        type: LIST_PAYMENT_HISTORY,
        payload: result
    };
}

export const earning = async (startDate, endDate) => {
    let accessToken = localStorage.getItem('access_token');
    const dataSubmit = {
        method: "Earning",
        body: {
            accessToken: accessToken,
            StartDate: startDate,
            EndDate: endDate,
        }
    }
    return await axiosInstance.post('/Earning', dataSubmit);
}

export const earningDetail = async (date) => {
    let accessToken = localStorage.getItem('access_token');
    let formData = new FormData()
    formData.append('accessToken', accessToken)
    formData.append('Date', date)
    const result =  await axiosInstance.post('/EarningDetail', formData);
    return result.data
}

export const cashDay = async (date) => {
    let accessToken = localStorage.getItem('access_token');
    let formData = new FormData()
    formData.append('accessToken', accessToken)
    formData.append('date', date)
    const result =  await axiosInstance.post('/cash-out/day', formData);
    return result.data
}

export const cashAll = async () => {
    let accessToken = localStorage.getItem('access_token');
    let formData = new FormData()
    formData.append('accessToken', accessToken)
    const result =  await axiosInstance.post('/cash-out/all', formData);
    return result.data
}