import axiosInstance from '../../axiosInstance';

export const getListCrewByService = async (data) => {
    return await axiosInstance.post('getCrewsListByService', data)
}

export const getListPortfolioOfCrew = async (userId, pageNo= 0) => {
    let accessToken = localStorage.getItem('access_token');
    let data = {
        "method": "getUserPortfolio",
        "body": {
            "accessToken": accessToken,
            "UserId": userId,
            "pageNo": pageNo
        }
    }
    return await axiosInstance.post('/getUserPortfolio', data)
}