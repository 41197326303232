import React, {useState, useEffect} from 'react';
import './index.scss'
import {
  Button,
  DatePicker,
  Form,
  Radio,
  Input,
  Upload,
  Empty,
  Rate,
  Avatar,
  Spin,
  Col,
  Row,
  Modal,
  Select,
  Collapse
} from "antd";
import {getConfigService} from "../../../state/auth/actions";
import moment from "moment";
import {
  CheckOutlined,
  CloudUploadOutlined,
  CheckCircleFilled,
  EnvironmentOutlined,
  LoadingOutlined,
  ProfileOutlined,
  DoubleLeftOutlined,
  CaretRightOutlined,
  PlusOutlined,
  CreditCardFilled,
  DollarOutlined
} from "@ant-design/icons/lib/icons";
import queryString from 'query-string'
import {useLocation} from 'react-router-dom';
import {isEmpty} from "lodash";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {useDispatch, useSelector} from "react-redux";
import {getListCrewByService} from "../../../state/crew/actions";
import {getListBudgets} from "../../../actions/commonActions";
import {bookAFullCrewsAPI, booking} from "../../../state/clients/actions";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import {getListCard} from "../../../state/payment/actions";
import StripeForm from "../../../components/StripeForm";
import {checkAppointment, checkPriceAppointment} from "../../../state/appointment/action";
import ModalShowProfile from "../../../components/Profile";
import ModalCheckAppointment from "../components/CheckAppointment";
import AlertError from "../../../components/Alert/error";
import {PAYMENT_PAID} from "../../../constants";
import MapBooking from "../components/MapBooking";
// const google = window.google;

const settingsSlick = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  // initialSlide: initialSlide,
  // arrows : false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const hoursOption = [
  {
    value: '0.5',
    unit: 'hour(s)',
  },
  {
    value: '1.0',
    unit: 'hour(s)',
  },
  {
    value: '1.5',
    unit: 'hour(s)',
  },
  {
    value: '2.0',
    unit: 'hour(s)',
  },
  {
    value: '2.5',
    unit: 'hour(s)',
  },
  {
    value: '3.0',
    unit: 'hour(s)',
  },
  {
    value: '3.5',
    unit: 'hour(s)',
  },
  {
    value: '4.0',
    unit: 'hour(s)',
  },
  {
    value: '4.5',
    unit: 'hour(s)',
  },
  {
    value: '5.0',
    unit: 'hour(s)',
  },
  {
    value: '5.5',
    unit: 'hour(s)',
  },
  {
    value: '6.0',
    unit: 'hour(s)',
  },
  {
    value: '6.5',
    unit: 'hour(s)',
  },
  {
    value: '7.0',
    unit: 'hour(s)',
  },
  {
    value: '7.5',
    unit: 'hour(s)',
  },
  {
    value: '8.0',
    unit: 'hour(s)',
  },
  {
    value: '8.5',
    unit: 'hour(s)',
  },
  {
    value: '9.0',
    unit: 'hour(s)',
  },
  {
    value: '9.5',
    unit: 'hour(s)',
  },
  {
    value: '10',
    unit: 'hour(s)',
  },
]

const daysOption = [
  {
    value: 1,
    unit: 'day(s)',
  },
  {
    value: 2,
    unit: 'day(s)',
  },
  {
    value: 3,
    unit: 'day(s)',
  },
  {
    value: 4,
    unit: 'day(s)',
  },
  {
    value: 5,
    unit: 'day(s)',
  },
  {
    value: 6,
    unit: 'day(s)',
  },
  {
    value: 7,
    unit: 'day(s)',
  },
]
const Booking = () => {
  const [service, setService] = useState(null)
  const [crew, setCrew] = useState(null)
  const [listCrews, setListCrews] = useState([])
  const [loadingShowCrews, setLoadingShowCrews] = useState(false)
  const {user} = useSelector(state => state.auth)
  const accessToken = localStorage.getItem('access_token')
  const [isBookFullCrews, setIsBookFullCrews] = useState(false)
  const [option, setOption] = useState('hours')
  const [budgetOption, setBudgetOption] = useState([])
  const [form] = Form.useForm();
  const [listAttachment, setListAttachment] = useState([])
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [location, setLocation] = useState(user ? user.Location : '')
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  });
  const [isShowPayment, setIsShowPayment] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [dataSubmit, setDataSubmit] = useState(null)
  const {cards} = useSelector(state => state.payments)
  const [isOpenModalAddCard, setOpenModalAddCard] = useState(false)
  const [isOpenModalConfirmPay, setOpenModalConfirmPay] = useState(false)
  const [subTotal, setSubTotal] = useState(0)
  const [stripeFee, setStripeFee] = useState(0)
  const [totalFee, setTotalFee] = useState(0)
  const [messageSuccess, setMessageSuccess] = useState('Full Crews request sent successfully')
  const [visibleModalShowProfile, setVisibleModalShowProfile] = useState(false)
  const [useSelected, setUserSelected] = useState(null)
  const dispatch = useDispatch()
  const [address, setAddress] = useState('')
  const [coordinatesAddress, setCoordinatesAddress] = React.useState({
    lat: null,
    lng: null
  });
  const [services, setServices] = useState([]);
  const [initialSlide, setInitialSlide] = useState(0)
  const [appointment, setAppointment] = useState(null)
  const [isOpenCheckAppointment, setOpenCheckAppointment] = useState(false)
  const [notBooking, setNotBooking] = useState(false)
  const formatDate = 'MM-DD-YYYY HH:mm'
  const hourCurrent = new Date().getHours() + 1
  let defaultDate = moment(`${hourCurrent}:00:00`, 'HH:mm:ss')
  const {search} = useLocation()
  const values = queryString.parse(search)
  console.log('values', values);
  const appointmentLocal = localStorage.getItem('appointment')

  useEffect(() => {

    const checkPaymentAppointment = async () => {
      const result = await checkAppointment()
      if (!result.data.status) {
        setAppointment(result.data.data)
        setNotBooking(true)
        setOpenCheckAppointment(true)
      }
    }
    checkPaymentAppointment()
  }, [])

  useEffect(() => {
    if (appointmentLocal) {
      let appointment = JSON.parse(appointmentLocal)
      if (appointment) {
        document.getElementById('mainBooking').focus();
        setListCrews([appointment.crew])
        setCrew(appointment.crew)
        setService(appointment.service)
        // setInitialSlide((Math.ceil(appointment.service.serviceId / 4) * 4 ) - 3)
      }
      localStorage.removeItem('appointment')
    }
  }, [appointmentLocal])

  useEffect(() => {
    const listServicesConfig = async () => {
      let result = await getConfigService()
      let lists = result.data.getConfig.data.Services

      let listCategories = ['Camera', 'Lighting', 'Sound', 'Editor', 'Producer']
      lists = lists.filter(item => {
        let bool = false
        for (let i = 0; i < listCategories.length; i++) {
          if ((item.serviceName.toLowerCase()).includes(listCategories[i].toLowerCase())) {
            bool = true
          }
        }
        return bool
      })
      if (!isEmpty(values) && values.serviceId && values.crewId) {
        const newService = lists.find(service => service.serviceId == values.serviceId);
        await getListCrews(newService, values.crewId);
      }
      setServices(lists)
    }
    listServicesConfig()
  }, [])

  useEffect(() => {
    const listBudgetConfig = async () => {
      let result = await getListBudgets()
      setBudgetOption(result.data.getBudgetList.data)
    }
    listBudgetConfig()
  }, [])

  const getListCrews = async (service, crewId = "") => {
    setService(service)
    setInitialSlide((Math.ceil(service.serviceId / 4) * 4) - 3)
    setCrew(null)
    setLoadingShowCrews(true);
    let data = {
      "body": {
        "Latitude": coordinates.lat ? parseFloat(coordinates.lat) : parseFloat(user.Latitude),
        "Longitude": coordinates.lng ? parseFloat(coordinates.lng) : parseFloat(user.Longitude),
        "pageNo": "0",
        "perPage": 20,
        "serviceId": service.serviceId,
        "accessToken": accessToken,
        "levelType": parseInt(service.LevelType),
      },
      "method": "getCrewsListByService"
    }
    const result = await getListCrewByService(data)
    let listCrews = result.data.getCrewsListByService.data;
    if (crewId) {
      const newCrew = listCrews.find(crew => crew.UserId == crewId);
      setCrew(newCrew)
    }
    setListCrews(listCrews)
    setLoadingShowCrews(false);
  }

  const renderListServices = () => {
    return services.map((d) =>
      <div key={d.serviceId} className={`item ${service && service.serviceId === d.serviceId && 'selected'}`} onClick={() => getListCrews(d)}>
        <div className='thumb-item'>
          <img src={d.image} width={40} alt=""/>
        </div>
        <div className='info-item'>
          <p>{d.serviceName}</p>
          {/*<p>{d.servicePrice} <span>$</span></p>*/}
        </div>
        {service && service.serviceId === d.serviceId && <CheckCircleFilled/>}
      </div>
    )
  }

  const renderListCrews = () => {
    return listCrews.map(item =>
      <Col key={item.UserId} className="wp-info-crew" xs={{span: 24}} sm={{span: 24}} md={{span: 12}} onClick={() => setCrew(item)}>
        <div className={`info-crew ${crew && crew.UserId === item.UserId ? 'selected' : ''}`}>
          <div className="info">
            <div className="thumb">
              <Avatar onClick={() => onSelectedUser(item.UserId)} size={48}
                      src={item.ProfilePic ? item.ProfilePic : `https://orbis-alliance.com/wp-content/themes/consultix/images/no-image-found-360x260.png`}/>
              {/*<p className="price-service">${item.serviceMaxPrice} </p>*/}
              {/*<p>( per/hour )</p>*/}
            </div>
            <div className="detail">
              <div className="name-rate">
                <p onClick={() => onSelectedUser(item.UserId)} className="name">{item.FirstName} {item.LastName}</p>
                <div className="rate">
                  <Rate allowHalf disabled defaultValue={item.ratings}/>
                </div>
              </div>
              <p className="name-service"><ProfileOutlined/>{item.serviceName}</p>
              <p className="location"><EnvironmentOutlined/>{item.Location}</p>
              <p className="location"><DollarOutlined/>${parseFloat(item.serviceMaxPrice).toFixed(2)} <span>( per/hour )</span></p>
            </div>
          </div>

          {crew && crew.UserId === item.UserId && <CheckCircleFilled className='icon-check'/>}
        </div>
      </Col>
    )
  }

  const normFile = (e) => {
    let files = e.fileList.map(item => item.originFileObj)
    setListAttachment(files)

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const confirmNeedFullCrews = () => {
    Modal.confirm({
      title: 'Need a Full Crew?',
      content: 'Our team will vet a team of professionals and contact you shortly to book your crew.',
      okText: 'Send Request',
      cancelText: 'Cancel',
      onOk() {
        setStartDate(null)
        setEndDate(null)
        setIsBookFullCrews(true)
        form.resetFields()
        setListCrews([])
        setCrew(null)
        setService(null)
      }
    });
  }

  const renderOptionBudget = () => {
    return budgetOption.map(item =>
      <Select.Option key={item.budgetID} value={item.budgetID}>{item.budget}</Select.Option>
    )
  }

  const renderOptionHours = () => {
    return hoursOption.map(item =>
      <Select.Option key={item.value} value={item.value}>{item.value} {item.unit}</Select.Option>
    )
  }

  const renderOptionDays = () => {
    return daysOption.map(item =>
      <Select.Option key={item.value} value={item.value}>{item.value} {item.unit}</Select.Option>
    )
  }

  const onChangeOption = (e) => {
    form.setFieldsValue({estimate: null});
    setOption(e.target.value)
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');

  }

  function disabledTime(current) {
    const MIN_MINUTES = 15
    let d = new Date()
    let hour = d.getHours()
    // let minutes = d.getMinutes() + MIN_MINUTES
    let arrayHour = []
    let arrayMinute = Array.from({length: 60}, (_, i) => i).filter(item => item % MIN_MINUTES !== 0)
    if (moment(current).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
      arrayHour = Array.from({length: 23}, (_, i) => i).filter(item => item < hour)
      if (new Date(current).getHours() === hour) {
        let arrayAdd = Array.from({length: new Date().getMinutes()}, (_, i) => i)
        arrayMinute = arrayMinute.concat(arrayAdd)
      }
    }

    return {
      disabledHours: () => arrayHour,
      disabledMinutes: () => arrayMinute
    };
  }

  const handleSelectStartDate = (date, dateString) => {
    if (dateString) {
      let dateFormat = moment(dateString).format('YYYY-MM-DD HH:mm')
      setStartDate(dateFormat)
    } else {
      setStartDate(null)
    }
  }

  const handleSelectEndDate = (date, dateString) => {
    if (dateString) {
      let dateFormat = moment(dateString).format('YYYY-MM-DD HH:mm')
      setEndDate(dateFormat)

    } else {
      setEndDate(null)
    }
  }

  const bookingFullCrews = async (values) => {
    try {
      if (!startDate) {
        return AlertError('Please input start date appointment!')
      }
      if (!endDate) {
        return AlertError('Please input end date appointment!')
      }
      if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
        return AlertError('Start date must be less than end date!')
      }
      if (!values.budget) {
        return AlertError('Please select your budget!')
      }
      setLoadingSubmit(true);
      let formData = new FormData();
      let dataSubmit = {
        ClientId: user.UserId,
        accessToken: accessToken,
        SelectedDate: startDate,
        StartTime: startDate,
        EndTime: endDate,
        Budget: values.budget,
        Notes: values.note,
        Latitude: user.Latitude,
        Longitude: user.Longitude,
      }
      let listTypeImage = ['image/jpeg', 'image/gif', 'image/png']
      let listTypeDocs = [
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint'
      ]
      Object.keys(dataSubmit).forEach(item => formData.append(item, dataSubmit[item]))
      let listFileImage = listAttachment.filter(item => listTypeImage.includes(item.type))
      let listFileDocs = listAttachment.filter(item => listTypeDocs.includes(item.type))
      Object.keys(listFileImage).forEach((key, i) => {
        formData.append('Image[]', listFileImage[key]);
      });
      Object.keys(listFileDocs).forEach((key, i) => {
        formData.append('File[]', listFileDocs[key]);
      });
      let response = await bookAFullCrewsAPI(formData)
      if (response.data.status) {
        setIsSuccess(true)
        setLoadingSubmit(false);
      } else {
        AlertError(response.data.Message)
        setLoadingSubmit(false);
      }
    } catch (e) {
      setLoadingSubmit(false);
      AlertError(e)
    }
  }

  const handleSelectLocation = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setLocation(value);
    setCoordinates(latLng);
  };

  const handleSelectAddress = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinatesAddress(latLng);
  };

  const confirmAppointment = async (values) => {
    if (notBooking) {
      return Modal.warning({
        content: 'Your previous appointment payment is pending. Please make a payment so that you can book other appointments!',
      });
    }
    if (!service) {
      return AlertError('Please select the service!')
    }
    if (!crew) {
      return AlertError('Please select the crew you want to appointment!')
    }
    if (!startDate) {
      return AlertError('Please input start date appointment!')
    }
    if (!values.estimate) {
      return AlertError('Please select your estimate time!')
    }
    if (service.FinalLengthDesired.length > 0 && !values.FinalLengthDesired) {
      return AlertError('Please select final file length desired!')
    }
    if (!address) {
      return AlertError('Please enter the address where you want the crew to work!')
    }
    // if(!values.offer){
    //       return AlertError('Please select your offer!')
    // }

    let valueDays = values.optionDate === 'days' ? values.estimate : '';
    let valueHours = values.optionDate === 'hours' ? values.estimate : '';

    if (values.offer) {
      if ((valueHours && parseFloat(values.offer) < parseFloat(crew.serviceMinPrice)) || (valueDays && parseFloat(values.offer) < parseFloat(crew.dayMinPrice))) {
        return Modal.warning({
          content: 'The price you offered is too low so the crew will not accept it. PLease offer a higher price.',
        });
      }
    }
    let offer = 0
    if (valueDays) {
      offer = values.offer ? values.offer : crew.dayMaxPrice
    }
    if (valueHours) {
      offer = values.offer ? values.offer : crew.serviceMaxPrice
    }

    setLoadingSubmit(true);
    let dataSubmit = {
      ClientId: user.UserId,
      accessToken: accessToken,
      CrewsId: crew.UserId,
      AppointmentType: 'Direct',
      SelectedDate: startDate,
      StartTime: startDate,
      Days: valueDays,
      Hours: valueHours,
      ServiceType: service.ServiceType,
      OfferPrice: offer,
      serviceId: service.serviceId,
      serviceName: service.serviceName,
      servicePrice: service.servicePrice,
      levelType: service.LevelType,
      Location: address,
      Latitude: coordinatesAddress.lat,
      Longitude: coordinatesAddress.lng,
      Notes: values.note ? values.note : '',
      FileTypeEdit: values.FileTypeEdit ? values.FileTypeEdit : '',
      FinalLengthDesired: values.FinalLengthDesired ? values.FinalLengthDesired : '',
      OutputType: values.OutputType ? values.OutputType : '',
      MasterFilesNeeded: values.MasterFilesNeeded ? values.MasterFilesNeeded : '',
      ViewingOutlet: values.ViewingOutlet ? values.ViewingOutlet : '',
      LengthOfSource: values.LengthOfSource ? values.LengthOfSource : '',
    }
    setDataSubmit(dataSubmit)
    await dispatch(getListCard())
    setLoadingSubmit(false)
    setIsShowPayment(true)
  }

  const renderListCreditCard = () => {
    return cards.map(item =>
      <Radio.Button key={item.id} value={item.id}>
        <div className='list-cards'>
          <div>
            <CreditCardFilled className='card-icon'/>
            <p>**** **** **** {item.last4}</p>
            {paymentMethod === item.id ? <CheckCircleFilled/> : null}
          </div>
        </div>
      </Radio.Button>
    )
  }

  const closeModalBookSuccess = () => {
    form.resetFields();
    setStartDate(null)
    setEndDate(null)
    setIsSuccess(false)
    setOpenModalConfirmPay(false)
    setIsShowPayment(false)
    setLocation('')
    setOption('hours')
    setListAttachment([])
    setAddress('')
    // setTouch(false)
    setInitialSlide(0)
    setCrew(null)
    setService(null)
    setListCrews([])
  }

  const onChangeCard = e => {
    setPaymentMethod(e.target.value);
  };

  const closeModalCreateCard = () => {
    setOpenModalAddCard(false)
  }

  const confirmPay = async () => {
    setLoadingSubmit(true)
    if (!paymentMethod) {
      setLoadingSubmit(false);
      return AlertError('Please add card payment!')
    }
    const dataSubmitPay = dataSubmit;
    dataSubmitPay.cardID = paymentMethod;
    setDataSubmit(dataSubmitPay)
    let formData = new FormData();
    let dataCheckPrice = {
      CrewsId: dataSubmit.CrewsId,
      SelectedDate: dataSubmit.SelectedDate,
      Days: dataSubmit.Days,
      Hours: dataSubmit.Hours,
      ServiceType: dataSubmit.ServiceType,
      StartTime: dataSubmit.StartTime,
      OfferPrice: dataSubmit.OfferPrice,
      serviceId: dataSubmit.serviceId,
      levelType: dataSubmit.levelType,
    }
    Object.keys(dataCheckPrice).forEach(item => formData.append(item, dataCheckPrice[item]))
    const resultCheckPrice = await checkPriceAppointment(dataCheckPrice)
    if (resultCheckPrice.data.status === true) {
      setSubTotal(resultCheckPrice.data.data.sub_total)
      setStripeFee(resultCheckPrice.data.data.stripe_fee)
      setTotalFee(resultCheckPrice.data.data.total)
      setOpenModalConfirmPay(true)
      setLoadingSubmit(false)
    } else {
      setLoadingSubmit(false)
      return AlertError(resultCheckPrice.data.Message)
    }

  }

  const payNow = async () => {
    setLoadingSubmit(true)
    let formData = new FormData();
    Object.keys(dataSubmit).forEach(item => formData.append(item, dataSubmit[item]))
    let listTypeImage = ['image/jpeg', 'image/gif', 'image/png']
    let listTypeDocs = [
      'application/pdf',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint'
    ]
    let listTypeVideo = ['video/mp4', 'video/x-msvideo']
    let listFileImage = listAttachment.filter(item => listTypeImage.includes(item.type))
    let listFileDocs = listAttachment.filter(item => listTypeDocs.includes(item.type))
    let listFileVideos = listAttachment.filter(item => listTypeVideo.includes(item.type))
    Object.keys(listFileImage).forEach((key, i) => {
      formData.append('AttachmentImage[]', listFileImage[key]);
    });
    Object.keys(listFileDocs).forEach((key, i) => {
      formData.append('AttachmentDoc[]', listFileDocs[key]);
    });
    Object.keys(listFileVideos).forEach((key, i) => {
      formData.append('AttachmentVideo[]', listFileVideos[key]);
    });
    let resultAppointment = await booking(formData)
    if (resultAppointment.data.status) {
      setIsSuccess(true)
      setMessageSuccess(resultAppointment.data.Message)
      setLoadingSubmit(false)
    } else {
      setLoadingSubmit(false)
      return AlertError(resultAppointment.data.Message)
    }
  }

  const onSelectedUser = userId => {
    setUserSelected(userId)
    setVisibleModalShowProfile(true)
  }

  const closeModalShowProfile = () => {
    setVisibleModalShowProfile(false)
    setUserSelected(null)
  }
  const closeModalCheckAppointment = (isPay) => {
    if (isPay === PAYMENT_PAID) {
      setNotBooking(false)
    }
    setOpenCheckAppointment(false)
    setAppointment(null)
    // history.push('/history')
  }

  return (
    <div className="booking-page page-children" style={{margin: "0 auto"}}>
      {!isBookFullCrews && !isShowPayment &&
        <div className="booking">
          <div className='map-appointment'>
            <PlacesAutocomplete value={location}
                                onChange={value => {
                                  setLocation(value)
                                  setService(null)
                                  setListCrews([])
                                  setCrew(null)
                                  setInitialSlide(0)
                                }}
                                searchOptions={{
                                  // types: ['address'],
                                  componentRestrictions: {country: ['us']},
                                }}
                                onSelect={handleSelectLocation}
            >

              {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                <div>
                  <Input className='input-search-gg' prefix={
                    <EnvironmentOutlined/>}  {...getInputProps({placeholder: 'What is your location ?'})} />
                  <div>
                    {loading ? <div>...loading</div> : null}
                    {suggestions.map(suggestion => {
                      const style = {
                        backgroundColor: suggestion.active ? '#08ffc8' : '#fff',
                        padding: '10px'
                      }

                      return (
                        <div
                          key={suggestion.placeId} {...getSuggestionItemProps(suggestion, {style})}>
                                                                  <span
                                                                    key={suggestion.placeId}>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <MapBooking lat={coordinates.lat ? coordinates.lat : parseFloat(user.Latitude)} lng={coordinates.lng ? coordinates.lng : parseFloat(user.Longitude)}
                        address={location ? location : user.Location} onSelectedUser={onSelectedUser}/>
          </div>
          <div className='full-crews'>
            <Button onClick={confirmNeedFullCrews}>Need a Full Crew?</Button>
          </div>

          <Form
            form={form}
            name="booking"
            layout="vertical"
            onFinish={confirmAppointment}
            initialValues={{
              optionDate: 'hours',
            }}
          >
            <div className="wp-equipment p-3">
              <div className='services'>
                <p className='title'>Select Services</p>
                <Form.Item name='service'>
                  <Slider {...settingsSlick} initialSlide={initialSlide} className='list-services-config'>
                    {renderListServices()}
                  </Slider>
                </Form.Item>
              </div>
              <div className='list-crews'>
                <p className='title'>List Crews</p>
                {!loadingShowCrews ?
                  <Form.Item name='crewId'>
                    {listCrews.length ?
                      <Row className='list' gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                        {renderListCrews()}
                      </Row>
                      :
                      <div className='empty-data'>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                      </div>
                    }
                  </Form.Item>
                  :
                  <div className="show-loading">
                    <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
                  </div>
                }

              </div>
              <div className='date-time' tabIndex="0" id="mainBooking">
                <p className='title'>Select Date</p>
                <div className='wp-inner'>
                  <div>
                    <Form.Item name='date-start'
                      // rules={[
                      //       {
                      //             required: true,
                      //             message: 'Please input end date appointment!',
                      //       },
                      // ]}
                    >
                      <DatePicker
                        onChange={handleSelectStartDate}
                        format={formatDate}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: defaultDate
                        }}
                        showNow={false}
                        placeholder='Select date'/>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className='equipment'>
                <p className='title'>Option</p>
                <Form.Item name='optionDate' className='option-select-date-appointment'>
                  <Radio.Group onChange={onChangeOption}>
                    <Radio value='hours'>Hours</Radio>
                    <Radio value='days'>Days</Radio>
                  </Radio.Group>
                </Form.Item>
                {option === 'days' && <p className='note-select-option'>Notes: Maximum 10 hour per day.</p>}
              </div>
              <div className="budget">
                <Form.Item
                  name="estimate"
                  // rules={[{required: true, message: 'Please select your estimate time!'}]}
                >
                  <Select
                    placeholder="Select your estimate time"
                  >
                    {option === 'hours' ? renderOptionHours() : renderOptionDays()}
                  </Select>
                </Form.Item>
              </div>

              {service?.ViewingOutlet.length > 0 &&
                <div className="note">
                  <p className='title'>Requirements</p>

                  <Form.Item name="ViewingOutlet">
                    <Select
                      placeholder="Viewing outlet"
                    >
                      {service.ViewingOutlet.map(item =>
                        <Select.Option key={item} value={item}>{item}</Select.Option>
                      )}
                    </Select>
                  </Form.Item>

                  {service?.FileTypeEdit.length > 0 &&
                    <Form.Item name="FileTypeEdit">
                      <Select
                        placeholder="File type that need to be edited"
                      >
                        {service.FileTypeEdit.map(item =>
                          <Select.Option key={item} value={item}>{item}</Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  }

                  {service?.LengthOfSource.length > 0 &&
                    <Form.Item name="LengthOfSource">
                      <Select
                        placeholder="Length of source material"
                      >
                        {service.LengthOfSource.map(item =>
                          <Select.Option key={item} value={item}>{item}</Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  }

                  {service?.FinalLengthDesired.length > 0 &&
                    <Form.Item name="FinalLengthDesired">
                      <Select
                        placeholder="Final file length desired?"
                      >
                        {service.FinalLengthDesired.map(item =>
                          <Select.Option key={item} value={item}>{item}</Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  }

                  {service?.OutputType.length > 0 &&
                    <Form.Item name="OutputType">
                      <Select
                        placeholder="Output file type?"
                      >
                        {service.OutputType.map(item =>
                          <Select.Option key={item} value={item}>{item}</Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  }

                  {service?.MasterFilesNeeded.length > 0 &&
                    <Form.Item name="MasterFilesNeeded">
                      <Select
                        placeholder="Master files needed?"
                      >
                        {service.MasterFilesNeeded.map(item =>
                          <Select.Option key={item} value={item}>{item}</Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  }

                </div>
              }


              <div className="note">
                <p className='title'>Note <span>(Optional)</span></p>
                <Form.Item
                  name="note"
                >
                  <Input.TextArea
                    placeholder="Enter note"
                    autoSize={{minRows: 3, maxRows: 5}}
                  />
                </Form.Item>
              </div>

              <div className="upload">
                <p className='title'>Upload file <span>(Optional)</span></p>
                <Form.Item
                  name="file"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload name="logo"
                          accept=".jpg, .jpeg, .png, .pdf, .doc, .xls, .xlsx, .ppt"
                          action={process.env.REACT_APP_URL}
                    // action="http://crew.kendemo.com"
                    //     action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture"
                  >
                    <Button className='btn-upload-file'
                            icon={<CloudUploadOutlined/>}>Upload</Button>
                  </Upload>
                </Form.Item>
              </div>

              <div className="note">
                <p className='title'>Selected Address</p>
                <Form.Item
                  className="w-100"
                  // rules={[{required: true, message: 'Please input your location!'}]}
                >
                  <PlacesAutocomplete value={address}
                                      onChange={value => {
                                        setAddress(value)
                                        // setTouch(true)
                                      }}
                                      searchOptions={{
                                        types: ['address'],
                                        componentRestrictions: {country: 'us'},
                                      }}
                                      onSelect={handleSelectAddress}
                  >

                    {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                      <div>
                        <Input  {...getInputProps({placeholder: 'Address'})} />
                        <div>
                          {loading ? <div>...loading</div> : null}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            const style = {
                              backgroundColor: suggestion.active ? '#08ffc8' : '#fff', padding: '10px'
                            }
                            return (
                              <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, {style, className})}>
                                <span key={suggestion.placeId}>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {/*{address === '' && touch &&*/}
                  {/*      <div className="ant-form-item-explain ant-form-item-explain-error" >*/}
                  {/*            <div role="alert">Please enter the address!</div>*/}
                  {/*      </div>*/}
                  {/*}*/}
                </Form.Item>
              </div>
              <div className="note w-100">
                <p className='title'>Enter Your Offer</p>
                <div className="description">
                  <p>Current price: ${crew ? option === 'hours' ? (parseInt(crew.serviceMaxPrice).toFixed(2)) : (parseInt(crew.dayMaxPrice).toFixed(2)) : '0.00'}
                    <span>{option === 'hours' ? `( per/hour )` : '( per/day )'}</span></p>
                  <p>If you want to send an offer please enter here</p>
                </div>
                <Form.Item
                  className="w-100"
                  name="offer"
                >
                  <Input placeholder="Offer" className='w-100'/>
                </Form.Item>
              </div>
            </div>
            <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
              <Button loading={loadingSubmit} htmlType='submit' className="w-50"
                      id="btn-confirm-appointment">
                Confirm Appointment
              </Button>
            </Form.Item>
          </Form>
        </div>
      }
      {isShowPayment && Object.keys(dataSubmit).length > 0 &&
        <div className='wp-payment'>
          <div className="head-payment">
            <Button className='back' onClick={() => setIsShowPayment(false)}><DoubleLeftOutlined/>Back</Button>
          </div>
          <Form
            form={form}
            name="booking"
            onFinish={confirmPay}
          >
            <div className='payment'>
              <div className='details'>
                <p className='title'>Payment Amount</p>
                <div>
                  <p className='amount'>${dataSubmit.Days ? ((dataSubmit.OfferPrice * dataSubmit.Days).toFixed(2)) : ((dataSubmit.OfferPrice * dataSubmit.Hours).toFixed(2))}</p>
                  <Collapse
                    bordered={false}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    className="site-collapse-custom-collapse detail-service"
                  >
                    <Collapse.Panel header="Detail service" key="1" className="site-collapse-custom-panel">
                      <div>
                        <p className='title'>Service</p>
                        <p>{dataSubmit.serviceName}</p>
                      </div>
                      <div>
                        <p className='title'>{dataSubmit.Days ? 'Day(s)' : 'Hour(s)'}</p>
                        <p>{dataSubmit.Days ? dataSubmit.Days : dataSubmit.Hours}</p>
                      </div>
                      <div>
                        <p className='title'>Offer</p>
                        <p>${parseFloat(dataSubmit.OfferPrice).toFixed(2)}</p>
                      </div>

                    </Collapse.Panel>
                  </Collapse>,
                </div>
              </div>
              <div className="payment-method">
                <p className='title'>Payment Method</p>
                <div>
                  {cards.length > 0 &&
                    <Form.Item name="payment_method">
                      <Radio.Group onChange={onChangeCard}>
                        {renderListCreditCard()}
                      </Radio.Group>
                    </Form.Item>
                  }
                  <div className='add-more-payment'>
                    <Button onClick={() => setOpenModalAddCard(true)} icon={<PlusOutlined/>}>
                      Add New Card
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
              <Button loading={loadingSubmit} htmlType="submit" className="w-50" id="btn-payment-now">
                Confirm pay
              </Button>
            </Form.Item>
          </Form>

          {isOpenModalAddCard && <StripeForm visible={isOpenModalAddCard} closeModalCreateCard={closeModalCreateCard}/>}
        </div>
      }
      {isBookFullCrews && !isShowPayment &&
        <div className="book-full-crews">
          <div className="head-booking">
            <p className="title">Send Request</p>
            <Button onClick={() => setIsBookFullCrews(false)}><DoubleLeftOutlined/>Back</Button>
          </div>
          <Form
            form={form}
            name="booking-full-crews"
            onFinish={bookingFullCrews}
          >
            <div className="wp-equipment p-3">
              <div className='date-time'>
                <p className='title'>Select Time</p>
                <div className='wp-inner'>
                  <div>
                    <Form.Item name='date-start'
                      // rules={[{required: true, message: 'Please input start date appointment!',}]}
                    >
                      <DatePicker
                        onChange={handleSelectStartDate}
                        format={formatDate}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        showTime
                        showNow={false}
                        placeholder='Select start date'/>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item name='date-end'
                      // rules={[{required: true,message: 'Please input end date appointment!', },]}
                    >
                      <DatePicker
                        onChange={handleSelectEndDate}
                        format={formatDate}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        showTime
                        showNow={false}
                        placeholder='Select end date'/>
                    </Form.Item>
                  </div>

                </div>
              </div>

              <div className="budget">
                <p className='title'>Budget</p>
                <Form.Item
                  name="budget"
                  // rules={[{ required: true , message: 'Please select your budget!' }]}
                >
                  <Select
                    placeholder="Select your budget"
                  >
                    {renderOptionBudget()}
                  </Select>
                </Form.Item>
              </div>

              <div className="note">
                <p className='title'>Note <span>(Optional)</span></p>
                <Form.Item
                  name="note"
                >
                  <Input.TextArea
                    placeholder="Enter note"
                    autoSize={{minRows: 3, maxRows: 5}}
                  />
                </Form.Item>
              </div>

              <div className="upload">
                <p className='title'>Attachment <span>(Optional)</span></p>
                <Form.Item
                  name="file"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload name="logo"
                          accept=".jpg, .jpeg, .png, .pdf, .doc, .xls, .xlsx, .ppt"
                          action={process.env.REACT_APP_URL}
                    //     action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture"
                  >
                    <Button className='btn-upload-file'
                            icon={<CloudUploadOutlined/>}>Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
            <Form.Item style={{margin: '0 auto', textAlign: 'center'}} className="w-100">
              <Button loading={loadingSubmit} htmlType="submit" className="w-50" id="btn-confirm-appointment">
                Send Job Request
              </Button>
            </Form.Item>
          </Form>
        </div>
      }

      <div>
        <Modal
          footer={null}
          visible={isOpenModalConfirmPay}
          maskClosable={false}
          closable={false}
          centered
          className='modal-confirm-pay-appointment'
          width={600}
        >
          <div className="body-modal">
            <div className="title-modal">
              <p>Confirm the price to pay</p>
            </div>
            <div className="content-pay">
              <div>
                <div className="title">
                  <p>Booking fee</p>
                </div>
                <div className="value">
                  <p>${subTotal}</p>
                </div>
              </div>
              <div>
                <div className="title">
                  <p>Stripe fee</p>
                </div>
                <div className="value">
                  <p>${stripeFee}</p>
                </div>
              </div>
              <div>
                <div className="title">
                  <p>Total</p>
                </div>
                <div className="value">
                  <p>${totalFee}</p>
                </div>
              </div>
            </div>
            <div className="footer-modal">
              <Button className='cancel-btn' onClick={() => setOpenModalConfirmPay(false)}>Cancel</Button>
              <Button loading={loadingSubmit} className='pay-btn' onClick={payNow}>Pay now</Button>
            </div>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          footer={null}
          visible={isSuccess}
          maskClosable={false}
          closable={false}
          centered
          className='modal-booking-success'
        >
          <div className="body-modal">
            <CheckOutlined/>
            <p>{messageSuccess}</p>
            <Button onClick={closeModalBookSuccess}>Close</Button>
          </div>
        </Modal>
      </div>
      {visibleModalShowProfile && useSelected && <ModalShowProfile idUser={useSelected} visible={visibleModalShowProfile} closeModal={closeModalShowProfile}/>}
      {isOpenCheckAppointment && appointment && <ModalCheckAppointment visible={isOpenCheckAppointment} appointment={appointment} closeModal={closeModalCheckAppointment}/>}

    </div>
  );
};

export default Booking;
