import React, {useState} from 'react';
import {Button, Modal} from "antd";
import './index.scss'
import {formatCurrency} from "../../../../utils/help";
import {cashAll, cashDay} from "../../../../state/payment/actions";
import ModalResultSuccess from "../../../../components/Result";
import AlertError from "../../../../components/Alert/error";

const CashOut = ({visible , amount, type, date, closeModal}) => {
      const [loading, setLoading] = useState(false)
      const [isOpenModalResult, setOpenModalResult] = useState(false)
      const [messageSuccess, setMessageSuccess] = useState(null)

      const cashOut = async () => {
            setLoading(true)
            if(type === 'day'){
                  const result = await cashDay(date)
                  if(result.status){
                        setMessageSuccess(result.Message)
                        setOpenModalResult(true)
                        setLoading(false)
                  } else{
                        setLoading(false)
                        AlertError(result.Message)
                  }
            }
            if(type === 'all'){
                  const result = await cashAll()
                  if(result.status){
                        setMessageSuccess(result.Message)
                        setOpenModalResult(true)
                        setLoading(false)
                  } else{
                        setLoading(false)
                        AlertError(result.Message)
                  }
            }
      }

      const closeModalSuccess = () => {
            closeModal(true)
      }

      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-cash-out'
                >
                      <div className="body-modal">
                            <div className="content">
                                  <h5>Crews now</h5>
                                  <p>Cash out: <span>{(formatCurrency().format(amount))}</span></p>
                            </div>
                            <div className="footer-modal">
                                  <Button onClick={() => closeModal(false)}>Cancel</Button>
                                  <Button loading={loading} onClick={cashOut}>Submit</Button>
                            </div>
                      </div>
                </Modal>
                {isOpenModalResult && <ModalResultSuccess isSuccess={isOpenModalResult} messageSuccess={messageSuccess} closeModalSuccess={closeModalSuccess} />}
          </div>
      );
}

export default CashOut;