import React, {useEffect, useState} from 'react';
import './index.scss'
import {Avatar, Button, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import EmptyData from "../../components/Empty";
import {listNotifications} from "../../state/notifications/action";
import {useHistory} from "react-router-dom";
import {
    TYPE_APPOINTMENT_ACCEPT,
    TYPE_APPOINTMENT_ACCEPT_REQUEST,
    TYPE_APPOINTMENT_BOOK, TYPE_APPOINTMENT_CANCEL, TYPE_APPOINTMENT_COMPLETE, TYPE_APPOINTMENT_EDIT,
    TYPE_INVITE_ACCEPT,
    TYPE_INVITE_SEND
} from "../../constants";

const Notifications = (props) => {
    const {notifications} = useSelector(state => state.notifications)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const loadNotifications = async () => {
            if(page !== 0){
                setLoading(true)
            }
            let result = await dispatch(listNotifications(page))
            if(result.payload.notificatonList.rowcount){
                setTotalRows(result.payload.notificatonList.rowcount)
            }
            setLoading(false)
        }
        loadNotifications()
    },[page, dispatch])
    // const menu = (
    //     <Menu>
    //         <Menu.Item key="0">
    //             <a href="https://www.aliyun.com"><CheckOutlined />Mark as Read</a>
    //         </Menu.Item>
    //         <Menu.Item key="1">
    //             <a href="https://www.antgroup.com"><CloseOutlined />Remove this notification</a>
    //         </Menu.Item>
    //     </Menu>
    // );
    const renderItemNotifications = () => {
        return notifications.map((item) =>
            <div className="item" key={item.NotificationId}>
                <div className='content'>
                    <div className="thumb">
                        <Avatar size={40} src={item.ProfilePic && item.ProfilePic !== '' ? item.ProfilePic : `https://orbis-alliance.com/wp-content/themes/consultix/images/no-image-found-360x260.png`} />
                    </div>
                    <div className="description">
                        <p className="title" onClick={() => readNotification(item)}>{item.message}</p>
                        <p className='time'>{item.NotificationTime}</p>
                    </div>
                </div>
                {/*<div className="action">*/}
                {/*    <Dropdown overlayClassName='dropdown-action-notification' overlay={menu} trigger={['click']}>*/}
                {/*        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
                {/*            <DashOutlined />*/}
                {/*        </a>*/}
                {/*    </Dropdown>*/}
                {/*</div>*/}
            </div>
        );
    }

    const readNotification = async (item) => {
        let typeNotify = item.ActionType
        if(typeNotify === TYPE_INVITE_SEND){
            let notificationData = {
                tabActive : 'invites'
            }
            await localStorage.setItem('notificationData', JSON.stringify(notificationData));
            return history.push('/connection')
        }
        if(typeNotify === TYPE_INVITE_ACCEPT){
            return history.push('/connection')
        }
        const listTypeSeeDetailInAppointmentPage = [
            TYPE_APPOINTMENT_BOOK ,
            TYPE_APPOINTMENT_ACCEPT ,
            TYPE_APPOINTMENT_ACCEPT_REQUEST,
            TYPE_APPOINTMENT_EDIT
        ]
        if(listTypeSeeDetailInAppointmentPage.includes(typeNotify)){
            let notificationData = {
                appointmentId : item.appointmentId,
                seeDetail : true
            }
            await localStorage.setItem('notificationData', JSON.stringify(notificationData));
            return history.push('/appointment')
        }
        const listTypeSeeDetailInHistoryPage = [
            TYPE_APPOINTMENT_CANCEL ,
            TYPE_APPOINTMENT_COMPLETE
        ]
        if(listTypeSeeDetailInHistoryPage.includes(typeNotify)){
            let notificationData = {
                appointmentId : item.appointmentId,
                seeDetail : true
            }
            await localStorage.setItem('notificationData', JSON.stringify(notificationData));
            return history.push('/history')
        }
    }


    return (
        <div className='notification-page page-children h-100'>
            <div className="notification">
                <h6 className="title">Notifications</h6>
                <div className="list-notification">
                    {notifications.length > 0 ?
                        <React.Fragment>
                            {renderItemNotifications()}
                            <div className='load-more-data'>
                                {loading ?
                                    <Spin className='spin-load-more-data'/>
                                    :
                                    notifications.length < totalRows &&
                                    <Button className="btn-load-more" onClick={() => setPage(page + 1)}>Load more</Button>
                                }
                            </div>
                        </React.Fragment>
                    :
                        <EmptyData/>
                    }
                </div>

            </div>
        </div>
    );
}

export default Notifications;