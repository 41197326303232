import axiosInstance from '../../axiosInstance'

export const LOGIN = 'auth/LOGIN';
export const LOGOUT = 'auth/CLEAR_USER';
export const LOGIN_SOCIAL = 'auth/LOGIN_SOCIAL';
export const AUTH_USER = 'auth/AUTH_USER';
export const GET_LIST_PORTFOLIO = 'auth/GET_LIST_PORTFOLIO';
export const DELETE_PORTFOLIO = 'auth/DELETE_PORTFOLIO';
export const UPDATE_SETTING = 'auth/UPDATE_SETTING';

export const login = dataToSubmit => {
    let data = {
        "method": "checkLogin",
        "body": {
            "Email": dataToSubmit.username,
            "Password": dataToSubmit.password,
            "NotificationToken": dataToSubmit.token,
            "Device_UID": "",
            "DeviceName": "",
            "DeviceType": "",
            "UserType": "",
            "OSVersion": ""
        }
    }
    const request = axiosInstance
        .post('/checkLogin', data)
        .then(res => {
            return res.data;
        }).catch(err => err);
    return {
        type: LOGIN,
        payload: request
    };
};
export function setToken(token, userId) {
    return dispatch => {
        setStorage('access_token', token, parseInt(60 * 60 * 6));
        setStorage('userId', userId, parseInt(60 * 60 * 6));
    }
}
export function setStorage(key, value, expires) {

    if (expires === undefined || expires === null) {
        expires = (24 * 60 * 60);  // default: seconds for 1 day
    } else {
        expires = Math.abs(expires); //make sure it's positive
    }
    let now = Date.now();  //millisecs since epoch time, lets deal only with integer
    let schedule = now + expires * 1000;
    try {
        localStorage.setItem(key, value);
        localStorage.setItem(key + '_expiresIn', schedule);
    } catch (e) {
        console.log('setStorage: Error setting key [' + key + '] in localStorage: ' + JSON.stringify(e));
        return false;
    }
    return true;
}
export const loginSocialFacebook = async (dataToSubmit) => {
    let data = {
        "body": {
            "DeviceName": "Web",
            "DeviceType": "Web",
            "Device_UID": "93f69493ded2d995",
            "Email": dataToSubmit.email,
            "FirstName": dataToSubmit.name,
            "LastName": dataToSubmit.name,
            "LoginType": "facebook",
            "Mobile": "",
            "NotificationToken": dataToSubmit.token ? dataToSubmit.token : '',
            "OSVersion": "24",
            "SocialLoginID": dataToSubmit.id,
            "UserName": ""
        },
        "method": "socialLogin"
    }

    const request =  await axiosInstance.post('/socialLogin', data)
    return {
        type: LOGIN_SOCIAL,
        payload: request.data
    };
};
export const loginSocialGoogle = dataToSubmit => {
    let data = {
        "body": {
            "DeviceName": "Web",
            "DeviceType": "Web",
            "Device_UID": dataToSubmit.googleId,
            "Email": dataToSubmit.email,
            "FirstName": dataToSubmit.familyName,
            "LastName": dataToSubmit.givenName,
            "LoginType": "googleplus",
            "Mobile": "",
            "NotificationToken": dataToSubmit.token ? dataToSubmit.token : '',
            "OSVersion": "24",
            "SocialLoginID": dataToSubmit.googleId,
            "UserName": ''
        },
        "method": "socialLogin"
    }
    const request = axiosInstance
        .post('/socialLogin', data)
        .then(res => {
            return res.data;
        }).catch(err => err.response.data);
    return {
        type: LOGIN_SOCIAL,
        payload: request
    };
};
export const getConfigService = async () => {
    try {
        return  await axiosInstance.post('/getConfig', {"method": "getConfig"})
    } catch (err) {
        return err.response.data
    }
}
export const userSignup = dataToSubmit => {
    let data = {
        "method": "userSignup",
        "body": {
            ...dataToSubmit,
            "NotificationToken": "",
            "Device_UID": "941AC910-BEBA-49FE-A0CA-7BF81F939E7F",
            "DeviceName": "Web",
            "OSVersion": "12.4",
            "MobileType": "IOS",
        }
    }
    return axiosInstance.post('/userSignup', data)
};
export function setCurrentUser(user) {
    return {
        type: AUTH_USER,
        payload: {
            data: user
        }
    };
}
export const forgotPassword = async email => {
    let data = {
        "method": "forgotPassword",
        "body": {
            "Email": email
        }
    }
    return await axiosInstance.post('/forgotPassword', data)
};
export const checkOtp = async (values) => {
    let data = {
        "method": "Checkotp",
        "body": {
            "Email": values.Email,
            "Otp": values.Otp,
        }
    }
    return await axiosInstance.post('/checkOtp', data)
};
export const editProfile = async data => {
    return await axiosInstance.post('/editProfile', data)
};
export const getProfileUser = async (userId) => {
    let accessToken = localStorage.getItem('access_token')
    let data = {
        "method": "getProfile",
        "body": {
            "accessToken" : accessToken,
            "UserId" : parseInt(userId)
        }
    }
    return await axiosInstance.post('/getProfile', data)
};
export const changePassword = async (values) => {
    let data = {
        "method": "changePassword",
        "body": {
            "UserId": values.userId,
            "Password": values.password,
            "ConfirmPassword": values.confirmPassword
        }
    }
    return await axiosInstance.post('/changePassword', data)
};
export const listPortfolioUser = async (userId, pageNo= 0) => {
    let accessToken = localStorage.getItem('access_token');
    let data = {
        "method": "getUserPortfolio",
        "body": {
            "accessToken": accessToken,
            "UserId": userId,
            "pageNo": pageNo
        }
    }
    const result =  await axiosInstance.post('/getUserPortfolio', data)
    return {
        type: GET_LIST_PORTFOLIO,
        payload: result.data
    }
}
export const addPortfolio = async (data) => {
    return await axiosInstance.post('/addPortfolio', data);
}

export const deletePortfolio = async (data, id) => {
    const result = await axiosInstance.post('/deletePortfolio', data);
    return {
        type: DELETE_PORTFOLIO,
        payload: {
            id : id ,
            data : result.data
        }
    };
}

export const updateLicense = async (data) => {
    return await axiosInstance.post('/upload-license', data);
}

export const updateSettings = async (isPush, notificationToken = '') => {
    let accessToken = localStorage.getItem('access_token');
    let data = {
        "method": "settings",
        "body": {
            "accessToken": accessToken,
            "IsPush": isPush,
            "NotificationToken": notificationToken
        }
    }
    const result =  await axiosInstance.post('/settings', data);
    return {
        type: UPDATE_SETTING,
        payload:  {
            result : result.data,
            isPush : isPush
        }
    };
}

export function logout() {
    return dispatch => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_expiresIn');
        localStorage.removeItem('userId');
        localStorage.removeItem('userId_expiresIn');
        // setAuthorizationToken(false);
        delete axiosInstance.defaults.headers.common["Authorization"];
        dispatch({
            type: LOGOUT
        })
    }
}

export const createAccountStripe = async (formData) => {
    formData.accessToken = localStorage.getItem('access_token')
    let data = {
        "method": "createConnectAccount",
        "body": formData
    }
    const result =  await axiosInstance.post('/createConnectAccount', data);
    return result.data
}

export const updateConnectAccount = async (formData) => {
    formData.accessToken = localStorage.getItem('access_token')
    let data = {
        "method": "updateConnectAccount",
        "body": formData
    }
    const result =  await axiosInstance.post('/updateConnectAccount', data);
    return result.data
}

export const saveBankAccount = async (formData) => {
    formData.accessToken = localStorage.getItem('access_token')
    let data = {
        "method": "createBankAccount",
        "body": formData
    }
    const result =  await axiosInstance.post('/createBankAccount', data);
    return result.data
}

export const uploadVerificationCrew = async (formData) => {
    return await axiosInstance.post('/upload-verification', formData);
}

export const sendChatMessage = async (appointmentId, message) => {
    const accessToken = localStorage.getItem('access_token')
    const formData = new FormData()
    formData.append('accessToken', accessToken)
    formData.append('appointmentId', appointmentId)
    formData.append('message', message)
    return await axiosInstance.post('/sendMessage', formData);
}

export const verificationDone = async (accessToken) => {
    let data = {
        "method": "isVerified",
        "body": {
            accessToken : accessToken
        }
    }
    return await axiosInstance.post('/isVerified', data);
}

export const resendCodeVerify = async (email) => {
    let data = {
        "method": "resendVerificationLink",
        "body": {
            email : email
        }
    }
    return await axiosInstance.post('/resendVerificationLink', data);
}