import React from 'react';
import {Button, Modal} from "antd";
import './index.scss'

const CheckVerifyCashOut = ({visible , user, openModalAddBank, openModalUploadPersonalLicense , openModalUploadDriveLicense,  closeModal}) => {
      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-check-verify-cash-out'
                >
                      <div className="head-modal">
                            <h5>Alert</h5>
                            <Button onClick={closeModal}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <div className="content">
                                  <p>- Please provide full bank information to the bank so that you can withdraw.</p>
                                  <p>- Verify driver's license information and personal license to get customer's trust so that you will get more bookings.</p>
                            </div>
                      </div>
                      <div className="footer-modal">
                            {user.external_accounts === 0 &&
                                    <Button onClick={openModalAddBank}>Add bank details</Button>
                            }
                            {user.w9_status === 0 &&
                                     <Button onClick={openModalUploadPersonalLicense}>Update personal license</Button>
                            }
                            {(!user.LicenseIdFront || !user.LicenseIdBack ) &&
                                    <Button onClick={openModalUploadDriveLicense}>Upload drive license</Button>
                            }
                      </div>
                </Modal>
          </div>
      );
}

export default CheckVerifyCashOut;