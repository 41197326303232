import React, {useState} from 'react';
import './index.scss'
import verifyEmail from './../../../../assets/images/email_verify.png';
import {Button} from "antd";
import {useHistory} from "react-router-dom";
import {logout, resendCodeVerify, verificationDone} from "../../../../state/auth/actions";
import {useDispatch} from "react-redux";
import ModalResultSuccess from "../../../../components/Result";
import ModalResultError from "../../../../components/Result/error";
import AlertSuccess from "../../../../components/Alert/success";
import AlertError from "../../../../components/Alert/error";

const  VerificationEmail = ({email, accessToken, closeVerify}) => {
      const history = useHistory();
      const dispatch = useDispatch();
      const [isOpenSuccess, setOpenSuccess] = useState(false)
      const [isOpenError, setOpenError] = useState(false)
      const [message, setMessage] = useState(null)
      const [loading, setLoading] = useState(false)


      const handleLogout = async () => {
            await dispatch(logout());
            history.push('/');
      };
      const closeModalResult = () => {
            setOpenSuccess(false)
            if(history.location.pathname === '/home'){
                  closeVerify()
            } else{
                  history.push('/');
            }
      }
      const closeModalResultError = () => {
            setOpenError(false)
      }

      const checkVerification = async () => {
            setLoading(true)
            const result = await verificationDone(accessToken)
            if (result.data.isVerified.status) {
                  setOpenSuccess(true)
                  setMessage(result.data.isVerified.Message)
                  setLoading(false)
            } else {
                  setOpenError(true)
                  setMessage(result.data.isVerified.Message)
                  setLoading(false)
            }
      }

      const resendVerification = async () => {
            const result = await resendCodeVerify(email)
            if (result.data.resendVerificationLink.status) {
                  return AlertSuccess(result.data.resendVerificationLink.Message)
            } else {
                  return AlertError(result.data.resendVerificationLink.Message)
            }
      }

      return (
          <div className='page-verification-email page-children'>
                <div className="verification-email">
                      <div className="head-widget">
                            <Button  onClick={() => handleLogout()}>Sign out</Button>
                      </div>
                      <div className="thumb-verify">
                            <img src={verifyEmail} alt=""/>
                            <p>Verify your email address</p>
                      </div>
                      <div className="content-verify">
                            <p>1. Open your registered email.</p>
                            <p>2. Click on verify link.</p>
                            <p>3. Open application and press on verification done.</p>
                            <p>4. If you are not getting mail, please click on resend verification link.</p>
                            <p>5. Thanks for registered in crewsnow you are ready to go.</p>
                      </div>
                      <div className="footer-verify">
                            <Button loading={loading} onClick={checkVerification}>Verification done</Button>
                            <Button onClick={resendVerification}>Resend verification link</Button>
                      </div>
                </div>
                {isOpenSuccess && <ModalResultSuccess isSuccess={isOpenSuccess} messageSuccess={message} closeModalSuccess={closeModalResult} />}
                {isOpenError && <ModalResultError isError={isOpenError} messageSuccess={message} closeModalError={closeModalResultError} />}
          </div>
      );
}

export default VerificationEmail;