import React, {useState} from 'react';
import {Avatar, Button, Col, Modal, Row, Slider} from "antd";
import './index.scss'
import {NO_IMAGE} from "../../../../constants";
import {addTip} from "../../../../state/appointment/action";
import AlertSuccess from "../../../../components/Alert/success";

const ModalTipAppointment = ({visible, appointmentId, crewName, crewProfileImage, maxTip, closeModal}) =>  {
      const [loading, setLoading] = useState(false)
      const [price, setPrice] = useState(1)

      const addTipAppointment = async () => {
            try {
                  setLoading(true)
                  const result = await addTip(appointmentId, price)
                  if(result.addTip.status){
                        AlertSuccess(result.addTip.Message)
                  }
                  setLoading(false)
                  closeModal(price)
            } catch (e) {
                  console.log(e)
            }
      }

      const onChange = (value) => {
            setPrice(value)
      }

      return (
          <React.Fragment>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-tip-appointment'
                    width={500}
                >
                      <div className="head-modal">
                            <h5>Tip appointment</h5>
                            <Button onClick={closeModal}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <div className="avatar">
                                  <Avatar size={48} src={crewProfileImage ? crewProfileImage : NO_IMAGE} />
                                  <p className="name-crew">{crewName}</p>
                            </div>
                            <div className="head-row">
                                  <p>Would you like to leave a tip?</p>
                            </div>
                            <Row className='tip-row'>
                                  <Col span={20}>
                                        <Slider
                                            min={1}
                                            max={maxTip}
                                            onChange={onChange}
                                            value={price}
                                        />
                                  </Col>
                                  <Col span={4} className='price-tip'>
                                        <p>${(price).toFixed(2)}</p>
                                  </Col>
                            </Row>
                            <div className="button-tip">
                                  <Button onClick={closeModal}>Cancel</Button>
                                  <Button loading={loading} onClick={addTipAppointment} className='btn-pay-now'>Confirm</Button>
                            </div>
                      </div>
                </Modal>
          </React.Fragment>

      );
}

export default ModalTipAppointment;