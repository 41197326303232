import React, {useCallback, useEffect, useRef, useState} from 'react';
import './index.scss'
import {LeftOutlined, RightOutlined, EnvironmentFilled} from "@ant-design/icons/lib/icons";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList} from "recharts";
import {Avatar, Button, Collapse} from "antd";
import {CASHED_OUT, NO_IMAGE} from "../../constants";
import moment from "moment";
import {earning, earningDetail} from "../../state/payment/actions";
import Loading from "../../components/Loading";
import EmptyData from "../../components/Empty";
import ScheduleDetail from "../Appointment/components/ScheduleDetail";
import {getFirstDayWeek} from "../../utils/help";
import {getLastDayWeek} from "../../utils/help";
import {formatCurrency} from "../../utils/help";
import CashOut from "./components/CashOut";
import {useDispatch, useSelector} from "react-redux";
import CheckVerifyCashOut from "./components/CheckCashOut";
import AddBankAccount from "./components/AddBankAccount";
import UploadPersonalLicense from "./components/UploadPersonalLicense";
import UploadDriveLicense from "./components/UploadDriveLicense";
import {getProfileUser, setCurrentUser} from "../../state/auth/actions";

const Earning = (props) => {
    const ref = useRef(null);
    const [widthChart, setWidthChart] = useState(0);
    const [startDate , setStartDate] = useState(getFirstDayWeek())
    const [endDate, setEndDate] = useState(getLastDayWeek())
    const [earningData, setEarningData] = useState([])
    const [spinState, setSpinState] = useState(true)
    const [dataChart, setDataChart] = useState([])
    const [loadingCollapse, setLoadingCollapse] = useState(true)
    const [keyActive, setKeyActive] = useState(null)
    const [listAppointment, setListAppointment] = useState([])
    const [isOpenDetail, setOpenDetail] = useState(false)
    const [appointmentSelected, setAppointmentSelected] = useState(null)
    const [isOpenCashOut, setOpenCashOut] = useState(false)
    const [amount, setAmount] = useState(null)
    const [typeCash, setTypeCash] = useState(null)
    const [dateCash, setDateCash] = useState(null)
    const {user} = useSelector(state => state.auth)
    const [isConfirmLicense, setConfirmLicense] = useState(false)
    const [isCanCashOut, setCanCashOut] = useState(true)
    const [isOpenAddBank, setOpenAddBank] = useState(false)
    const [isOpenUploadPersonalLicense, setOpenUploadPersonalLicense] = useState(false)
    const [isOpenUploadDriveLicense, setOpenUploadDriveLicense] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if(user && (user.w9_status === 0 || !user.LicenseIdFront || !user.LicenseIdBack || user.external_accounts === 0)){
            setConfirmLicense(true)
            setCanCashOut(false)
        }
        if(user && (user.w9_status === 1 && user.LicenseIdFront && user.LicenseIdBack && user.external_accounts !== 0)){
            setConfirmLicense(false)
            setCanCashOut(true)
        }
    }, [user])

    useEffect(() => {
        setWidthChart(ref.current ? ref.current.offsetWidth : 0)
        const handleResize = () => {
            setWidthChart(ref.current.offsetWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [ref])

    useEffect(() => {
        const initDataEarning = async () => {
            setSpinState(true)
            const result = await earning(startDate, endDate)
            setEarningData(result.data.Earning.data)
            let weeklyEarning = result.data.Earning.data.weeklyEarning.map(item => {
                return {
                    day : item.Day,
                    total : parseInt(item.Total)
                }
            })
            setDataChart(weeklyEarning)
            setSpinState(false)
        }
        initDataEarning()
    }, [startDate, endDate])

    const renderCustomizedLabel = (props) => {
        const {x, y, width, value} = props;
        const radius = 10;
        return (
            <g>
                <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle"
                      fontSize={10}>
                    {value}
                </text>
            </g>
        );
    };

    const renderBarChart = useCallback (() => {
        return (
            <BarChart
                className='chart-custom-earning'
                width={widthChart}
                height={600}
                data={dataChart}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="5 5" stroke="#808080" strokeOpacity="0.1"/>
                <XAxis dataKey="day" stroke="#fff" tickMargin={20} height={100} fontWeight={500} scale="point"
                       padding={{left: 50, right: 50}}/>
                <YAxis/>
                <Bar stroke="none" dataKey="total" fill="#feb034" barSize={25} minPointSize={5}>
                    <LabelList dataKey="total" content={renderCustomizedLabel}/>
                </Bar>
            </BarChart>
        )
    }, [dataChart, widthChart])

    useEffect(() => {
        renderBarChart()
    }, [startDate, endDate, renderBarChart])

    const changeDateEarning = (day) => {
        setStartDate(moment(new Date(startDate), "DD-MM-YYYY").add(day, 'days').format('YYYY-MM-DD'))
        setEndDate(moment(new Date(endDate), "DD-MM-YYYY").add(day, 'days').format('YYYY-MM-DD'))
    }

    const onChangeCollapse = (key) => {
        setKeyActive(key)
    }

    useEffect(() => {
        if(keyActive){
            const detailEarning = async () => {
                try {
                    setLoadingCollapse(true)
                    const result = await earningDetail(keyActive)
                    if(result.status){
                        setListAppointment(result.data)
                    }
                    setLoadingCollapse(false)
                } catch (e) {
                    console.log(e)
                }
            }
            detailEarning()
        }
    }, [keyActive])

    const seeDetailAppointment = (id) => {
        setAppointmentSelected(id)
        setOpenDetail(true)
    }

    const closeModal = (isCashed) => {
        if(isCashed && typeCash === 'day'){
            let newData = earningData
            let foundIndex = newData.weeklyEarning.findIndex(x => x.Date === dateCash);
            newData.weeklyEarning[foundIndex].CashedOut = CASHED_OUT
            newData.TotalCashOut = parseInt(newData.TotalCashOut) - parseInt(amount)
            setEarningData(newData)
        }
        if(isCashed && typeCash === 'all'){
            let newData = earningData
            // newData = newData.weeklyEarning.map(item => {
            //     return Object.assign({}, item, {
            //         CashedOut:  CASHED_OUT
            //     });
            // })
            newData = newData.weeklyEarning.map(v => ({...v, CashedOut:  CASHED_OUT}));
            newData.TotalCashOut = parseInt(newData.TotalCashOut) - parseInt(amount)
            setEarningData(newData)
        }
        setAmount(null)
        setTypeCash(null)
        setDateCash(null)
        setOpenCashOut(false)
        setConfirmLicense(false)
    }

    const openModalCashOut = (amount, date , type) => {
        setAmount(amount)
        setTypeCash(type)
        setDateCash(date)
        setOpenCashOut(true)
    }

    const closeModalVerify = (isSuccess) => {

        if(isSuccess){
            const getProfile = async () => {
                let result = await getProfileUser(user.UserId)
                dispatch(setCurrentUser(result.data.getProfile.data))
            }
            getProfile()
        }
        setConfirmLicense(true)
        setOpenAddBank(false)
        setOpenUploadPersonalLicense(false)
        setOpenUploadDriveLicense(false)

    }

    const openModalAddBank =  () => {
        setOpenAddBank(true)
        setConfirmLicense(false)
    }

    const openModalUploadPersonalLicense  = () => {
        setOpenUploadPersonalLicense(true)
        setConfirmLicense(false)
    }

    const openModalUploadDriveLicense =  () => {
        setOpenUploadDriveLicense(true)
        setConfirmLicense(false)
    }

    return (
        <div className='earning-page page-children h-100'>
            <div className="earning">
                    <div className="charts">
                        <div className="date-earning">
                            <Button onClick={() => changeDateEarning(-7)}><LeftOutlined/></Button>
                            <h6>{moment(startDate).format('LL')} - {moment(endDate).format('LL')}</h6>
                            <Button onClick={() => changeDateEarning(7)} disabled={endDate === moment(new Date()).format('YYYY-MM-DD')}><RightOutlined/></Button>
                        </div>
                        <div className="chart" ref={ref}>
                            {renderBarChart()}
                        </div>
                    </div>
                    {spinState ?
                        <Loading/>
                    :
                        <React.Fragment>
                            <div className="total-this-week">
                                <p>This week</p>
                                <p>${earningData.weeklyTotalPrice}</p>
                            </div>
                            <div className="total-payout">
                                <div>
                                    <p>Last payout</p>
                                    <p className='amount'>{(formatCurrency().format(earningData.LastPayout))}</p>
                                </div>
                                <div>
                                    <p>Total earning</p>
                                    <p className='amount'>{(formatCurrency().format(earningData.TotalEarning))}</p>
                                </div>
                                <div>
                                    <p>Total tip</p>
                                    <p className='amount'>{(formatCurrency().format(earningData.TotalTipPrice))}</p>
                                </div>
                            </div>
                            <div className="cash-out-now">
                                <div>
                                    <div className='content-cash-out'>
                                        <p>The total amount you can cash out today</p>
                                        <p className="amount">{(formatCurrency().format(earningData.TotalCashOut))}</p>
                                    </div>
                                    <Button onClick={() => openModalCashOut(earningData.TotalCashOut, '' , 'all')} disabled={parseFloat(earningData.TotalCashOut) === 0 || !isCanCashOut} className={`${(parseFloat(earningData.TotalCashOut) === 0 || !isCanCashOut) && 'btn-cash-out-disable'}`}>Cash out</Button>
                                </div>
                            </div>
                            <div className="transactions">
                                {!isOpenDetail ?
                                    <React.Fragment>
                                        <div className="header-widget">
                                            <p>Daily Earning</p>
                                            <p>Transactions</p>
                                        </div>
                                        <div>
                                            <Collapse onChange={onChangeCollapse} accordion activeKey={keyActive}>
                                                {earningData.weeklyEarning.map(item =>
                                                    <Collapse.Panel
                                                        key={item.Date}
                                                        header={
                                                            <div  className='title-collapse'>
                                                                <div className='head-col'>
                                                                    <p className="time">{item.EarningDate} <span>( {item.TotalAppointment} appointment )</span></p>
                                                                    <Button onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        openModalCashOut(item.Total, item.Date, 'day')
                                                                    }} disabled={item.RemainingDay !== 0 || parseFloat(item.Total) === 0 || item.CashedOut === 1 || !isCanCashOut} className={`${(item.RemainingDay !== 0 || parseFloat(item.Total) === 0 || item.CashedOut === 1 || !isCanCashOut) && 'no-cash-out'}`}>Cash out</Button>
                                                                </div>
                                                                <div className='desc-col'>
                                                                    <div>
                                                                        <p>Amount</p>
                                                                        <p>${item.Price}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p>Tip</p>
                                                                        <p>${item.tipPrice}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p>Total</p>
                                                                        <p>${item.Total}</p>
                                                                    </div>
                                                                    {item.RemainingDay !== 0 && parseFloat(item.Total) !== 0 &&
                                                                        <div>
                                                                            <p className='notify-day-cash'>After {item.RemainingDay} days, you can cash out</p>
                                                                        </div>
                                                                    }
                                                                    {item.RemainingDay === 0 && parseFloat(item.Total) !== 0 && item.CashedOut === 0 &&
                                                                        <div>
                                                                            <p className='notify-day-cash'>Not yet cash out</p>
                                                                        </div>
                                                                    }
                                                                    {item.RemainingDay === 0 && parseFloat(item.Total) !== 0 && item.CashedOut === 1 &&
                                                                        <div>
                                                                            <p className='notify-day-cash'>Cashed out</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        {loadingCollapse && keyActive === item.Date ?
                                                            <Loading/>
                                                            :
                                                            <div className='body-col'>
                                                                <div className="list-appointment-cash">
                                                                    {listAppointment.length > 0 ?
                                                                        listAppointment.map(item =>
                                                                            <div key={item.appointmentId} className="item">
                                                                                <div className="info-client">
                                                                                    <div className="info-top">
                                                                                        <div className="thumb">
                                                                                            <Avatar size={48} src={item.ClientProfilePic ? item.ClientProfilePic : NO_IMAGE} />
                                                                                        </div>
                                                                                        <div className="info">
                                                                                            <p className="name" > {item.ClientName} <span>${item.Price}</span></p>
                                                                                            <span className="appointment-name">{item.ServiceName}</span>
                                                                                            <p className='address'><EnvironmentFilled />{item.Location}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="action-earning">
                                                                                    <p className='time'>{moment(item.Date).format('MM-DD-YYYY')} - {moment(item.Date, 'HH:mm').format('hh:mm A')}</p>
                                                                                    <div className='duration'>
                                                                                        <p>Duration:</p>
                                                                                        <p>{item.Hours ? `${parseFloat(item.Hours).toFixed(1)} (per/hour)` : `${parseFloat(item.Days).toFixed(1)} (per/day)`}</p>
                                                                                    </div>
                                                                                    <Button className='detail' onClick={() => seeDetailAppointment(item.appointmentId)}>Detail</Button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : <EmptyData/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </Collapse.Panel>
                                                )}
                                            </Collapse>
                                        </div>
                                    </React.Fragment>
                                :
                                    <ScheduleDetail appointmentSelected={appointmentSelected} closeDetail={() => setOpenDetail(false)}  />
                                }

                            </div>
                        </React.Fragment>
                    }
                </div>
            {isOpenCashOut && <CashOut visible={isOpenCashOut} amount={amount} type={typeCash} date={dateCash} closeModal={closeModal}/> }
            {isConfirmLicense && <CheckVerifyCashOut visible={isConfirmLicense} user={user} openModalAddBank={openModalAddBank} openModalUploadPersonalLicense={openModalUploadPersonalLicense} openModalUploadDriveLicense={openModalUploadDriveLicense}  closeModal={closeModal}/> }
            {isOpenAddBank && <AddBankAccount visible={isOpenAddBank} user={user}  closeModal={closeModalVerify}/> }
            {isOpenUploadPersonalLicense && <UploadPersonalLicense visible={isOpenUploadPersonalLicense} user={user} closeModal={closeModalVerify}/> }
            {isOpenUploadDriveLicense && <UploadDriveLicense visible={isOpenUploadDriveLicense} user={user} closeModal={closeModalVerify}/> }

        </div>
    );
}

export default Earning;