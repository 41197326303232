export const USER_TYPE_CLIENT = 'company'
export const USER_TYPE_CREW = 'crews'

export const ACCEPT_APPOINTMENT_FOR_CREW = "1"
export const DECLINE_APPOINTMENT_FOR_CREW = "3"
export const CANCEL_APPOINTMENT_FOR_CLIENT = "2"

export const STATUS_INVITATION_CONNECTION = "0"
export const STATUS_CONFIRM_CONNECTION = "1"
export const STATUS_REJECT_CONNECTION = "2"
export const STATUS_UNFOLLOW_CONNECTION = "3"

export const NO_IMAGE = 'https://orbis-alliance.com/wp-content/themes/consultix/images/no-image-found-360x260.png'

export const PUSH_NOTIFICATION = "1"
export const NOT_PUSH_NOTIFICATION = "0"

export const PAYMENT_PAID = '1'
export const PAYMENT_UNPAID = '0'

export const APPOINTMENT_PENDING = '0'
export const APPOINTMENT_SCHEDULE = '1'
export const APPOINTMENT_REJECT = '2'
export const APPOINTMENT_CANCEL = '3'
export const APPOINTMENT_COMPLETE = '4'

export const ACTION_ARRIVE_APPOINTMENT = '2'
export const ACTION_START_APPOINTMENT = '3'
export const ACTION_COMPLETE_APPOINTMENT = '4'

export const CREW_STATUS_ARRIVE_APPOINTMENT = '1'
export const CREW_STATUS_START_APPOINTMENT = '2'
export const CREW_STATUS_COMPLETE_APPOINTMENT = '3'

export const ATTACHMENT_TYPE_USB = 1
export const ATTACHMENT_TYPE_SEND_MAIL = 2
export const ATTACHMENT_TYPE_UPLOAD_FILE = 3

export const CASHED_OUT = 1
export const NOT_CASH_OUT = 0

// notification
export const TYPE_INVITE_SEND                = '1';
export const TYPE_INVITE_ACCEPT              = '2';
export const TYPE_APPOINTMENT_BOOK           = '3';
export const TYPE_APPOINTMENT_ACCEPT         = '4';
export const TYPE_APPOINTMENT_CANCEL         = '5';
export const TYPE_APPOINTMENT_COMPLETE       = '6';
export const TYPE_APPOINTMENT_ACCEPT_REQUEST = '7';
export const TYPE_APPOINTMENT_SCHEDULE       = '8';
export const TYPE_APPOINTMENT_REJECT         = '9';
export const TYPE_APPOINTMENT_JOB_POST       = '10';
export const TYPE_APPOINTMENT_EDIT           = '11';
export const TYPE_APPOINTMENT_PAYMENT_FINAL  = '12';
export const TYPE_JOB_START_BEFORE           = '13';
export const TYPE_JOB_END_BEFORE             = '14';
export const TYPE_CREW_SPEND_EXTRA_TIME      = '15';
export const TYPE_CREW_ARRIVE_REVENUE        = '16';
export const TYPE_APPOINTMENT_TIP            = '17';
export const TYPE_NEW_MESSAGE                = '18';
export const TYPE_APPOINTMENT_EXPIRED        = '19';

export const MAX_PRICE_SERVICE        = 99999.99;

export const FILE_TERM = 'http://crewsnow.com/crewsapp/Pages/terms-of-use'
export const FILE_PRIVACY_POLICY = 'http://crewsnow.com/crewsapp/Pages/privacy-policy'

export const NONE_VACCINE_STATUS           = 0;
export const NONE_VACCINE_STATUS_NAME           = 'No vaccine';
export const VACCINATED_VACCINE_STATUS               = 1;
export const VACCINATED_VACCINE_STATUS_NAME               = 'Vaccinated';
export const FULLY_VACCINATED_VACCINE_STATUS        = 2;
export const FULLY_VACCINATED_VACCINE_STATUS_NAME        = 'Fully vaccinated';