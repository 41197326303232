import React, {useState} from 'react';
import './index.scss'
import {Button, Form, Input} from "antd";
import {UserOutlined, PhoneOutlined, MailOutlined} from "@ant-design/icons";

const Contact = (props) => {
    const [loading, setLoading] = useState(false);

    const conSendContact = (values) => {
        setLoading(true)
        setLoading(false)
    }
    return (
        <div className='contact-page  page-children h-100 pt-5'>
            <div className='title'>
                <h2>Contact</h2>
                <p>Fill your information below</p>
            </div>
            <Form name="contact"
                  onFinish={conSendContact}
            >
                <div className="wrapper">
                    <div className='left'>
                        <Form.Item
                            className="w-100"
                            name="name"
                            rules={[{required: true, message: 'Please input name!'}]}
                        >
                            <Input placeholder="Name" className="w-100"
                                   prefix={<UserOutlined className="site-form-item-icon"/>}/>
                        </Form.Item>
                        <Form.Item
                            className="w-100"
                            name="phone"
                            rules={[{required: true, message: 'Please input phone!'}]}
                        >
                            <Input placeholder="Phone" className="w-100"
                                   prefix={<PhoneOutlined className="site-form-item-icon"/>}/>
                        </Form.Item>
                        <Form.Item
                            className="w-100"
                            name="email"
                            rules={[{required: true, message: 'Please input email!'}]}
                        >
                            <Input placeholder="Email" className="w-100"
                                   prefix={<MailOutlined className="site-form-item-icon"/>}/>
                        </Form.Item>
                        <Form.Item className="w-100">
                            <Button  loading={loading} htmlType="submit" className="w-100" id="btn-send-contact">
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                    <div className='right'>
                        <Form.Item name='message' className="w-100"
                                   rules={[{required: true, message: 'Please input message!'}]}
                        >
                            <Input.TextArea placeholder='Message' autoSize={{ minRows: 8, maxRows: 10 }}/>
                        </Form.Item>
                    </div>

                </div>
            </Form>
        </div>
    );
}

export default Contact;