import axios from "axios";
import {notification} from "antd";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "X-API-KEY": "e9vp8QyZAoW4euwY116SHxQfCGFTOTb2e0xU2q9E"
    }
});

axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {

    console.log('error', error)
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.status === 401){
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_expiresIn');
        localStorage.removeItem('userId');
        localStorage.removeItem('userId_expiresIn');
        delete axiosInstance.defaults.headers.common["Authorization"];
        notification.warning({
            message: 'Your session has expired, please login again.',
            className: 'custom-notify-message-success'
        });
        window.location.href = "/";
    }
});
export default axiosInstance;
