import React, {useState} from 'react';
import {Button, Modal, Radio, Upload} from "antd";
import './index.scss'
import {getBase64} from "../../../../utils/help";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {uploadVerificationCrew} from "../../../../state/auth/actions";
import AlertError from "../../../../components/Alert/error";
import ModalResultSuccess from "../../../../components/Result";

const UploadPersonalLicense = ({visible , closeModal}) => {
      const [frontIdFile , setFrontIdFile] = useState(null)
      const [frontIdImage , setFrontIdImage] = useState(null)
      const [loadingFrontId , setLoadingFrontId] = useState(false)
      const [backIdFile , setBackIdFile] = useState(null)
      const [backIdImage , setBackIdImage] = useState(null)
      const [loadingBackId , setLoadingBackId] = useState(false)
      const [w9File , setW9File] = useState(null)
      const [w9Image , setW9Image] = useState(null)
      const [loadingW9 , setLoadingW9] = useState(false)
      const [loadingSubmit , setLoadingSubmit] = useState(false)
      const accessToken = localStorage.getItem('access_token')
      const [isOpenModalResult, setOpenModalResult] = useState(false)
      const [messageSuccess, setMessageSuccess] = useState(null)
      const [typeUpload, setTypeUpload] = useState('w-9')

      const uploadButtonFrontId = (
          <div>
                {loadingFrontId ? <LoadingOutlined /> : <PlusOutlined />}
          </div>
      );

      const uploadButtonBackId = (
          <div>
                {loadingBackId ? <LoadingOutlined /> : <PlusOutlined />}
          </div>
      );

      const uploadButtonW9 = (
          <div>
                {loadingW9 ? <LoadingOutlined /> : <PlusOutlined />}
          </div>
      );

      const handleUploadLicenseFront = info => {
            if (info.file.status === 'uploading') {
                  setLoadingFrontId(true)
                  return;
            }
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  setFrontIdFile(info.file.originFileObj)
                  getBase64(info.file.originFileObj, imageUrl => {
                        setFrontIdImage(imageUrl)
                        setLoadingFrontId(false)
                  });
            }
      };

      const handleUploadLicenseBack = info => {
            if (info.file.status === 'uploading') {
                  setLoadingBackId(true)
                  return;
            }
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  setBackIdFile(info.file.originFileObj)
                  getBase64(info.file.originFileObj, imageUrl => {
                        setBackIdImage(imageUrl)
                        setLoadingBackId(false)
                  });
            }
      };

      const handleUploadW9W8Ben = info => {
            if (info.file.status === 'uploading') {
                  setLoadingW9(true)
                  return;
            }
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  setW9File(info.file.originFileObj)
                  getBase64(info.file.originFileObj, imageUrl => {
                        setW9Image(imageUrl)
                        setLoadingW9(false)
                  });
            }
      };

      const saveUploadPersonalLicense = async () => {
            setLoadingSubmit(true)
            let formData = new FormData();
            formData.append('accessToken', accessToken)
            if(!frontIdFile){
                  setLoadingSubmit(false)
                  return AlertError('Please upload photo id front!')
            }
            if(!backIdFile){
                  setLoadingSubmit(false)
                  return AlertError('Please upload photo id back!')
            }
            if(!w9File){
                  setLoadingSubmit(false)
                  return AlertError('Please upload w-9 or w-8ben!')
            }
            formData.append('w9Form_w8Ben', w9File);
            formData.append('photoIdFront', frontIdFile);
            formData.append('photoIdBack', backIdFile);
            let result = await uploadVerificationCrew(formData);
            console.log(result)
            if(result.data.status){
                  setMessageSuccess(result.data.Message)
                  setOpenModalResult(true)
                  setLoadingSubmit(false)
            }else {
                  setLoadingSubmit(false)
                  return AlertError(result.data.Message)
            }
      }

      const closeModalSuccess = () => {
            setOpenModalResult(false)
            closeModal(true)
      }

      const onChangeUpload = (e) => {
            setTypeUpload(e.target.value)
      }

      return (
          <div>
                <Modal
                    footer={null}
                    visible={visible}
                    maskClosable={false}
                    closable={false}
                    centered
                    width={500}
                    className='modal-upload-personal-license'
                >
                      <div className="head-modal">
                            <h5>Upload personal license</h5>
                            <Button onClick={() => closeModal(false)}>Close</Button>
                      </div>
                      <div className="body-modal">
                            <div className="widget">
                                  <div className="head-widget">
                                        <p className="title-widget">Select photo ID</p>
                                  </div>
                                  <div className="upload-license">
                                        <div className="front-license">
                                              <p className='title'>Front of ID</p>
                                              <Upload
                                                  name="avatar"
                                                  listType="picture-card"
                                                  className="license-drive-upload"
                                                  accept=".png, .jpg, .jpeg"
                                                  showUploadList={false}
                                                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                  action={process.env.REACT_APP_URL}
                                                  onChange={handleUploadLicenseFront}
                                              >
                                                    {frontIdImage ? <img src={frontIdImage} alt="avatar" style={{ width: '100%' }} /> : uploadButtonFrontId}
                                              </Upload>
                                        </div>
                                        <div className="back-license">
                                              <p className='title'>Back of ID</p>
                                              <Upload
                                                  name="avatar"
                                                  listType="picture-card"
                                                  className="license-drive-upload "
                                                  accept=".png, .jpg, .jpeg"
                                                  showUploadList={false}
                                                  action={process.env.REACT_APP_URL}
                                                  onChange={handleUploadLicenseBack}
                                              >
                                                    {backIdImage ? <img src={backIdImage} alt="avatar" style={{ width: '100%' }} /> : uploadButtonBackId}
                                              </Upload>
                                        </div>
                                  </div>
                            </div>
                            <div className="widget">
                                  <div className="head-widget">
                                        <p className="title-widget">Select w-9 or w-8ben</p>
                                  </div>
                                  <div className="upload-license upload-w9-w8ben">
                                        <div className="type-upload">
                                              <Radio.Group onChange={onChangeUpload} defaultValue={typeUpload}>
                                                    <Radio.Button value='w-9'>w-9</Radio.Button>
                                                    <Radio.Button value='w-8ben'>w-8ben</Radio.Button>
                                              </Radio.Group>
                                        </div>
                                        <div className="front-license w-9-w-8ben">
                                              {/*<p className='title'>{typeUpload === 'w-9' ? 'w-9' : 'w-8ben'}</p>*/}
                                              <Upload
                                                  name="avatar"
                                                  listType="picture-card"
                                                  className="license-drive-upload"
                                                  accept=".png, .jpg, .jpeg"
                                                  showUploadList={false}
                                                  action={process.env.REACT_APP_URL}
                                                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                  onChange={handleUploadW9W8Ben}
                                              >
                                                    {w9Image ? <img src={w9Image} alt="avatar" style={{ width: '100%' }} /> : uploadButtonW9}
                                              </Upload>

                                              {/*<Upload name="logo"*/}
                                              {/*        accept=".pdf"*/}
                                              {/*        maxCount={1}*/}
                                              {/*        action={process.env.REACT_APP_URL}*/}
                                              {/*    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"*/}
                                              {/*        listType="picture"*/}
                                              {/*>*/}
                                              {/*      <Button className='btn-upload-file btn-upload-attachment'*/}
                                              {/*              icon={<CloudUploadOutlined/>}>Upload</Button>*/}
                                              {/*</Upload>*/}
                                        </div>
                                  </div>
                            </div>
                      </div>
                      <div className="footer-modal">
                            <Button className="skip" onClick={() => closeModal(false)}>Cancel</Button>
                            <Button loading={loadingSubmit} onClick={saveUploadPersonalLicense}>Upload & Verify</Button>
                      </div>
                </Modal>
                {isOpenModalResult && <ModalResultSuccess isSuccess={isOpenModalResult} messageSuccess={messageSuccess} closeModalSuccess={closeModalSuccess} />}
          </div>
      );
}

export default UploadPersonalLicense;