import React from 'react';
import {Col, Row} from "antd";
import {AndroidFilled, AppleFilled} from "@ant-design/icons";
import "./index.scss"
import {Link} from "react-router-dom";

const DownloadApp = (props) => {
      return (
          <div className='download-app-page page-children'>
                <div className="download-app">
                      <Row>
                            <Col className='card-app'  lg={{ span: 6, offset: 5 }} md={{ span: 8, offset: 3 }} sm={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                                  <div>
                                        <p>Google Play Store</p>
                                        <AndroidFilled />
                                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.beverlyboy.crewsnow.android&hl=vi&gl=US" >Download now</a>
                                  </div>
                            </Col>
                            <Col className='card-app' lg={{ span: 6, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                                  <div>
                                        <p>Apple Store</p>
                                        <AppleFilled />
                                        <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/crews-now/id1458721611">Download now</a>
                                  </div>
                            </Col>
                      </Row>
                      <div className="redirect-use-web">
                            <Link to='/login'>Return use the website?</Link>
                      </div>
                </div>
          </div>
      );
}

export default DownloadApp;