import React from 'react';
import {Button, Modal} from "antd";
import {CheckOutlined} from "@ant-design/icons/lib/icons";
import './index.scss'

const ModalResultSuccess = ({isSuccess, messageSuccess , closeModalSuccess}) => {
      return (
          <div>
                <Modal
                    footer={null}
                    visible={isSuccess}
                    maskClosable={false}
                    closable={false}
                    centered
                    className='modal-result-success'
                >
                      <div className="body-modal">
                            <CheckOutlined />
                            <p>{messageSuccess}</p>
                            <Button onClick={closeModalSuccess}>Close</Button>
                      </div>
                </Modal>
          </div>
      );
}

export default ModalResultSuccess;