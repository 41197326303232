import axiosInstance from '../../axiosInstance';

export const createNewProject = (data) => {
      return new Promise((resolve, reject) => {
            axiosInstance.post('/addProject', data).then(
                  (res) => {
                        return resolve(res.data)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}